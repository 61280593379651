import { action, thunk } from "easy-peasy";
import { aloApi } from "../../core/alo-api";
import { toast } from "react-toastify";
import { ENDPOINTS_EVENT } from "../../constants/endpoints";

export const modelContactUs = {
  /**
   * State
   */
  message: "",

  /**
   * Action: Set loading change password
   */
  setData: action((state, payload) => {
    state.topics.announcement = payload?.topicAnnouncement.topic_list.topics;
    state.topics.hot = payload.topicHot.topic_list.topics;
    state.topics.latest = payload.topicLatest.topic_list.topics;
    state.topics.users = payload.topicHot.users;
  }),

  /**
   * Action: Call api to Send Contact
   */
  requestSendContact: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }
      const url = ENDPOINTS_EVENT.CONTACT;
      const res = await aloApi.post(url, payload);
      // action.setLoadingList(true);
      // action.setQuery(payload);
      // action.setData({ topicAnnouncement, topicHot, topicLatest });
      toast.success("Your email has been sent successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
