import React from "react";
import {
  AboutImageBanner,
  AboutImageBannerLeft,
  AboutImageBannerRight,
} from "../../../../assets/images";

import "./about-banner.scss";

const AboutBanner = () => {
  return (
    <section className="about-banner">
      <div className="banner-container divider-40">
        <img src={AboutImageBanner} alt="" />
        <div className="banner-description">
          <h1>Who we are</h1>
          <h2>Our story</h2>
          <p className="divider-20">
            In the digital transformation era, speed is the new currency.
            Testers and developers are racing to roll out quality, bug-free
            software to the market as fast as they can. Yet, the traditional
            approach is tedious, given its manual nature, the spiking demand,
            and the lack of a skilled workforce.
          </p>
          <p>
            With more than 23 years of experience in the technology industry,
            <span> FPT Software - the global Software Powerhouse</span>, has
            recognized the issue testers now facing. To clear such bottleneck,
            we developed{" "}
            <span>akaAT - an all-in-one test automation solution</span> enabling
            testers, from beginner to expert levels, to create tests for Web,
            Mobile, and Web Service without a line of code.
          </p>
        </div>
      </div>
      <div className="download-warp">
        <a href="https://www.fpt-software.com" target="_blank" rel="noreferrer">
          <button className="download-btn">More about FPT Software</button>
        </a>
      </div>
    </section>
  );
};

export default AboutBanner;

// <div className="banner-left">
// {/* <img src={AboutImageBannerLeft} alt="" /> */}
// </div>
// <div className="banner-right">
// {/* <img src={AboutImageBannerRight} alt="" /> */}
// <div className="banner-description">
//   <span>Who we are</span>
//   <h2>Our story</h2>
//   <p>
//     In the digital transformation era, speed is the new currency.
//     Testers and developers are racing to roll out quality, bug-free
//     software to the market as fast as they can. Yet, the traditional
//     approach is tedious, given its manual nature, the spiking demand,
//     and the lack of a skilled workforce.
//   </p>
//   <p>
//     With more than 23 years of experience in the technology industry,
//     FPT Software - the global Software Powerhouse, has recognized the
//     issue testers now facing. To clear such bottleneck, we developed
//     akaAT - an all-in-one test automation solution enabling testers,
//     from beginner to expert levels, to create tests for Web, Mobile,
//     and Web Service without a line of code.
//   </p>
// </div>
// </div>
