import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IconPlay } from "../../../../assets/icons/icon";
import Pagination from "../../../../common/components/pagination/pagination";

const WebinarSearch = ({
  currentTab,
  results,
  textSearch,
  showModal,
  setShowModal,
  setEmbedCode,
  infoPage,
}) => {
  const { formatMessage } = useIntl();
  const { data, total } = results;

  const getEventSearchResults = useStoreActions(
    (action) => action.events.fetchEventSearchResults
  );

  const eventSearch = useStoreState((state) => state.events?.eventSearch);

  useEffect(() => {
    getEventSearchResults({
      text: textSearch,
      tab: currentTab,
      page: infoPage.page,
    });
  }, [currentTab, getEventSearchResults, infoPage.page]);

  if (data.length === 0) {
    return (
      <section className="webinars-more">
        <div className="search_results_content">
          <p> Results for {`"${eventSearch.keyword}"`} </p>
          <p>Sorry. There are no results for {`"${eventSearch.keyword}"`}</p>
          <p>Try rewording your query, or browse through our site.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="webinars-more">
      <h2>Results</h2>
      <div className="more-wrap-item divider-60">
        {data.map((item, index) => (
          <article className="more-item" key={item.id}>
            <div
              className="more-img"
              onClick={() => {
                setEmbedCode(item.attributes.url);
                setShowModal(true);
              }}
            >
              <img
                src={`https://img.youtube.com/vi/${item.attributes.url?.replace(
                  "https://www.youtube.com/watch?v=",
                  ""
                )}/maxresdefault.jpg`}
                alt=""
                className="divider-24"
              />
              <IconPlay className={"more-icon"} />
            </div>
            <h3>{item.attributes.title}</h3>
            <p className="divider-20">{item.attributes.description}</p>
          </article>
        ))}
      </div>
    </section>
  );
};

export default WebinarSearch;
