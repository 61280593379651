/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet-async";
import Toc from "react-toc";
import { LinkedinShareButton, FacebookShareButton } from "react-share";
import moment from "moment";
import { useForm } from "react-hook-form";

import { IconFb, IconLinkedin } from "../../../../assets/icons/icon";
import Layout from "../../../../common/components/layout";
import { BASE_URL_SUPPORT } from "../../../../constants/endpoints";
import { getStrapiMedia } from "../../../../common/utils";
import { HeadingRenderer } from "../../../../common/components/table-content/table-content";

import "./event-article.scss";

const gfm = require("remark-gfm");

const initData = {
  email: "",
  fname: "",
  job: "",
};

const EventArticlePage = () => {
  const { slug } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });
  // action
  const getEventArticle = useStoreActions(
    (action) => action.events.fetchEventArticle
  );
  // get
  const eventArticle = useStoreState((state) => state.events?.eventArticle);

  useEffect(() => {
    getEventArticle(slug);
  }, [getEventArticle, slug]);
  console.log("eventsFeatured", eventArticle);

  const requestAttendEmail = useStoreActions(
    (action) => action.events.requestAttendEmail
  );

  const onSubmit = (data) => {
    requestAttendEmail(data);
    reset(initData);
  };

  return (
    <Layout hiddenStarted>
      <Helmet>
        <title>{eventArticle.attributes?.seo?.metaTitle}</title>
        <meta
          name="description"
          content={eventArticle.attributes?.seo?.metaDescription}
        />
        {/* Facebook Meta Tags */}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={eventArticle.attributes?.seo?.metaSocial[0]?.title}
        />
        <meta
          property="og:description"
          content={eventArticle.attributes?.seo?.metaSocial[0]?.description}
        />
        <meta
          property="og:image"
          content={`${BASE_URL_SUPPORT}${eventArticle.attributes?.seo?.metaSocial[0]?.image.data.attributes.url}`}
        />
      </Helmet>
      <div className="event-article-page">
        <h1 className="divider-48">Events</h1>
        <section className="event-article-header divider-40">
          <div className="event-article-left">
            <div className="event-article-tag-wrap divider-48">
              <span className="event-tag">Events</span>
              <span>
                {moment(eventArticle.attributes?.publishedAt).format("LL")} — 3
                minutes read
              </span>
            </div>
            <h1>{eventArticle.attributes?.title}</h1>
            <p>{eventArticle.attributes?.seo?.metaDescription}</p>
          </div>
          <div className="event-article-right">
            <img
              src={`${BASE_URL_SUPPORT}${eventArticle.attributes?.image.data.attributes.url}`}
              // src="https://akaat.com/services/portal/download/15192509_1833530230239671_7449326546432947363_n.jpeg"
              alt=""
            />
          </div>
        </section>
        <section className="event-article-content divider-60">
          <div className="event-article-content-left">
            <ReactMarkdown
              children={eventArticle.attributes?.content}
              remarkPlugins={[gfm]}
              linkTarget="_blank"
              components={{
                img: ({ node, ...props }) => (
                  <img src={getStrapiMedia(props.src)} alt={props.alt} />
                ),
                h2: HeadingRenderer,
                h3: HeadingRenderer,
              }}
            ></ReactMarkdown>
          </div>
          <aside className="event-article-content-right">
            <Toc
              markdownText={eventArticle.attributes?.content}
              className={"event-article-toc"}
            />
          </aside>
        </section>
        <section className="event-article-social-network divider-40">
          <p className="divider-12">Share this post</p>
          <div className="wrap-icons">
            <FacebookShareButton url={window.location.href} quote={"Akaat"}>
              <IconFb style={{ color: "#000000", marginRight: "20px" }} />
            </FacebookShareButton>
            <LinkedinShareButton url={window.location.href}>
              <IconLinkedin style={{ color: "#000000", marginRight: "20px" }} />
            </LinkedinShareButton>
            {/* <FacebookShareButton
              url={window.location.href}
              quote={"Akaat"}
            >
              <IconLinkedin style={{ color: "#000000" }} />
            </FacebookShareButton> */}
          </div>
        </section>
        <footer className="event-article-footer">
          <h3 className="divider-20">REGISTER NOW</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="event-wrap-email">
              <label htmlFor="fname">First Name*</label>
              <input
                type="text"
                id="fname"
                name="fname"
                className="divider-35"
                {...register("fname", { required: true, maxLength: 80 })}
              />
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                  maxLength: 80,
                })}
                className="divider-35"
              />
              <label htmlFor="job">Job title*</label>
              <input
                type="job"
                id="job"
                name="job"
                className="divider-40"
                {...register("job", { required: true, maxLength: 80 })}
              />
              <div className="event-article-btn">
                <button type="submit" disabled={!isValid}>
                  Join Us Now
                </button>
              </div>
            </div>
          </form>
        </footer>
      </div>
    </Layout>
  );
};

export default EventArticlePage;
