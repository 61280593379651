import React from "react";
import { DownloadImageTestManager } from "../../../../assets/images";
import "./download-test-management.scss";

const DownloadTestManagement = () => {
  return (
    <section className="download-test-management">
      <div className="row">
        <div className="col-12 col-lg-7 test-engine-left">
          <img src={DownloadImageTestManager} alt=" man and woman discussing" />
        </div>
        <div className="col-12 col-lg-5 test-engine-right">
          <h2>Test Management</h2>
          <p className="divider-64">
            Try akaAT Test Management and collaborate within your team at ease.
            {/* <span> Learn more about akaAT Collab.</span> */}
          </p>
          <a href="https://manager.akaat.com" target="_blank" rel="noreferrer">
            <button className="download-btn">Learn more</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default DownloadTestManagement;
