import { useStoreActions } from "easy-peasy";
import React from "react";
import { IconSearch } from "../../../assets/icons/icon";

const StoriesTab = ({ setTextSearch, textSearch, setSearchResults }) => {
  const getStorySearchResults = useStoreActions(
    (action) => action.successStories.fetchStorySearchResults
  );

  const handelSearch = () => {
    if (textSearch.length > 0) {
      setSearchResults(true);
      getStorySearchResults({ text: textSearch, page: 1 });
    } else {
      setSearchResults(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      handelSearch();
    }
  };

  return (
    <section className="success-stories-tab divider-80">
      <div className="tab-left">
        <article className={`tab-item`} tabIndex="1">
          &nbsp;
        </article>
      </div>
      <div className="tab-right">
        <div className="search-container">
          <button onClick={handelSearch}>
            <IconSearch />
          </button>
          <input
            type="text"
            placeholder="Search..."
            className=""
            onChange={(event) => setTextSearch(event.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>
    </section>
  );
};

export default StoriesTab;
