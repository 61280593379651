import React from "react";
import { useIntl } from "react-intl";
import { IconPlay } from "../../../../assets/icons/icon";

const WebinarsMore = ({ data, showModal, setShowModal, setEmbedCode }) => {
  const { formatMessage } = useIntl();
  return (
    <section className="webinars-more">
      <h2>More Webinars</h2>
      <div className="more-wrap-item divider-60">
        {data.map((item, index) => (
          <article className="more-item" key={item.id}>
            <div
              className="more-img"
              onClick={() => {
                setEmbedCode(item.attributes.url);
                setShowModal(true);
              }}
            >
              <img
                src={`https://img.youtube.com/vi/${item.attributes.url?.replace(
                  "https://www.youtube.com/watch?v=",
                  ""
                )}/maxresdefault.jpg`}
                alt=""
                className="divider-24"
              />
              <IconPlay className={"more-icon"} />
            </div>
            <h3>{item.attributes.title}</h3>
            <p className="divider-20">{item.attributes.description}</p>
          </article>
        ))}
      </div>
    </section>
  );
};

export default WebinarsMore;
