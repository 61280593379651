import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Layout from "../../common/components/layout";
import TestEngineBanner from "./components/test-engine-banner/test-engine-banner";
import TestEngineBenefit from "./components/test-engine-benefit/test-engine-benefit";
import AkaStudioFeature from "./components/test-engine-feature/test-engine-feature";
import TestEngineIntroduction from "./components/test-engine-introduction/test-engine-introduction";
import { TestEngineImageEnd } from "../../assets/images";
import "./aka-test-engine.scss";
import { useViewportSize } from "../../common/hooks";
import MobileTestEngineFeature from "../../mobile-pages/aka-test-engine/components/test-engine-feature/test-engine-feature";
import MobileTestEngineBenefit from "../../mobile-pages/aka-test-engine/components/test-engine-benefit/test-engine-benefit";
import MobileTestEngineBanner from "../../mobile-pages/aka-test-engine/components/test-engine-banner/test-engine-banner";

const AkaTestEnginePage = () => {
  const { formatMessage } = useIntl();
  const [viewportWidth] = useViewportSize();

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "AkaTestEnginePage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout hiddenStarted product>
      <div className="test-engine-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.test_engine.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.test_engine.meta_description" })}
          />
        </Helmet>
        {viewportWidth > 991 ? (
          <TestEngineBanner />
        ) : (
          <MobileTestEngineBanner />
        )}

        <TestEngineIntroduction />
        {viewportWidth > 991 ? (
          <>
            <TestEngineBenefit />
            <AkaStudioFeature />
          </>
        ) : (
          <>
            <MobileTestEngineBenefit />
            <MobileTestEngineFeature />
          </>
        )}
        <section className="test-engine-end">
          <div className="w-1440">
            <div className="row">
              <div className="col-12 col-md-10">
                <img src={TestEngineImageEnd} alt="studio lifecycle" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AkaTestEnginePage;
