/* eslint-disable react-hooks/rules-of-hooks */
import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { GroupIcon } from "../../../assets/icons/icon";
import Layout from "../../../common/components/layout";
import "./blog-article.scss";

const BlogArticle = () => {
  const globalUserInfo = useStoreState((state) => state);

  const aaa = () => {};
  return (
    <Layout hiddenStarted>
      <div className="blog-article-page">
        <h1 className="divider-48">Blog</h1>
        <section className="blog-article-header divider-40">
          <div className="blog-article-left">
            <div className="blog-article-tag-wrap divider-48">
              <span className="blog-tag">Blog</span>{" "}
              <span>February 28, 2022 — 3 minutes read</span>
            </div>
            <h1>akaAT - Automation solution for programmers/testers</h1>
            <p>
              akaAT's mission is to help programmers and testers solve problems
              encountered at work and provide more weapons to increase
              productivity, reduce costs, increase the competitiveness of
              businesses while still Provide customers with the best service.
            </p>
          </div>
          <div className="blog-article-right">
            <img
              src="https://akaat.com/services/portal/download/15192509_1833530230239671_7449326546432947363_n.jpeg"
              alt=""
            />
          </div>
        </section>
        <section className="blog-article-content divider-60">
          <div className="blog-article-content-left">
            <p id="tag1">
              Fortunately, on a bustling year-end afternoon, I happened to meet
              and talk to Mr. Nguyen Duy Vinh - the runner-up of the ICIS 2020
              (a corporate innovation award of our company). I). A few questions
              about life, work, some gossip about dreams and life, then he
              showed me the whole sky of passion encapsulated in one word akaAT.{" "}
            </p>
            <p id="tag2">
              He divided share: "I have done many Automation Test projects with
              different types of applications. Most of the time to implement
              these Automation projects, you need to write code based on open
              source libraries or use tools. Commercial automation tools on the
              market come with a license, which brings the paint-points anyone
              who's ever worked on a project has encountered: If you're using an
              open source library, it's not quick and easy to get started. The
              process usually starts with building the Automation Framework,
              then the scripter based on this framework to code out test
              scripts, and finally deploy to run and maintain the scripts every
              time there are changes. All of the above requires the Scripter to
              have good programming skills (skills of programmers - devs) and at
              the same time have a good understanding of Testing as well as a
              good understanding of the business. part of the application to be
              tested (skills of a tester).
            </p>
            <p id="tag3">
              So, Automation scripter is a 2-in-1 skill combination of both
              programmers and testers. . To train a good Automation Scripter but
              love the job is not simple and takes a lot of time.
            </p>
            <p id="tag4">
              If you use commercial tools, there will be points. In terms of
              startup time and issue resolution support of the support team, the
              service license cost is often very high and will be charged
              directly to the selling price of the service to the customer. This
              will be a disadvantage for businesses in their efforts to provide
              Services are priced competitively with other competitors. It is
              this that has motivated him to develop a product that both helps
              his colleagues - programmers and testers solve problems
              encountered at work, and provides more weapons to increase
              productivity. productivity, reduce costs, increase competitiveness
              while still providing customers with the best service. ”
            </p>
            <p id="tag5">
              We all understand that in times of market economy, competition on
              service quality and price is an extremely fierce battle. . With
              the determination to increase the productivity of the unit and a
              burning passion for the Automation Test industry, Mr. Vinh and his
              teammates gave birth to the first brainchild in the akaAT
              ecosystem - akaAT Studio.
            </p>
            <p id="tag6">
              He said: “akaAT Studio is an Automation Test tool - Automated
              testing tools. It is developed based on current popular open
              source automation libraries such as Selenium, Appium or
              WinAppDriver.”
            </p>
          </div>
          <aside className="blog-article-content-right">
            <a href="#tag1">
              <p>1.Table of content</p>
            </a>
            <a href="#tag2">
              <p>1.1Table of content</p>
            </a>
            <a href="#tag3">
              <p>1.2Table of content</p>
            </a>
            <a href="#tag4">
              <p>2.Table of content</p>
            </a>
            <a href="#tag5">
              <p>3.Table of content</p>
            </a>
          </aside>
        </section>
        <section className="blog-article-social-network">
          <p>Share this post</p>
          <GroupIcon />
        </section>
      </div>
    </Layout>
  );
};

export default BlogArticle;
