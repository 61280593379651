import React from "react";
import { IconPlay } from "../../../../assets/icons/icon";
import {
  ImageGlobal,
  ImageLaptop,
  LogoAkaAT,
  PreviewVideo,
} from "../../../../assets/images";
import { BASE_URL_FORUM } from "../../../../constants/endpoints";
import "./home-community.scss";

const MobileHomeCommunity = () => {
  return (
    <section className="m-home-community">
      <div className="community-above">
        <img src={ImageGlobal} alt="global community" className="divider-12" />
        {/* <img src={ImageLaptop} alt="" className="community-right-image" />
          <div className="community-logo">
            <img src={LogoAkaAT} alt="" width={70} />
          </div>
          <div className="community-video">
            <img src={PreviewVideo} alt="" width={210} />
            <IconPlay className={"community-icon"} />
          </div> */}
      </div>
      <div className="community-below">
        <h3>What's on our</h3>
        <h2 className="divider-12">Community?</h2>
        <p className="divider-20">
          akaAT Community – The official knowledge-sharing space for all akaAT
          users
        </p>
        <a href={`${BASE_URL_FORUM}`} target={"_blank"} rel="noreferrer">
          <button className="community-btn">Join our community</button>
        </a>
      </div>
    </section>
  );
};

export default MobileHomeCommunity;
