import React from "react";
import Slider from "react-slick/lib/slider";
import "./device-farm-benefit.scss";
import {
  IconBenefitProduct4,
  IconBenefitProduct5,
  IconBenefitProduct6,
  IconHourglass,
} from "../../../../assets/icons/icon";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileDeviceFarmBenefit = () => {
  return (
    <section className="m-device-farm-benefit">
      <div className="recognition-text divider-40">
        <h2 className="divider-52">
          Enhancing your remote <br />
          testing experience
        </h2>
      </div>
      <div className="recognition-slides divider-40">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <IconBenefitProduct4 />
            <h3 className="divider-8">Ship smarter and together</h3>
            <p>
              Access your devices concurrently, detect and fix bugs faster with
              akaAT. The entire team can seamlessly collaborate and share
              devices across projects.
            </p>
          </article>
          <article className="recognition-row divider-20">
            <IconBenefitProduct5 />
            <h3 className="divider-8">Save delivery and travelling cost</h3>
            <p>
              akaAT Device Farm lets you remotely run tests on a large scale,
              using the same devices your customers use, without any onsite
              travel fee.
            </p>
          </article>

          <article className="recognition-row divider-20">
            <IconHourglass />
            <h3 className="divider-8">Optimize device idle time</h3>
            <p>
              Reduce waiting time with multiple test executions at a time. Keep
              track on devices usage rate and fill up idle time with other
              usage.
            </p>
          </article>

          <article className="recognition-row divider-20">
            <IconBenefitProduct6 />
            <h3 className="divider-8">Enhance security</h3>
            <p>
              akaAT takes high priority in security, as all data will be deleted
              after each remoting session to ensure no data leak.
            </p>
          </article>
        </Slider>
      </div>
      <div className="benefit-wrap-btn">
        <a
        // href={`https://akatester.com/auth/register`}
        // target={"_blank"}
        // rel="noreferrer"
        >
          <button className="benefit-btn">Learn more</button>
        </a>
      </div>
    </section>
  );
};

export default MobileDeviceFarmBenefit;
