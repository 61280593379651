import React from "react";
import Slider from "react-slick/lib/slider";
import "./aka-studio-feature.scss";
import {
  IconCodelessGui,
  IconDynamicTesting,
  IconMultiPlatforms,
  IconTestingIntegrate,
} from "../../../../assets/icons/icon";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileStudioFeature = () => {
  return (
    <section className="m-aka-studio-feature">
      <div className="recognition-text divider-40">
        <h2 className="divider-52">
          Power-packed with <br />
          <span>features</span>
        </h2>
      </div>
      <div className="recognition-slides">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconCodelessGui />
            </div>
            <h3 className="divider-8">CODELESS GUI</h3>
            <p>
              {" "}
              With a user-friendly and customizable GUI, even beginners now can
              use akaAT Studio to build sophisticated tests, without a line of
              code.
            </p>
          </article>
          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconTestingIntegrate />
            </div>
            <h3 className="divider-8">DYNAMIC DATA-DRIVEN TESTING</h3>
            <p>
              Using data files containing keywords related to the application
              being tested
            </p>
          </article>

          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconMultiPlatforms />
            </div>
            <h3 className="divider-8">MULTI PLATFORMS</h3>
            <p>
              Test multiple platforms at the same time (Web, mobile, API, window
              app...). One test case able to cover all platforms
            </p>
          </article>

          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconDynamicTesting />
            </div>
            <h3 className="divider-8">SMART RECORDER</h3>
            <p>
              Test scripts, processes or product data – anything you want, akaAT
              ensures you can save it and conveniently listen to it later.
            </p>
          </article>
        </Slider>
      </div>
    </section>
  );
};

export default MobileStudioFeature;
