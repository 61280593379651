import React, { useState } from "react";

import Slider from "react-slick/lib/slider";
import {
  FeedbackBaovietMobile,
  FeedbackFptMobile,
  FeedbackVv,
} from "../../../../assets/images";
import "./home-feedback.scss";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const data = [
  {
    img: FeedbackFptMobile,
    name: "FPT Information System",
    header: "Simple and easy",
    comment: `akaAT's interfaces look like common IDE nowadays which help
    you to create test case simply and easily.`,
  },
  {
    img: FeedbackBaovietMobile,
    name: "BAOVIET Life",
    header: "Save 70% contract processing time",
    comment: `“Bao Viet Life and FPT figured out automation processes to
    reduce workload with akaAT”.`,
  },
  {
    img: FeedbackVv,
    name: "Video Visit",
    header: "Achieve 2x Faster Time to Market",
    comment: `We choose akaAT firstly because this product provides us with
    a test solution on Cloud platform for health system. Secondly,
    the tool produces clear, easy to read and understand reports.
    Besides, the support team is also very active and
    enthusiastic. These are things that convinced us to use akaAT.`,
  },
];

const MobileHomeRecognition = () => {
  const [showMore, setShowMore] = useState(false);
  const truncate = (text) => {
    if (text.length > 120) {
      return text.substring(0, 120) + "...";
    }
    return text;
  };
  return (
    <section className="m-home-feedback">
      <div className="feedback-left divider-20">
        <h2>
          Voice of <span>Customers</span>
        </h2>
      </div>
      <div className="feedback-slides">
        <Slider {...settings}>
          {data.map((item, index) => (
            <article className="feedback-item divider-20" key={index}>
              <div className="feedback-logo-customer divider-20">
                <img src={item.img} alt="" />
              </div>
              <div className="feedback-content">
                <p className="feedback-content-name divider-12">{item.name}</p>
                <p className="feedback-content-header">{item.header}</p>
                <p className="feedback-content-comment divider-20">
                  {showMore ? item.comment : truncate(item.comment)}
                </p>
                {item.comment.length > 120 && (
                  <label
                    onClick={() => setShowMore(!showMore)}
                    className="divider-8"
                  >
                    {showMore ? "Show less" : "Read more"}
                  </label>
                )}
              </div>
            </article>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default MobileHomeRecognition;
