import React from "react";
import { useIntl } from "react-intl";
import {
  ImageEcosystem,
  ImageMobileSupport,
  ImageSeamlesslyIntegration,
} from "../../../../assets/images";
import "./home-why.scss";

const MobileHomeWhy = () => {
  const { formatMessage } = useIntl();
  return (
    <section className="m-home-why">
      <h3 className="why-title divider-48">
        {formatMessage({ id: "home.why.msg_01" })}
      </h3>
      <article className="why-highlights divider-60">
        <div className="why-left divider-20">
          <div className="why-ordinal divider-12">01</div>
          <h3 className="divider-12">
            {formatMessage({ id: "home.why.msg_02" })}
          </h3>
          <p>{formatMessage({ id: "home.why.msg_03" })}</p>
        </div>
        <img src={ImageSeamlesslyIntegration} alt="" className="why-image" />
      </article>
      <article className="why-highlights divider-60">
        <div className="why-left divider-20">
          <div className="why-ordinal divider-20">02</div>
          <h3 className="divider-20">
            {formatMessage({ id: "home.why.msg_04" })}
          </h3>
          <p>{formatMessage({ id: "home.why.msg_05" })}</p>
        </div>
        <div className="why-image">
          <img src={ImageEcosystem} alt="" />
        </div>
      </article>
      <article className="why-highlights">
        <div className="why-left divider-20">
          <div className="why-ordinal divider-12">03</div>
          <h3 className="divider-12">
            {formatMessage({ id: "home.why.msg_06" })}
          </h3>
          <p>{formatMessage({ id: "home.why.msg_07" })}</p>
        </div>
        <div className="why-right">
          <img src={ImageMobileSupport} alt="" />
        </div>
      </article>
    </section>
  );
};

export default MobileHomeWhy;
