import { useEffect } from "react";
import * as tocbot from "tocbot";

import { useViewportSize } from "../../common/hooks";

import AdsBanner from "./components/ads-banner/ads-banner";
import AdsCommunity from "./components/ads-community/ads-community";
import AdsEcosystem from "./components/ads-ecosystem/ads-ecosystem";
import AdsEvaluate from "./components/ads-evaluate/ads-evaluate";
import AdsFeature from "./components/ads-feature/ads-feature";
import AdsFooter from "./components/ads-footer/ads-footer";
import AdsNav from "./components/ads-nav/ads-nav";
import AdsProduct from "./components/ads-product/ads-product";
import MobileAdsEvaluate from "../../mobile-pages/ads/ads-evaluate/ads-evaluate";

import "./ads.scss";
import { AdsImageBgFeature } from "../../assets/images";
import MobileAdsFeature from "../../mobile-pages/ads/ads-feature/ads-feature";

const AdsPage = () => {
  const [viewportWidth] = useViewportSize();
  useEffect(() => {
    tocbot.init({
      // Where to render the table of contents.
      tocSelector: ".js-toc",
      // Where to grab the headings to build the table of contents.
      contentSelector: "main",
      // Which headings to grab inside of the contentSelector element.
      headingSelector: ".ads-dot",
      // For headings inside relative or absolute positioned containers within content.
      hasInnerContainers: true,
      // Main class to add to lists.
      extraLinkClasses: "toc-link",
      // Class that gets added when a list should be collapsed.
      isCollapsedClass: "is-collapsed",
      // Smooth scrolling enabled.
      scrollSmooth: true,
      // Smooth scroll duration.
      scrollSmoothDuration: 420,
      // headingsOffset: 40,
      collapseDepth: 0,
      includeHtml: true,
    });
  }, []);
  return (
    <>
      <div className="ads-page">
        <AdsNav />
        <div className="ads-page-aside">
          <aside className="js-toc"></aside>
        </div>
        <main>
          <div id="2" className="ads-dot"></div>
          <AdsProduct />
          <div id="3" className="ads-dot"></div>
          <div className="ads-wrap">
            <div className="ads-bg-img">
              <img src={AdsImageBgFeature} alt="ads background img product" />
            </div>
            {viewportWidth > 991 ? <AdsFeature /> : <MobileAdsFeature />}
            <div id="4" className="ads-dot"></div>
            <AdsCommunity />
          </div>
          <div id="5" className="ads-dot"></div>
          {viewportWidth > 991 ? <AdsEvaluate /> : <MobileAdsEvaluate />}
          <div id="6" className="ads-dot"></div>
          <AdsEcosystem />
        </main>
        <AdsFooter />
      </div>
    </>
  );
};
export default AdsPage;
