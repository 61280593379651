import { lazy } from "react";

const EventsWebinarsPage = lazy(() => import("./events-webinars"));
const EventArticlePage = lazy(() =>
  import("./events/event-article/event-article")
);
const EventListPage = lazy(() => import("./events/event-list/event-list"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/events",
    public: true,
    component: EventsWebinarsPage,
  },
  {
    path: "/events/all",
    public: true,
    component: EventListPage,
  },
  {
    path: "/event/article/:slug",
    public: true,
    component: EventArticlePage,
  },
];
