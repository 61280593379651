import React, { useState } from "react";
import { useStoreActions } from "easy-peasy";
import { useForm } from "react-hook-form";

import "./profile-change-password.scss";

import {
  IconSave,
  IconVisibilityOff,
  IconVisibilityOn,
} from "../../../../assets/icons/icon";

const PASSWORD_PATTERN = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
const ProfileChangePassword = ({ setImageUpdate, dataUserInfo }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleCfPassword, setVisibleCfPassword] = useState(false);

  const sendUpdatePassword = useStoreActions(
    (action) => action.profile.requestUpdatePassword
  );

  const onSubmit = (data) => {
    sendUpdatePassword({
      newPassword: data.password,
      id: dataUserInfo.id,
    });
  };

  return (
    <section className="profile-change-password">
      <div className="change-password-header">
        <h3>Change password</h3>
      </div>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)} className="change-password-form">
        <div className="change-password-item divider-16">
          <label htmlFor="email">New password</label>
          <br />
          <input
            id="newPassword"
            name="newPassword"
            type={visiblePassword ? "text" : "password"}
            autoComplete="on"
            {...register("password")}
          />
          {visiblePassword ? (
            <IconVisibilityOn
              style={{
                verticalAlign: "bottom",
                marginRight: "4px",
                color: "#667085",
              }}
              onClick={() => setVisiblePassword(false)}
            />
          ) : (
            <IconVisibilityOff
              style={{
                verticalAlign: "bottom",
                marginRight: "4px",
                color: "#667085",
              }}
              onClick={() => setVisiblePassword(true)}
            />
          )}
        </div>
        <div className="change-password-item divider-16">
          <label htmlFor="cfPassword">Confirm new password</label>
          <br />
          <input
            id="cfPassword"
            name="cfPassword"
            type={visibleCfPassword ? "text" : "password"}
            autoComplete="on"
            {...register("cfPassword", {
              required: "Please confirm password!",
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  if (!PASSWORD_PATTERN.test(password)) {
                    return "Your password must include at least 8 characters. A strong password combines lowercase letters, uppercase letters, numbers";
                  }
                  return password === value || "Passwords should match!";
                },
              },
            })}
          />
          {visibleCfPassword ? (
            <IconVisibilityOn
              style={{
                verticalAlign: "bottom",
                marginRight: "4px",
                color: "#667085",
              }}
              onClick={() => setVisibleCfPassword(false)}
            />
          ) : (
            <IconVisibilityOff
              style={{
                verticalAlign: "bottom",
                marginRight: "4px",
                color: "#667085",
              }}
              onClick={() => setVisibleCfPassword(true)}
            />
          )}
        </div>

        <div className="change-password-item divider-16">
          <label htmlFor="email">&nbsp;</label>
          <br />
          {errors.cfPassword ? (
            <p style={{ color: "red" }}>{errors.cfPassword.message}</p>
          ) : (
            <p style={{ color: "red" }}>&nbsp;</p>
          )}
        </div>

        <hr />
        <div className="person-wrap-btn">
          <button className="btn person-btn" type="submit">
            <IconSave
              style={{
                verticalAlign: "bottom",
                marginRight: "4px",
              }}
            />{" "}
            Update
          </button>
        </div>
      </form>
    </section>
  );
};

export default ProfileChangePassword;
