import React from "react";
import { Link } from "react-router-dom";
import "./download-header.scss";

const DownloadHeader = () => {
  return (
    <section className="download-header">
      <div className="header-top-description divider-20">
        <h1 className="divider-20">Download</h1>
        <ul>
          <li>
            Start your automation journey now with akaAT! By clicking Download,
            you have agreed with our{" "}
            <Link to="/terms">Terms and Conditions.</Link>
          </li>
          <li>
            <Link to="/release-notes">Release Notes.</Link>
          </li>
        </ul>
      </div>
      {/* <div className="header-community">
        <h2>akaAT Studio Community</h2>
        <p>
          akaAT Studio Community is the version for personal use with
          user-friendly features to create test scripts automatically and
          effortlessly.
        </p>
        <span>Learn more about akaAT Studio.</span>
        <p>
          Want to use akaAT Studio for your team?
          <span> Contact us. </span>
        </p>
      </div> */}
    </section>
  );
};

export default DownloadHeader;
