import {
  IconFeatureProduct4,
  IconFeatureProduct5,
  IconFeatureProduct6,
  IconTestingIntegrate,
} from "../../../../assets/icons/icon";
import { TestManagementImageLogo } from "../../../../assets/images";
import "./test-management-feature.scss";
const AkaStudioFeature = () => {
  return (
    <section className="test-management-feature">
      <div className="w-1440">
        <h2 className="divider-52">Core features</h2>
        <div className="feature-container">
          <div className="row feature-center divider-70">
            <article className="col-4 feature-item">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct5 />
              </div>
              <h3 className="divider-8">TEST PLANNING</h3>
              <p>
                Accelerate your teamwork with test plan, dynamic test case
                design, repositories, and task allocation features.
              </p>
            </article>
            <article className="col-4 feature-item feature-item-right">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct4 className="feature-icon-right" />
              </div>
              <h3 className="divider-8">DYNAMIC REPORT</h3>
              <p>
                Show up-to-date aggregate visualized test. Report both manual &
                automation tests. Multiple view traceability to check the root
                cause
              </p>
            </article>
          </div>
          <div className="row feature-center">
            <article className="col-4 feature-item">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct6 />
              </div>
              <h3 className="divider-8">TRACEABILITY</h3>
              <p>Multiple view traceability to check the root cause</p>
            </article>
            <article className="col-4 feature-item feature-item-right">
              <div className="benefit-icon divider-28">
                <IconTestingIntegrate className="feature-icon-right" />
              </div>
              <h3 className="divider-8">FLEXIBLE TEST PROCESS</h3>
              <p>
                Provide a flexible testing process <br /> for agile/scrum or
                waterfall projects.
              </p>
            </article>
          </div>
          <div className="feature-img">
            <img src={TestManagementImageLogo} alt="logo akaAt studio" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AkaStudioFeature;
