import React, { useState } from "react";
import { IconCollapse, IconExpanded } from "../../../../assets/icons/icon";

import "./pricing-question.scss";

const AskedQuestionsList = [
  {
    title: `1. What is the difference between billing monthly and annually? Do you have any discount?`,
    description: `akaAT Studio Enterprise, akaAT Test Engine and akaAT Management are all available for both monthly and annual payments. In addition, the annual billing offers a better price with an almost 5% discount on the total budget.`,
  },
  {
    title: `2. 	Can akaAT send me an invoice?`,
    description: `Sure, our sales team will send you the invoice to your registration email.`,
  },
  {
    title: `3. Can I convert the trial version to a paid subscription, and keep all of my settings? `,
    description: `Yes, you can. The enterprise license will have more features than the trial/ community versions, so basically you will pay for more features when purchasing enterprise versions. All the data and settings will be retained.`,
  },
  {
    title: `4. 	What if more people are added to my team? `,
    description: `You can consider purchasing additional licenses for your new team members. The new licenses will be added to your current package. Please contact us if you want to buy a new license. `,
  },
  {
    title: `5. 	What will happen if I cancel my Enterprise license? `,
    description: `Payments for Enterprise licenses are non-refundable, so make sure you choose a suitable plan before purchasing. All Enterprise features will remain until the end of your license period.
    If in any case, you are unhappy with akaAT, please contact our support by emailing to support@akaat.com. We will try our best to walk you through any inconvenience and enhance your experience with akaAT.`,
  },
  {
    title: `6. 	Can I collaborate under the free plan?`,
    description: `akaAT Studio and akaAT Test Engine will only be collaborated on if you use the Enterprise plan. With akaAT Management, the free trial version allows you to collaborate with up to 3 users, including you.`,
  },
];

const Questions = ({ title, content, isExpanded, expandCard }) => {
  return (
    <div
      className={`accordion-item divider-24 ${
        isExpanded ? "accordion-expanded" : ""
      }`}
    >
      <button
        className={isExpanded ? "divider-24" : ""}
        aria-expanded={isExpanded ? "true" : "false"}
        onClick={expandCard}
      >
        <h3>{title}</h3>
        {/* <span className="icon" aria-hidden="t rue" /> */}
        {isExpanded ? (
          <IconExpanded className="icon" />
        ) : (
          <IconCollapse className="icon" />
        )}
      </button>
      <div className="accordion-content">
        <p>{content}</p>
      </div>
    </div>
  );
};

const PricingQuestion = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const handleShow = (code) => {
    if (code === expandedCard) {
      return setExpandedCard(null);
    }
    setExpandedCard(code);
  };

  return (
    <section className="pricing-question">
      <div className="pricing-question-container" id="Faqs">
        <h2 className="divider-16">Frequently Asked Questions</h2>
        <p className="divider-48">
          You have question. Let's see what we can help you.
        </p>
        <div className="accordion">
          {AskedQuestionsList.map((item, index) => (
            <Questions
              title={item.title}
              content={item.description}
              key={index}
              isExpanded={expandedCard === index}
              expandCard={() => handleShow(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingQuestion;
