import React from "react";

function TermsOfUse({ currentTab }) {
  return (
    <>
      {currentTab === "rules1" && (
        <div id="rules1">
          <h2 className="divider-35">Term of use</h2>
          <p className="divider-35">
            Last modified and effective as of December 21st, 2021 Welcome to
            [akaAT] as web-based and desktop application provided by FPT
            Software Company Limited.
          </p>
          <p className="divider-35">
            PLEASE READ THIS TOU OF USE (“ToU”) CAREFULLY. THE TOU FORM A LEGAL
            AGREEMENT BETWEEN YOU AND FPT FOR AND ON BEHALF OF ITS AFFILIATES
            (“FSOFT”). BY ACTIVATING, ACCEPTING, ACCESSING OR USING THE SERVICE
            AND RELATED WEBSITES (COLLECTIVELY, THE "SERVICE") PROVIDED TO YOU
            BY FSOFT, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE
            TO BE BOUND BY THE TOU. IF YOU ARE ACCEPTING THE TOU ON BEHALF OF
            ANOTHER PERSON OR A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT AND
            WARRANT THAT YOU HAVE FULL AUTHORITY TO BIND THAT PERSON, COMPANY,
            OR LEGAL ENTITY TO THE TOU. IF YOU DO NOT AGREE TO ALL OF THE TOU,
            DO NOT ACCESS, OR USE THE SERVICE. The ToU govern your access to and
            use of the Services provided by the FSOFT.
          </p>
          <p className="divider-35">
            At its own discretion, FSOFT will add, amend, modify, supplement or
            restate the ToU from time to time, and the ToU as in force are
            always published on FSOFT website: https://akaat.com. The continued
            use of the Service after such changes have been posted shall
            constitute your acceptance of such revised ToU.
          </p>
          {/* <p className="divider-35">
            The Terms of Use were last modified{" "}
            <span className="hight-light-bg-text">on July … th, 2022.</span>
          </p> */}
          {/* <b className="divider-35">Scope of terms</b>
          <p className="divider-35">
            These terms apply generally to your use of the Site. Other portions
            of the Site, such as akaAT Studio, akaAT Center, akaAT Runtime
            Engine and akaAT Collacb have separate agreements or terms and
            conditions which you must also comply with.
          </p> */}
          <h3 className="divider-35">Definition</h3>
          <p className="divider-35">
            Affiliate means any legal entity in which a party, directly or
            indirectly, holds more than fifty percent (50%) of the entity’s
            shares or voting rights. Any legal entity will be considered an
            affiliate as long as that interest is maintained.
          </p>
          <p className="divider-35">
            Account means the registration account with FSOFT for accessing the
            Service.
          </p>
          <p className="divider-35">
            Content means all text, information, data, images, audio or video
            material, in whatever medium or form, inputted by you for the
            purpose of using the Site and/or the Service.
          </p>
          <p className="divider-35">
            Definitive Agreement means the definitive agreement executed by you
            and FSOFT in relation to the Service.
          </p>
          <p className="divider-35">
            Effective Date means the date that you visit, utilize the Site
            and/or the Service.
          </p>
          <p className="divider-35">
            Intellectual Property Rights means patents of any type, design
            rights, utility models or other similar invention rights,
            copyrights, mask work rights, trade secret or confidentiality
            rights, trademarks, trade names and service marks and any other
            intangible property rights, including applications and registrations
            for any of the foregoing, in any country, arising under statutory or
            common law or by contract and whether or not perfected, now existing
            or hereafter filed, issued, or acquired.
          </p>
          <p>Security Event means</p>
          <ul className="divider-35">
            <li>
              any unauthorized third-party access to the Site and/or the
              Service; or
            </li>
            <li>
              any use of the Service by you that has the potential to materially
              impact the Site and/or the Service.
            </li>
            <li>
              such other services as FSOFT may decide, at its discretion, to
              integrate into the Site from time to time.
            </li>
          </ul>
          <p className="divider-35">
            Site means the website and relevant infrastructure platform of FSOFT
            on which the Service is constructed and provided.
          </p>
          <p className="divider-35">
            Service Fees means the subscription fees payable by you to FSOFT for
            using the Service, as set out in the Definitive Agreement.
          </p>
          <p className="divider-35">
            Service Term means the initial or renewal period you may utilize the
            Service as set out in the Definitive Agreement.
          </p>
        </div>
      )}

      {currentTab === "rules2" && (
        <div id="rules2">
          <h3 className="divider-35">Account and security</h3>
          <p>
            1. As a prerequisite of entering into the Definitive Agreement
            regarding a specific Service, you must register for an Account by
            selecting a unique user identification and password, and by
            providing certain information. You hereby agree to:{" "}
          </p>
          <ul className="divider-35">
            <li>
              keep password confidential and use only Account and password when
              logging in
            </li>
            <li>
              ensure that you log out from your Account at the end of each
              session on the Site;
            </li>
            <li>
              immediately notify FSOFT of any unauthorized use of your Account;
              and
            </li>
            <li>
              ensure that your Account information is accurate and up-to-date.
            </li>
          </ul>
          <p className="divider-35">
            2. FSOFT may verify your e-mail address by sending an activation
            link in e-mail to you. In this case, you must activate their
            registration through this link. You represent and warrant that you
            specify a valid e-mail address that will be reachable and active. If
            you become unreachable through the main contact details for longer
            than 30 calendar days, FSOFT may terminate the Service that you have
            used in progress with immediate effect, without refunding any of the
            Service Fees and erase your Account with and/or without any backup
            Content, without further notice.
          </p>
          <p className="divider-35">
            3. You are fully responsible for all activities that occur under
            your Account and Content even if such activities or uses were not
            committed by you. FSOFT will not be liable for any loss or damage
            arising from unauthorized use of your password or your failure to
            comply with the ToU.
          </p>
          <p className="divider-35">
            4. Notwithstanding any other provision in the ToU, if there is a
            Security Event, FSOFT may, without liability or prejudice to its
            other rights and without prior notice to you, remove the relevant
            your Content and disable your Account until the relevant Security
            Event has been resolved. FSOFT shall give a written notice as soon
            as is reasonably practicable of the nature of the relevant Security
            Event.
          </p>
        </div>
      )}

      {currentTab === "rules3" && (
        <div id="rules3">
          <h3 className="divider-35">Services</h3>
          <p className="divider-35">
            Subject to the Definitive Agreement, the FSOFT shall, during the
            Service Term: provide the Service of accessing akaAT Studio; akaAT
            Test Engine and akaAT Management
          </p>
          <p className="divider-35">
            FSOFT may at its own discretion develop additional products,
            utilities and offerings or functionality of the Site or discontinue
            maintaining the Site and/or provision of any of the Service. In the
            event FSOFT adds new products, utilities and offerings or makes
            additional functionality available through the Site, and the ToU,
            shall be fully applicable to such new products, utilities and
            offerings or additional functionality of the Site.
          </p>
        </div>
      )}

      {currentTab === "rules4" && (
        <div id="rules4">
          <h3 className="divider-35">Use of the site and the service</h3>
          <p>
            You undertake to use the Site and/or the Service in a manner
            consistent with their purpose and functionality within the user
            interface provided by FSOFT. You shall not misuse the Site and/or
            the Service to cause any harm, damage, losses or interference for
            FSOFT, any third parties, as well as operation of the Site and/or
            provision of the Service. In particular, under no circumstance you
            shall use the Site or the Service to:
          </p>
          <ul className="divider-35">
            <li>
              upload, post, create, transmit or otherwise make available any
              content that is illegal, harmful, slanderous, unethical,
              defamatory, harassing, threatening or infringing upon another’s
              intellectual property, rights privacy or publicity, incites
              hatred, bigotry, racial, ethnic, sexual, religious or social
              discrimination or is insulting to any persons, groups and/or
              entities;
            </li>
            <li>
              attempt to access the Site or the Service in a way other than
              through interface and instructions provided by FSOFT;
            </li>
            <li>
              impersonate any person, representative of a legal entity and/or
              any organization without authorization, and misrepresent the
              source of any information;
            </li>
            <li>
              make available any materials containing viruses, worms, Trojan
              horses, routings, trap doors, time bombs or any other harmful
              codes, instructions, programs or components designed to directly
              or indirectly interfere with, manipulate, interrupt, destroy or
              limit the functionality or integrity of any computer software or
              hardware or data or telecommunications equipment;
            </li>
            <li>
              take any action or engage in any conduct that could directly or
              indirectly damage, disable, overburden, or impair the Site,
              Service or networks connected to the Service;
            </li>
            <li>
              make available any content which is false, misleading and/or
              promoting illegal activities;
            </li>
            <li>
              make available any content which is false, misleading and/or
              promoting illegal activities;
            </li>
            <li>
              collect and store personal data, private and personally
              identifiable information without express consent and authorization
              of the holder;
            </li>
            <li>
              assist to any person to perform any of actions referenced to in
              this section.
            </li>
          </ul>
        </div>
      )}

      {currentTab === "rules5" && (
        <div id="rules5">
          <h3 className="divider-35">Charges and payment</h3>
          <p className="divider-35">
            You shall pay the Service Fees to FSOFT for the utilization of
            Service in accordance with the Definitive Agreement.
          </p>
          <p className="divider-35">
            If you fail to pay the Service Fees in accordance with the payment
            term in the Definitive Agreement, by giving notice to you in writing
            or by electronic means (email), the Service shall be suspended until
            the payment is confirmed. If you fail to resume the Service in 10
            (ten) days since the overdue date, FSOFT may terminate the Service,
            and erase your Account with and/or without any backup Content,
            without further notice
          </p>
          <p className="divider-35">
            All amounts and fees stated or referred to in the Definitive
            Agreement are non-cancellable and non-refundable unless otherwise
            expressly noted, even if the Service are suspended or terminated
            prior to the end of the Service Term.
          </p>
          <p className="divider-35">
            FSOFT shall be entitled to establish or change its own Service Fees,
            at its own discretion by giving notice to you in writing or by
            electronic means (email). Notice shall be provided at least one (1)
            month before the changed fees take effect.
          </p>
        </div>
      )}

      {currentTab === "rules6" && (
        <div id="rules6">
          <h3 className="divider-35">Data protection</h3>
          <p className="divider-35">
            All Content entered, created, imported, or added to the Site by you,
            are exclusively owned by you. You shall have sole responsibility for
            the legality, reliability, integrity, accuracy and quality of the
            your Content.
          </p>
          <p className="divider-35">
            FSOFT shall treat and manage all Content confidentially, observing
            privacy regulations specified by data protection law.
          </p>
          <p className="divider-35">
            By entering into the ToU, you hereby acknowledge and agree that in
            order to ensure proper functionality of the Site and the Service,
            FSOFT is entitled to store, manage, process and transmit such
            Content.
          </p>
          <p>
            You hereby acknowledge and agree that through the use of the Site or
            the Service, you provide express and unconditional consent for the
            collection, use, disclosure and/or processing of the Content for one
            or more of the following purposes:
          </p>
          <ul className="divider-35">
            <li>
              to respond to legal processes or to comply with or as required by
              any applicable law, governmental or regulatory requirements of any
              relevant jurisdiction;
            </li>
            <li>
              to conduct research, analysis and development activities
              (including, but not limited to, data analytics, surveys, service
              development and/or profiling), to analyze how you use our Service,
              to improve the Service or products and/or to enhance your
              experience;
            </li>
            <li>
              to deal with and/or facilitate a business asset transaction
              (including, but not limited to, the purchase, sale, lease, merger,
              amalgamation or any other acquisition, disposal), where such
              transaction involves FSOFT and/or its affiliate as a participant;
            </li>
          </ul>
        </div>
      )}

      {currentTab === "rules7" && (
        <div id="rules7">
          <h3 className="divider-35">Proprietary rights</h3>
          <p className="divider-35">
            You hereby expressly and irrevocably acknowledge and agree that
            accessing the Site and using the Service do not vest you with any
            right, title or interest in intellectual property and other rights
            to content which is accessible at the Site or through the Service
            owned by FSOFT.
          </p>
          <p className="divider-35">
            You undertake not to use, sell, distribute, resell, decompile,
            disassemble, attempt to discover any source code, reverse engineer,
            modify, rework, reproduce, display, publicly perform, broadcast,
            rent, create security interest in, pledge, lien, encumber, license,
            make derivative works from content accessible at the Site or through
            the Service owned by FSOFT.
          </p>
        </div>
      )}

      {currentTab === "rules8" && (
        <div id="rules8">
          <h3 className="divider-35">Termination</h3>
          <p className="divider-35">
            This ToU shall commence on the Effective Date and shall continue
            until the expiry of the Service Term. The initial Service Term
            together with any subsequent renewal periods shall constitute the
            Service Term.
          </p>
          <p>
            FSOFT may terminate the ToU with immediate effect by giving written
            notice to you if:
          </p>
          <ul className="divider-35">
            <li>
              You fail to pay any amount due under Definitive Agreement on the
              due date for payment and remains in default not less than ten (10)
              days after being notified in writing to make such payment;
            </li>
            <li>
              You commit a material breach of any terms of the ToU which breach
              is irremediable or (if such breach is remediable) fails to remedy
              that breach within a period of ten (10) days after being notified
              in writing to do so;
            </li>
            <li>
              By using the Service, you violate the law of a country, state, or
              other territory of jurisdiction, authorities may require the
              service to be terminated;
            </li>
            <li>
              FSOFT suspends or ceases, or threatens to suspend or cease,
              carrying on all or a substantial part of its business or other
              activities;
            </li>
            <li>upon the dissolution, liquidation, or winding up of FSOFT.</li>
          </ul>
          <p>On termination of the ToU for any reason:</p>
          <ul className="divider-35">
            <li>
              all licenses granted under the ToU shall immediately terminate;
            </li>
            <li>
              FSOFT may destroy or otherwise dispose of your Account with and/or
              without any Content, no later than ten (10) days after the
              effective date of the termination of the ToU. In case of occurring
              your backup Content, by a written request, FSOFT shall use
              reasonable commercial endeavors to deliver the backup Content to
              you within 30 days of its receipt of such a written request,
              provided that you have, at that time, paid all fees and charges
              outstanding at and resulting from termination (whether or not due
              at the date of termination). You shall pay all reasonable expenses
              incurred by FSOFT in returning or disposing of your backup
              Content;
            </li>
            <li>
              any rights, remedies, obligations or liabilities of the parties
              that have accrued up to the date of termination, including the
              right to claim damages in respect of any breach of the Agreement
              that existed at or before the date of termination shall not be
              affected or prejudiced;
            </li>
            <li>
              any outstanding balance becomes immediately due and payable.
            </li>
          </ul>
        </div>
      )}

      {currentTab === "rules9" && (
        <div id="rules9">
          <h3 className="divider-35">Disclaimers</h3>
          <p className="divider-35">
            The Service are provided "AS IS" and without any warranties, claims
            or representations made by FSOFT of any kind either expressed,
            implied or statutory with respect to the Service, including, without
            limitation, warranties of quality, performance, non-infringement, or
            fitness for a particular purpose, nor are there any warranties
            created by course of dealing, course of performance or trade usage.
          </p>
          <p className="divider-35">
            Without limiting the foregoing and to the maximum extent permitted
            by applicable law, FSOFT does not warrant that the Service, the Site
            or the functions contained therein will be available, accessible,
            uninterrupted, timely, secure, accurate, complete or error-free,
            that defects, if any, will be corrected, or that the Site that makes
            the same available are free of viruses, worms, Trojan horses,
            routings, trap doors, time bombs or any other harmful codes,
            instructions, programs or components.
          </p>
        </div>
      )}

      {currentTab === "rules10" && (
        <div id="rules10">
          <h3 className="divider-35">Indemnity and limitations of liability</h3>
          <p>
            You shall indemnify, defend, and hold harmless FSOFT, its owners,
            directors, officers, employees, representatives, agents, successors
            and assigns from and against any and all losses, damages, costs and
            expenses, including reasonable legal fees, resulting from, arising
            out of or incident to any suit, claim, actions, proceedings, and all
            related liabilities based on:
          </p>
          <ul className="divider-35">
            <li>
              your violation or breach of any term of the ToU or any guidelines,
            </li>
            <li>your use or misuse of the Service or the Site,</li>
            <li>your breach of any law or any rights of a third party, or</li>
            <li>any content uploaded by you.</li>
          </ul>

          <p className="divider-35">
            In no event shall FSOFT be liable whether in contract, warranty,
            tort (including, without limitation, negligence (whether active,
            passive or imputed), service liability, strict liability or other
            theory), or other cause of action at law, in equity, by statute or
            otherwise, for: loss of use; loss of profits; loss of revenues; loss
            of data; loss of good will; or failure to realize anticipated
            savings, in each case whether direct or indirect; or any indirect,
            incidental, special or consequential damages, arising out of or in
            connection with the use or inability to use the Site or the Service.
          </p>
          <p>
            FSOFT’s total aggregate liability in contract, tort (including
            negligence or breach of statutory duty), misrepresentation,
            restitution or otherwise, arising in connection with the performance
            or contemplated performance of the ToU shall be limited to
          </p>
          <ul className="divider-35">
            <li>
              the total your Service Fees paid during the three (03) months
              immediately preceding the date on which the claim arose, or
            </li>
            <li>
              the amount set out in the Definitive Agreement, whichever is
              lesser.
            </li>
          </ul>
        </div>
      )}

      {currentTab === "rules11" && (
        <div id="rules11">
          <h3 className="divider-35">Force majeure</h3>
          <p>
            FSOFT shall not be liable for any failure or delay in performance of
            any of its obligations hereunder if such delay or failure to perform
            is caused by circumstances beyond its control, including, without
            limitation, government regulations or orders, outbreak of a state of
            emergency, acts of God, war, warlike hostilities, civil commotion,
            riots, epidemics, fire, strikes, lockouts, or any other similar
            cause or causes. You shall be required to accept any delayed
            shipment, lack of service, or delivery made within a reasonable
            time.
          </p>
        </div>
      )}
      {currentTab === "rules12" && (
        <div id="rules12">
          <h3 className="divider-35">Miscellaneous</h3>
          <p className="divider-35">
            If any provision of the ToU shall be deemed unlawful, void, or for
            any reason unenforceable under the law of any jurisdiction, then
            that provision shall be deemed severable from the ToU and shall not
            affect the validity and enforceability of any remaining provisions
            in such jurisdiction nor the validity and enforceability of the
            provision in question under the law of any other jurisdiction.
          </p>
          <p className="divider-35">
            The ToU are governed by the laws of Vietnam. Unless otherwise
            required by applicable laws, any dispute, controversy, claim or
            difference of any kind whatsoever arising out of or relating to the
            ToU shall be referred to and finally resolved by a competent court
            of Vietnam.
          </p>
          <p className="divider-35">
            You may not assign, sublicense or transfer any rights granted to you
            hereunder or subcontract any of your obligations.
          </p>
          <p className="divider-35">
            The ToU constitutes the entire agreement between the parties
            regarding its subject matter and supersedes any prior versions of
            the agreement, ToU, as well as arrangements, correspondence,
            conditions and any other similar ToU and conditions. The ToU are
            applicable exclusively to relations between you and FSOFT and does
            not create any third-party beneficiary rights.
          </p>
          <p className="divider-35">
            The Service may include programs or code that are licensed under an
            open source software ("OSS") license model. OSS programs and code
            are subject to the ToU, conditions and obligations of the applicable
            OSS license, and are SPECIFICALLY EXCLUDED FROM ALL WARRANTY AND
            SUPPORT OBLIGATIONS DESCRIBED ELSEWHERE IN THIS TOU.
          </p>
          <p className="divider-35">
            If you have any questions or concerns about the ToU or any issues
            raised in the ToU or on the Site, please contact FSOFT’ support team
            at: akaat.support@fpt.com.
          </p>
        </div>
      )}
    </>
  );
}

export default TermsOfUse;
