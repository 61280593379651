import React from "react";
import { Link } from "react-router-dom";
import { IconArrowRight } from "../../../../assets/icons/icon";
import { BASE_URL_SUPPORT } from "../../../../constants/endpoints";

const EventsFeatured = ({ dataFeatured }) => {
  const { highlight, popular } = dataFeatured;
  return (
    <section className="events-featured divider-80">
      <h3 className="featured-header divider-32">Featured</h3>
      <div className="featured-left">
        <Link to={`/event/article/${highlight?.attributes?.slug}`}>
          <img
            src={`${BASE_URL_SUPPORT}${highlight?.attributes?.image.data.attributes.url}`}
            alt=""
          />
        </Link>
        <div className="featured-content">
          <p className="events-tag divider-20">Events</p>
          <Link to={`/event/article/${highlight?.attributes?.slug}`}>
            <h5>{highlight?.attributes?.title}</h5>
          </Link>

          <p className="featured-short-desc">
            {highlight?.attributes?.description}
          </p>
        </div>
      </div>
      <div className="featured-right">
        {popular.map((item, index) => (
          <Link to={`/event/article/${item.attributes.slug}`} key={item.id}>
            <article className="featured-news divider-20">
              <div className="featured-vertical-line"></div>
              <p className="events-tag divider-20">Events</p>
              <h5>{item.attributes.title}</h5>
            </article>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default EventsFeatured;
