import React from "react";
import { IconArrowForward, IconDownload } from "../../../../assets/icons/icon";
import { TestEngineMobileBanner } from "../../../../assets/images";
import "./test-engine-banner.scss";
const MobileTestEngineBanner = () => {
  return (
    <section className="m-test-engine-banner">
      <img src={TestEngineMobileBanner} alt="" />
      <div className="banner-title">
        <h1 className="divider-24">akaAT Test Engine</h1>
        <p className="divider-32">
          The short-cut to effortless
          <br />
          {`{automation test orchestration}`}.
        </p>
        <a href={`https://docs.akaat.com/`} target={"_blank"} rel="noreferrer">
          <button className=" divider-60">
            Get started
            <IconArrowForward style={{ marginLeft: "8px" }} />
          </button>
        </a>
      </div>
    </section>
  );
};

export default MobileTestEngineBanner;
