import { Helmet } from "react-helmet-async";
import Announcement from "./announcement";
import Footer from "./footer";
import Nav from "./nav";

const Layout = ({
  children,
  categories,
  seo,
  hiddenStarted,
  subscribe,
  contactUs,
  studio,
  product,
  hiddenFooter,
}) => (
  <>
    {/* categories={categories} */}

    <Nav />
    <Announcement />
    <div style={{ margin: "auto" }} className="layout_main_content">
      {children}
    </div>
    {!hiddenFooter && (
      <Footer
        hiddenStarted={hiddenStarted}
        subscribe={subscribe}
        contactUs={contactUs}
        studio={studio}
        product={product}
      />
    )}
  </>
);

export default Layout;
