import { lazy } from "react";

const InsightsPage = lazy(() => import("./insights"));
const InsightsArticlePage = lazy(() =>
  import("./insights-article/insights-article")
);
const InsightsListPage = lazy(() => import("./insights-list/insights-list"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/insights",
    public: true,
    component: InsightsPage,
  },
  {
    path: "/insights/:category",
    public: true,
    component: InsightsListPage,
  },
  {
    path: "/insights/:category",
    public: true,
    component: InsightsListPage,
  },
  {
    path: "/blog/article/:slug",
    public: true,
    component: InsightsArticlePage,
  },
  {
    path: "/news/article/:slug",
    public: true,
    component: InsightsArticlePage,
  },
];
