import { lazy } from "react";

const ProfilePage = lazy(() => import("./profile"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/profile",
    public: false,
    component: ProfilePage,
  },
];
