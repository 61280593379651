import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useIntl } from "react-intl";

import { IconPlay } from "../../../../assets/icons/icon";

const WebinarsFeatured = ({ dataFeatured, setShowModal, setEmbedCode }) => {
  const { formatMessage } = useIntl();
  const CurrentDate = moment();
  const EventDate = moment(dataFeatured?.attributes?.event_starts_at);

  return (
    <section className="webinars-article-header divider-40">
      <div className="row">
        <div className="webinars-left col-lg-7 col-12">
          <div className="webinars-tag-wrap divider-48">
            <span className="webinars-tag">Webinars</span>
            <span>
              {moment(dataFeatured.attributes?.event_starts_at).format(
                "h:mm a, dddd, MMMM Do YYYY"
              )}
            </span>
          </div>
          <h1>{dataFeatured.attributes?.title}</h1>
          <p className="divider-48">{dataFeatured.attributes?.description}</p>
          <div className="webinars-btn divider-20">
            {EventDate.diff(CurrentDate) >= 0 ? (
              <a
                href={dataFeatured.attributes?.registration_link}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({ id: "webinar.msg_01" })}
              </a>
            ) : (
              <button
                onClick={() => {
                  setEmbedCode(dataFeatured.attributes?.url);
                  setShowModal(true);
                }}
              >
                {formatMessage({ id: "webinar.msg_02" })}
              </button>
            )}
          </div>
        </div>
        <div className="webinars-article-right col-lg-5 col-12">
          <div
            className="image-container"
            onClick={() => {
              setEmbedCode(dataFeatured.attributes?.url);
              setShowModal(true);
            }}
          >
            <img
              src={`https://img.youtube.com/vi/${dataFeatured.attributes?.url.replace(
                "https://www.youtube.com/watch?v=",
                ""
              )}/maxresdefault.jpg`}
              alt=""
            />
            <IconPlay className={"introduction-icon"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebinarsFeatured;
