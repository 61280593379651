import { Link } from "react-router-dom";
import {
  IconBenefitProduct4,
  IconBenefitProduct5,
  IconBenefitProduct6,
  IconHourglass,
} from "../../../../assets/icons/icon";
import "./device-farm-benefit.scss";
const DeviceFarmBenefit = () => {
  return (
    <section className="device-farm-benefit">
      <div className="w-1440">
        <h2 className="divider-52">
          Enhancing your remote <br />
          testing experience
        </h2>
        <div className="row benefit-center divider-32">
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitProduct4 />
            </div>
            <h3 className="divider-32">Ship smarter and together</h3>
            <p>
              Access your devices concurrently, detect and fix bugs faster with
              akaAT. The entire team can seamlessly collaborate and share
              devices across projects.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitProduct5 />
            </div>
            <h3 className="divider-32">Save delivery and travelling cost</h3>
            <p>
              akaAT Device Farm lets you remotely run tests on a large scale,
              using the same devices your customers use, without any onsite
              travel fee.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconHourglass />
            </div>
            <h3 className="divider-32">Optimize device idle time</h3>
            <p>
              Reduce waiting time with multiple test executions at a time. Keep
              track on devices usage rate and fill up idle time with other
              usage.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitProduct6 />
            </div>
            <h3 className="divider-32">Enhance security</h3>
            <p>
              akaAT takes high priority in security, as all data will be deleted
              after each remoting session to ensure no data leak.
            </p>
          </article>
        </div>
        <div className="benefit-wrap-btn">
          {/* <a
            href={`https://akatester.com/auth/register`}
            target={"_blank"}
            rel="noreferrer"
          >
            <button className="benefit-btn">Learn more</button>
          </a> */}
          <Link to={`/contact-us`}>
            <button className="benefit-btn">Learn more</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default DeviceFarmBenefit;
