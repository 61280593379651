import { lazy } from "react";

const AkaStudioPage = lazy(() => import("./aka-studio"));
/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: "/aka-studio",
    public: true,
    component: AkaStudioPage,
  },
];
