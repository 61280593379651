import {
  IconLogoTestManagement,
  IconLogoTextTestManagement,
} from "../../../../assets/icons/icon";
import {
  TestManagementLogoIntroduction,
  TestManagementMultiDevices,
} from "../../../../assets/images";
import "./test-engine-introduction.scss";
const TestEngineIntroduction = () => {
  return (
    <section className="test-engine-introduction">
      <div className="w-1440">
        <div className="row">
          <div className="col-12 col-md-6 introduction-text">
            {/* <img
              src={TestManagementLogoIntroduction}
              alt=""
              className="introduction-img"
            /> */}
            <div className="introduction-title">
              <IconLogoTextTestManagement />
              <h2 className="divider-40">Test Management</h2>
            </div>
            <p>
              Management has never been easier with akaAT Management! The
              platform enhances your test quality in real-time with advanced
              management tools and teamwork features. No more silos, your agile
              project members can work effectively, manage all types of work
              items and their state flows, no matter where they are.
            </p>
          </div>
          <div className="col-12 col-md-6 introduction-img">
            <img
              src={TestManagementMultiDevices}
              alt=""
              className="introduction-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestEngineIntroduction;
