import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { GroupIcon, IconFb, IconLinkedin } from "../../../assets/icons/icon";
import Layout from "../../../common/components/layout";
import { LinkedinShareButton, FacebookShareButton } from "react-share";
import moment from "moment";
import { getStrapiMedia } from "../../../common/utils";
import { BASE_URL_SUPPORT } from "../../../constants/endpoints";
import _ from "lodash";
import { HeadingRenderer } from "../../../common/components/table-content/table-content";
import Toc from "react-toc";

import "./success-stories-detail.scss";

const gfm = require("remark-gfm");

const SuccessStoriesDetailPage = () => {
  const { slug } = useParams();
  const location = useLocation();
  const category = location.pathname.replace(`/${slug}`, "").substring(1);
  // action
  const getStoryDetail = useStoreActions(
    (action) => action.successStories.fetchStoryDetail
  );
  // get
  const articleDetail = useStoreState((state) => state.successStories?.story);

  useEffect(() => {
    getStoryDetail({ slug: slug });
  }, [getStoryDetail, slug]);

  return (
    <Layout
      hiddenStarted
      seo={{
        metaTitle: articleDetail.attributes?.seo?.metaTitle,
        metaDescription: articleDetail.attributes?.seo?.metaDescription,
      }}
    >
      <div className="success-stories-detail">
        <h1 className="divider-48">{_.capitalize(category)}</h1>
        <section className="blog-article-header divider-40">
          <div className="blog-article-left">
            <div className="blog-article-tag-wrap divider-48">
              <span className="blog-tag">{_.capitalize(category)}</span>
              {/* <span>
                {moment(articleDetail.attributes?.publishedAt).format("LL")} —
                {articleDetail.attributes?.timeRead}
              </span> */}
            </div>
            <h1>{articleDetail.attributes?.title}</h1>
            <p>{articleDetail.attributes?.seo?.metaDescription}</p>
          </div>
          <div className="blog-article-right">
            <img
              src={`${BASE_URL_SUPPORT}${articleDetail.attributes?.image.data.attributes.url}`}
              alt=""
            />
          </div>
        </section>
        <section className="blog-article-content divider-60">
          <div className="blog-article-content-left">
            <ReactMarkdown
              children={articleDetail.attributes?.content}
              remarkPlugins={[gfm]}
              linkTarget="_blank"
              components={{
                img: ({ node, ...props }) => (
                  <img src={getStrapiMedia(props.src)} alt={props.alt} />
                ),
                h2: HeadingRenderer,
                h3: HeadingRenderer,
              }}
            />
          </div>
          <aside className="blog-article-content-right">
            <Toc
              markdownText={articleDetail.attributes?.content}
              className={"blog-article-toc"}
            />
          </aside>
        </section>
        <section className="blog-article-social-network">
          <p>Share this post</p>
          <div className="wrap-icons">
            <FacebookShareButton url={window.location.href} quote={"Akaat"}>
              <IconFb style={{ color: "#000000", marginRight: "20px" }} />
            </FacebookShareButton>
            <LinkedinShareButton url={window.location.href}>
              <IconLinkedin style={{ color: "#000000", marginRight: "20px" }} />
            </LinkedinShareButton>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SuccessStoriesDetailPage;
