import { lazy } from "react";

const SuccessStoriesPage = lazy(() => import("./success-stories"));
const SuccessStoriesDetailPage = lazy(() =>
  import("./success-stories-detail/success-stories-detail")
);
// const InsightsArticlePage = lazy(() =>
//   import("./insights-article/insights-article")
// );
// const InsightsListPage = lazy(() => import("./insights-list/insights-list"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/success-stories",
    public: true,
    component: SuccessStoriesPage,
  },
  {
    path: "/success-stories/:slug",
    public: true,
    component: SuccessStoriesDetailPage,
  },
];
