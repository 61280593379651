import React from "react";
import { ImageGroup } from "../../../../assets/icons/icon";
import { useIntl } from "react-intl";
import {
  ImageEcosystem,
  ImageSeamlesslyIntegration,
} from "../../../../assets/images";
import "./home-why.scss";

const HomeWhy = () => {
  const { formatMessage } = useIntl();
  return (
    <section className="home-why">
      <div className="w-1440">
        <h2 className="divider-60">
          {formatMessage({ id: "home.why.msg_01" })}
        </h2>
        <article className="row divider-120">
          <div className="col-6 why-left">
            <div className="why-ordinal divider-20">01</div>
            <h3 className="divider-20">
              {formatMessage({ id: "home.why.msg_02" })}
            </h3>
            <p>{formatMessage({ id: "home.why.msg_03" })}</p>
          </div>
          <div className="col-4">
            <img
              src={ImageSeamlesslyIntegration}
              alt=""
              className="why-image"
            />
          </div>
        </article>
        <article className="row divider-120">
          <div className="col-6 why-image">
            <img src={ImageEcosystem} alt="" width="82%" />
          </div>
          <div className="col-6 why-left">
            <div className="why-ordinal divider-20">02</div>
            <h3 className="divider-20">
              {formatMessage({ id: "home.why.msg_04" })}
            </h3>
            <p>{formatMessage({ id: "home.why.msg_05" })}</p>
          </div>
        </article>
        <article className="row">
          <div className="col-6 why-left">
            <div className="why-ordinal divider-20">03</div>
            <h3 className="divider-20">
              {formatMessage({ id: "home.why.msg_06" })}
            </h3>
            <p>{formatMessage({ id: "home.why.msg_07" })}</p>
          </div>
          <div className="col-6 why-right">
            <ImageGroup />
          </div>
        </article>
      </div>
    </section>
  );
};

export default HomeWhy;
