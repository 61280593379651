import { action, createStore, thunk } from "easy-peasy";
import keycloak from "./keycloak";

import { ENDPOINTS_SUPPORT } from "../constants/endpoints";
import { aloApi } from "../core/alo-api";
import { TYPE_PRICING } from "../constants";

const store = createStore({
  global: {
    /**
     * State
     */
    announcement: {},
    pricingTab: TYPE_PRICING.E2E,
    userInfo: null,
    /**
     * Action: Set global user info
     */
    // getAnnouncementPage: action((state, payload) => {
    //   if (!(state && state.globalUserInfo !== undefined)) return;

    //   if (payload && state.globalUserInfo.id !== payload.id) {
    //     state.globalUserInfo = payload;
    //   }
    // }),
    setAnnouncement: action((state, payload) => {
      state.announcement = payload?.data[0];
    }),

    setUserInfo: action((state, payload) => {
      state.userInfo = payload;
    }),

    /**
     * Action: Set global pricing tab
     */
    setPricingTab: action((state, payload) => {
      state.pricingTab = payload;
    }),

    fetchUserInfo: thunk(async (action, payload = {}) => {
      try {
        const user = await keycloak?.loadUserProfile();
        action.setUserInfo(user);
      } catch (err) {
        console.error(err);
        // handleError(err);
      } finally {
        // action.setLoadingList(false);
      }
    }),

    fetchAnnouncement: thunk(async (action, payload) => {
      try {
        // if (!(payload !== null && typeof payload === "object")) {
        //   throw new Error("Invalid Payload");
        // }

        // action.setLoadingList(true);
        // action.setQuery(payload);

        const url = ENDPOINTS_SUPPORT.ANNOUNCEMENT;
        const res = await aloApi.get(url);
        action.setAnnouncement(res.data);
      } catch (err) {
        console.error(err);
        // handleError(err);
      } finally {
        // action.setLoadingList(false);
      }
    }),
  },
});

export default store;
