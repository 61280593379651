import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import {
  IconArrowDownward,
  IconArrowUpward,
} from "../../../../assets/icons/icon";
import {
  FeedbackBaoviet,
  FeedbackFpt,
  FeedbackTpbank,
  FeedbackVv,
} from "../../../../assets/images";
import "./home-feedback.scss";

const HomeFeedback = () => {
  const sliderRef = useRef();
  const [slide, setSlide] = useState({
    slideIndex: 0,
    updateCount: 0,
  });
  const settings = {
    centerMode: true,
    centerPadding: "30px",
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    vertical: true,
    // afterChange: () =>
    //   setSlide({
    //     ...slide,
    //     updateCount: slide.updateCount + 1,
    //   }),
    // beforeChange: (currentSlide, nextSlide) => {
    //   return setSlide({ slideIndex: nextSlide }),
    // }

    beforeChange: (currentSlide, nextSlide) => {
      setSlide({ slideIndex: nextSlide });
    },
  };

  const next = () => {
    // Slider.slickNext();
    sliderRef.current.slickNext();
  };
  const previous = () => {
    // Slider.slickPrev();
    sliderRef.current.slickPrev();
  };

  // var $cSlick = $c.getSlick();
  // var index = $(this).data("index");
  // var current = $c.slickCurrentSlide();
  // var last = $cSlick.slideCount - 1;

  // if( current === 0 && index === last ) {
  //     $c.slickGoTo(-1);
  // } else if ( current === last && index === 0 ) {
  //     $c.slickGoTo( last + 1 );
  // } else {
  //     $c.slickGoTo(index);
  // }

  const test = (index) => {
    const current = slide.slideIndex;
    const last = 4;
    if (current === 0 && index === last) {
      sliderRef.current.slickGoTo(-1);
    } else if (current === last && index === 0) {
      sliderRef.current.slickGoTo(last + 1);
    } else {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <section className="home-feedback divider-145">
      <div className="w-1440">
        <div className="feedback-left">
          <h2>
            Voice of <span>Customers</span>
          </h2>
        </div>
        <div className="feedback-center">
          <Slider {...settings} ref={sliderRef}>
            <article
              className="feedback-item divider-40"
              onClick={() => test(0)}
            >
              <div className="feedback-logo-customer">
                <img src={FeedbackFpt} alt="" />
              </div>
              <div className="feedback-content">
                <p className="feedback-content-name divider-12">
                  FPT Information System
                </p>
                <p className="feedback-content-header">Simple and easy</p>
                <p className="feedback-content-comment divider-26">
                  akaAT's interfaces look like common IDE nowadays which help
                  you to create test case simply and easily.
                </p>
              </div>
            </article>
            <article
              className="feedback-item divider-40"
              onClick={() => test(1)}
            >
              <div className="feedback-logo-customer">
                <img src={FeedbackBaoviet} alt="" />
              </div>
              <div className="feedback-content">
                <p className="feedback-content-name divider-12">BAOVIET Life</p>
                <p className="feedback-content-header">
                  Save 70% contract processing time
                </p>
                <p className="feedback-content-comment divider-26">
                  “Bao Viet Life and FPT figured out automation processes to
                  reduce workload with akaAT”.
                </p>
              </div>
            </article>
            <article
              className="feedback-item divider-40"
              onClick={() => test(2)}
            >
              <div className="feedback-logo-customer">
                <img src={FeedbackVv} alt="" height={164} />
              </div>
              <div className="feedback-content">
                <p className="feedback-content-name divider-12">Video Visit</p>
                <p className="feedback-content-header">
                  Achieve 2x Faster Time to Market
                </p>
                <p className="feedback-content-comment divider-26">
                  We choose akaAT firstly because this product provides us with
                  a test solution on Cloud platform for health system. Secondly,
                  the tool produces clear, easy to read and understand reports.
                  Besides, the support team is also very active and
                  enthusiastic. These are things that convinced us to use akaAT.
                </p>
              </div>
            </article>
          </Slider>
        </div>
        <div className="feedback-right">
          <div className="feedback-arrow divider-8" onClick={previous}>
            <IconArrowUpward />
          </div>

          <div className="feedback-arrow" onClick={next}>
            <IconArrowDownward />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFeedback;
