import { IconLogoTextTestEngine } from "../../../../assets/icons/icon";
import {
  TestEngineImageMultiDevices,
  TestEngineLogoIntroduction,
} from "../../../../assets/images";
import "./test-engine-introduction.scss";
const TestEngineIntroduction = () => {
  return (
    <section className="test-engine-introduction">
      <div className="w-1440">
        <div className="row">
          <div className="col-12 col-md-6 introduction-text">
            {/* <img
              src={TestEngineLogoIntroduction}
              alt=""
              className="introduction-img"
            /> */}
            <div className="introduction-title">
              <IconLogoTextTestEngine />
              <h2 className="divider-40">Test Engine</h2>
            </div>

            {/* <IconLogoTextTestEngine />
            <h2 className="divider-40">Test Engine</h2> */}
            <p>
              Avoiding any human error and wasted resource, akaAT Test Engine
              keeps your automated testing in tune. akaAT Test Engine is the
              ultimate orchestration tool where users can configure and trigger
              tasks, making the whole test process streamlined and optimized
              productively. akaAT Test Engine works effectively with akaAT
              Agent, which is the main actor to execute all the jobs and
              generate a result for the report after testing.
            </p>
          </div>
          <div className="col-12 col-md-6 introduction-img">
            <img
              src={TestEngineImageMultiDevices}
              alt=""
              className="introduction-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestEngineIntroduction;
