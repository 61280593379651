import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Layout from "../../../common/components/layout";
import Pagination from "../../../common/components/pagination/pagination";
import "./insights-list.scss";
import _ from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import { BASE_URL_SUPPORT } from "../../../constants/endpoints";

const InsightsListPage = () => {
  const { formatMessage } = useIntl();
  const { category } = useParams();

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": `${category}ListPage` };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  const [infoPage, setInfoPage] = useState({
    MaxResultCount: 5,
    page: 1,
    SkipCount: 0,
  });

  const getPage = (page) => {
    setInfoPage({
      ...infoPage,
      page,
      SkipCount: (page - 1) * infoPage.MaxResultCount,
    });
  };

  const getSize = (size) => {
    setInfoPage({
      ...infoPage,
      MaxResultCount: size,
    });
  };

  // action
  const getArticles = useStoreActions(
    (action) => action.insights.fetchArticles
  );
  // get
  const allArticles = useStoreState((state) => state.insights?.allArticles);
  useEffect(() => {
    getArticles({ page: infoPage.page, tab: category });
  }, [category, getArticles, infoPage.page]);

  return (
    <Layout>
      <div className="insights-list-page">
        {category === "news" && (
          <Helmet>
            <title>
              {formatMessage({ id: "seo.insight.news_list.title_tag" })}
            </title>
            <meta
              name="description"
              content={formatMessage({
                id: "seo.insight.news_list.meta_description",
              })}
            />
          </Helmet>
        )}

        {category === "blog" && (
          <Helmet>
            <title>
              {formatMessage({ id: "seo.insight.blog_list.title_tag" })}
            </title>
            <meta
              name="description"
              content={formatMessage({
                id: "seo.insight.blog_list.meta_description",
              })}
            />
          </Helmet>
        )}

        <h1 className="divider-48">
          <Link to={`/insights`}>{_.capitalize(category)}</Link>
        </h1>
        <hr />
        {allArticles?.data.map((item) => (
          <article className="more-item" key={item.id}>
            <div className="insights-blog-img">
              <Link to={`/${category}/article/${item.attributes.slug}`}>
                <img
                  src={`${BASE_URL_SUPPORT}${item.attributes.image.data.attributes.url}`}
                  alt=""
                  className="divider-24"
                />
              </Link>
            </div>

            <div className="insights-blog-content">
              <div className="more-tag">
                {item.attributes?.tags?.split(",").map((tag) => (
                  <p className="insights-tag divider-20">{_.capitalize(tag)}</p>
                ))}
              </div>
              <Link to={`/${category}/article/${item.attributes.slug}`}>
                <h5 className="divider-12">{item.attributes.title}</h5>
              </Link>

              <p className="more-short-desc divider-20">
                {item.attributes.seo?.metaDescription}
              </p>
            </div>
          </article>
        ))}

        <Pagination
          getPage={getPage}
          getSize={getSize}
          numberPerPage={infoPage.MaxResultCount}
          totalValue={allArticles.total}
          currentPage={
            (infoPage.SkipCount + infoPage.MaxResultCount) /
              infoPage.MaxResultCount -
            1
          }
        />
      </div>
    </Layout>
  );
};

export default InsightsListPage;
