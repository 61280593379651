import { lazy } from "react";

const TermPage = lazy(() => import("./terms"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/terms",
    public: true,
    component: TermPage,
  },
];
