// export const X_AKAAT_REQUEST_RESOURCE = "x-akaat-request-resource";

// -----https://support.akaat.com/-----
export const BLOCKS_CONTENT_VIDEO = "blocks.content-video";
export const BLOCKS_CONTENT_VIDEO_FEATURED = "blocks.content-video-featured";

/* page download */
export const DOWNLOAD_PRODUCT = {
  STUDIO: "akaAT Studio",
  TEST_ENGINE: "akaAT Test Engine",
  TEST_MANAGEMENT: "akaAT Test Management",
  DEVICE_FARM: "akaAT Device Farm",
};
export const DOWNLOAD_OS = {
  WIN_OS: "winOS",
  MAC_OS: "macOS",
};
/* page pricing */
export const TYPE_PRICING = {
  FREE: "Free",
  ENTERPRISE: "Enterprise",
  // STUDIO: "Studio",
  // MANAGEMENT: "Management",
  // TEST_ENGINE: "TestEngine",
  E2E: "E2E",
  STANDALONE: "Standalone",
};
