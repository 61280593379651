import { lazy } from "react";

const AkaTestManagementPage = lazy(() => import("./aka-test-management"));
/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: "/aka-test-management",
    public: true,
    component: AkaTestManagementPage,
  },
];
