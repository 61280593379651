import React from "react";
import {
  ImageAsiaPacific,
  ImageAward,
  ImageInternationalBusiness,
  ImageItWorld,
  ImageSaokhue,
} from "../../../../assets/images";
import Slider from "react-slick/lib/slider";
import "./about-recognition.scss";

const settings = {
  // centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  // autoplay: true,
  // autoplaySpeed: 2000,
  // pauseOnHover: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        // infinite: true,
        // dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   }
    // }
  ],
};

const AboutRecognition = () => {
  return (
    <section className="about-recognition">
      <div className="recognition-text divider-40">
        <h2>Our recognitions</h2>
        <p>Let's see what we ahieved so far</p>
      </div>
      <div className="recognition-slides">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <img
              src={ImageItWorld}
              alt=""
              className="recognition-logo divider-20"
            />
            <h3 className="divider-8">IT World Awards 2022</h3>
            <p>Gold Globee® Winner - Software Test Automation</p>
          </article>

          <article className="recognition-row divider-20">
            <img
              src={ImageInternationalBusiness}
              alt=""
              className="recognition-logo divider-20"
            />
            <h3 className="divider-8">International Business Awards 2022</h3>
            <p>Silver Stevie® Winner - DevOps Solution</p>
          </article>

          <article className="recognition-row divider-20">
            <img
              src={ImageAsiaPacific}
              alt=""
              className="recognition-logo divider-20"
            />
            <h3 className="divider-8">Asia-Pacific Stevie® Awards 2022</h3>
            <p>
              Bronze Stevie® Winner - Award for the Innovation in Technology
              Development - Computer Industries
            </p>
          </article>

          <article className="recognition-row divider-20">
            <img
              src={ImageSaokhue}
              alt=""
              className="recognition-logo divider-20"
            />
            <h3 className="divider-8">Sao Khue 2021</h3>
            <p>Top 10 - New software products and solutions</p>
          </article>
        </Slider>
      </div>
    </section>
  );
};

export default AboutRecognition;
