import React from "react";
import {
  ImageAsiaPacific,
  ImageAward,
  ImageItWorld,
  ImageSaokhue,
} from "../../../../assets/images";
import Slider from "react-slick/lib/slider";
import "./aka-studio-benefit.scss";
import {
  IconChart,
  IconComputer,
  IconHourglass,
} from "../../../../assets/icons/icon";
import { Link } from "react-router-dom";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileStudioBenefit = () => {
  return (
    <section className="m-aka-studio-benefit">
      <div className="recognition-text divider-40">
        <h2 className="divider-52">
          Go codeless and effortless <br /> with akaAT
        </h2>
      </div>
      <div className="recognition-slides divider-40">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <IconHourglass />
            <h3 className="divider-8">
              Save up to 50% effort compared to manual testing
            </h3>
            <p>
              Shorten the testing period, reduce unnecessary fees and optimize
              testers’ efforts.
            </p>
          </article>
          <article className="recognition-row divider-20">
            <IconChart />
            <h3 className="divider-8">Powerful low-code platform</h3>
            <p>
              Create and run tests faster, smarter, and fully customizable, even
              with limited coding skills.
            </p>
          </article>

          <article className="recognition-row divider-20">
            {/* <img
              src={ImageSaokhue}
              alt=""
              className="recognition-logo divider-20"
            /> */}
            <IconComputer />
            <h3 className="divider-8">Free to use</h3>
            <p>
              Free for individuals with full basic functions and can be
              customizable with more features, up to enterprises’ needs.
            </p>
          </article>
        </Slider>
      </div>
      <div className="benefit-wrap-btn">
        <Link to="/download">
          <button className="benefit-btn">Learn more</button>
        </Link>
      </div>
    </section>
  );
};

export default MobileStudioBenefit;
