import { useKeycloak } from "@react-keycloak/web";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useContext, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  IconArrowBackMobile,
  IconArrowForwardMobile,
  IconDownload,
  IconGlobal,
  IconLogoDeviceFarmMobile,
  IconLogoStudioMobile,
  IconLogoTestEngineMobile,
  IconLogoTestManagementMobile,
  IconLogOut,
  IconMagnifier,
  IconNavEvent,
  IconNavInsights,
  IconNavSuccessStories,
} from "../../../assets/icons/icon";
import { LogoAkaAT, ProfileDefaultAvatar } from "../../../assets/images";
import { BASE_URL_FORUM } from "../../../constants/endpoints";
import { Context } from "../wrapperIntl";

const NavBarsMobileTab = ({ handelShowNavMobile }) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = useState("");
  switch (key) {
    case "sub_product":
      return (
        <div className="m-has-submenu">
          <div className="m-header-submenu" onClick={() => setKey("")}>
            <IconArrowBackMobile /> <p>Product</p>
          </div>
          <div className="m-nav-submenu">
            <Link to="/aka-studio" onClick={() => handelShowNavMobile(false)}>
              <IconLogoStudioMobile
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Studio
            </Link>
            <Link
              to="/aka-test-engine"
              onClick={() => handelShowNavMobile(false)}
            >
              <IconLogoTestEngineMobile
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Test Engine
            </Link>
            {/* <Link
              to="/success-stories"
              onClick={() => handelShowNavMobile(false)}
            >
              <IconNavSuccessStories
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Runtime Engine
            </Link> */}
            <Link
              to="/aka-test-management"
              onClick={() => handelShowNavMobile(false)}
            >
              <IconLogoTestManagementMobile
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Test Management
            </Link>
            <Link
              to="/aka-device-farm"
              onClick={() => handelShowNavMobile(false)}
            >
              <IconLogoDeviceFarmMobile
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Device Farm
            </Link>
            {/* <Link
              to="/success-stories"
              onClick={() => handelShowNavMobile(false)}
            >
              <IconNavSuccessStories
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Store
            </Link> */}
          </div>
        </div>
      );
    case "sub_resources":
      return (
        <div className="m-has-submenu">
          <div className="m-header-submenu" onClick={() => setKey("")}>
            <IconArrowBackMobile /> <p>Resources</p>
          </div>
          <div className="m-nav-submenu">
            <Link to="/download" onClick={() => handelShowNavMobile(false)}>
              <IconDownload
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Download
            </Link>
            <a
              href={`https://docs.akaat.com`}
              target={"_blank"}
              rel="noreferrer"
              onClick={() => handelShowNavMobile(false)}
            >
              <IconMagnifier
                style={{
                  marginRight: "4px",
                }}
              />
              Guideline
            </a>
            <Link to="/insights" onClick={() => handelShowNavMobile(false)}>
              <IconNavInsights
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Blog & News
            </Link>
            <Link to="/events" onClick={() => handelShowNavMobile(false)}>
              <IconNavEvent
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Event
            </Link>
            <Link
              to="/success-stories"
              onClick={() => handelShowNavMobile(false)}
            >
              <IconNavSuccessStories
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />
              Success stories
            </Link>
            <a
              href={`https://forum.akaat.com`}
              target={"_blank"}
              rel="noreferrer"
              onClick={() => handelShowNavMobile(false)}
            >
              <IconGlobal
                style={{
                  marginRight: "8px",
                  marginLeft: "2px",
                }}
              />
              Community
            </a>
          </div>
        </div>
      );
    // case "sub_english":
    //   return (
    //     <ul className="navbar-nav">
    //       <li className="m-nav-item">
    //         {/* <Link
    //                to="https:akaat.com/akastudio"
    //                className="nav-link "
    //              >
    //                {formatMessage({ id: "menu.product" })}
    //              </Link> */}
    //         <a
    //           href={`https:akaat.com/akastudio`}
    //           target={"_blank"}
    //           rel="noreferrer"
    //           className="nav-link "
    //         >
    //           {formatMessage({ id: "menu.product" })}
    //         </a>
    //       </li>
    //       <li className="m-nav-item">
    //         <Link to="#" className="nav-link">
    //           {formatMessage({ id: "menu.pricing" })}
    //         </Link>
    //       </li>

    //       {/* <Link to="/contact-us" className="nav-link ">
    //              {formatMessage({ id: "menu.pricing" })}Contact Us
    //              </Link> */}
    //       <li className="m-nav-item">
    //         <Link
    //           to="#"
    //           className="nav-link"
    //           onClick={() => setOpen(!open)}
    //           aria-controls="example-collapse-text"
    //           aria-expanded={open}
    //         >
    //           <p>{formatMessage({ id: "menu.resources" })}</p>
    //           <IconArrowForwardMobile />
    //         </Link>
    //       </li>
    //       <li className="m-nav-item">
    //         <Link to="#" className="nav-link">
    //           {formatMessage({ id: "menu.about" })}
    //         </Link>
    //       </li>
    //     </ul>
    //   );

    default:
      return (
        <ul className="navbar-nav">
          <li className="m-nav-item">
            <Link
              to="#"
              className="nav-link"
              onClick={() => setKey("sub_product")}
            >
              <p>{formatMessage({ id: "menu.product" })}</p>
              <IconArrowForwardMobile />
            </Link>
          </li>
          <li className="m-nav-item">
            <Link
              to="/pricing"
              className="nav-link"
              onClick={() => handelShowNavMobile(false)}
            >
              {formatMessage({ id: "menu.pricing" })}
            </Link>
          </li>

          <li className="m-nav-item">
            <Link
              to="#"
              className="nav-link"
              onClick={() => setKey("sub_resources")}
            >
              <p>{formatMessage({ id: "menu.resources" })}</p>
              <IconArrowForwardMobile />
            </Link>
          </li>
          <li className="m-nav-item">
            <Link
              to="/about"
              className="nav-link"
              onClick={() => handelShowNavMobile(false)}
            >
              {formatMessage({ id: "menu.about" })}
            </Link>
          </li>
        </ul>
      );
  }
};

const NavBarsMobile = ({ show, handelShowNavMobile }) => {
  const { keycloak } = useKeycloak();

  const userInfo = useStoreState((state) => state.global?.userInfo);

  const getUserInfo = useStoreActions((action) => action.global.fetchUserInfo);

  useEffect(() => {
    if (keycloak?.authenticated) {
      getUserInfo();
    }
  }, [keycloak?.authenticated]);

  const context = useContext(Context);
  const { formatMessage } = useIntl();

  return (
    <>
      {show && (
        <div
          className={`offcanvas offcanvas-end w-100 d-flex d-lg-none`}
          style={{
            visibility: "visible",
            transform: "none",
            // paddingRight: "26px",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div className="offcanvas-header">
            <Link to="/" className="nav-link">
              <img src={LogoAkaAT} alt="logo" width={92} height={28} />
            </Link>
            {/* <button
              type="button"
              className="btn-close text-reset"
              onClick={() => setShow(false)}
            >
              asdasdasd
            </button> */}
            <button
              type="button"
              className="btn-close text-reset"
              onClick={() => handelShowNavMobile(false)}
            ></button>
          </div>
          <div className="offcanvas-body">
            {/* bar */}
            <div className="m-nav-above me-auto mb-2 mb-lg-0">
              <NavBarsMobileTab handelShowNavMobile={handelShowNavMobile} />
            </div>
            <div className="m-nav-below">
              <a
                href={`${BASE_URL_FORUM}`}
                target={"_blank"}
                rel="noreferrer"
                className="divider-12"
              >
                <button className="btn">
                  <IconGlobal className="me-1" />
                  Join our Community
                </button>
              </a>

              <Link
                to="/download"
                className="divider-20"
                onClick={() => handelShowNavMobile(false)}
              >
                <button className="btn">
                  <IconDownload className="m-1" />
                  Download
                </button>
              </Link>

              {keycloak?.authenticated && (
                <div className="m-current-user">
                  <div className="m-has-user-info">
                    <Link
                      to="/profile"
                      onClick={() => handelShowNavMobile(false)}
                    >
                      <img
                        alt="avatar user"
                        width="40"
                        height="40"
                        src={`${
                          userInfo?.attributes?.avatar || ProfileDefaultAvatar
                        }`}
                      />
                    </Link>
                    {/* <div className="m-user-submenu">
                      <Link to="/profile">User info</Link>
                    </div> */}
                  </div>
                  <div
                    className="m-has-user-info"
                    onClick={() => handelShowNavMobile(false)}
                  >
                    <Link to="#" onClick={() => keycloak?.logout()}>
                      <IconLogOut />
                    </Link>
                    {/* <div className="m-user-submenu">
                      <Link to="#" onClick={() => keycloak?.logout()}>
                        Log out
                      </Link>
                    </div> */}
                  </div>
                </div>
              )}

              {!keycloak?.authenticated && (
                <button className="btn" onClick={() => keycloak?.login()}>
                  Log in
                </button>
              )}
            </div>
            {/* end bar */}
          </div>
        </div>
      )}
    </>
    // <div onClick={() => setShow(false)}>{show && "oke"}</div>
  );
};

export default NavBarsMobile;
