import React from "react";
import Slider from "react-slick/lib/slider";
import "./test-engine-feature.scss";
import {
  IconFeatureProduct4,
  IconFeatureProduct5,
  IconFeatureProduct6,
  IconTestingIntegrate,
} from "../../../../assets/icons/icon";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileTestManagementFeature = () => {
  return (
    <section className="m-test-engine-feature">
      <div className="recognition-text divider-40">
        <h2 className="divider-52">Core features</h2>
      </div>
      <div className="recognition-slides">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct5 />
            </div>
            <h3 className="divider-8">TEST PLANNING</h3>
            <p>
              Accelerate your teamwork with test plan, dynamic test case design,
              repositories, and task allocation features.
            </p>
          </article>
          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct4 />
            </div>
            <h3 className="divider-8">DYNAMIC REPORT</h3>
            <p>
              Show up-to-date aggregate visualized test. Report both manual &
              automation tests. Multiple view traceability to check the root
              cause
            </p>
          </article>

          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct6 />
            </div>
            <h3 className="divider-8">TRACEABILITY</h3>
            <p>Multiple view traceability to check the root cause</p>
          </article>

          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconTestingIntegrate />
            </div>
            <h3 className="divider-8">FLEXIBLE TEST PROCESS</h3>
            <p>
              Provide a flexible testing process for agile/scrum or waterfall
              projects.
            </p>
          </article>
        </Slider>
      </div>
    </section>
  );
};

export default MobileTestManagementFeature;
