import {
  IconBenefitTestEngine1,
  IconBenefitTestEngine2,
  IconBenefitTestEngine3,
} from "../../../../assets/icons/icon";
import "./test-engine-benefit.scss";
const TestEngineBenefit = () => {
  return (
    <section className="test-engine-benefit">
      <div className="w-1440">
        <h2 className="divider-52">
          Enhance your test orchestration <br /> with akaAT Test Engine
        </h2>
        <div className="row benefit-center divider-32">
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitTestEngine1 />
            </div>
            <h3 className="divider-32">
              Ultimate control over the test process
            </h3>
            <p>
              Orchestrate the automation test process: Plan, trigger, configure
              and schedule tests effortlessly.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitTestEngine2 />
            </div>
            <h3 className="divider-32">Deep insights from reports </h3>
            <p>
              Fetch extensive data from your tests, analyze and seamlessly
              receive notifications to your communication tools.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitTestEngine3 />
            </div>
            <h3 className="divider-32">Shorten the testing time</h3>
            <p>
              Accelerate testing by executing autonomous, repetitive tasks, and
              in parallel, from start to end.
            </p>
          </article>
        </div>
        <div className="benefit-wrap-btn">
          <a
          // href={`https://akatester.com/auth/register`}
          // target={"_blank"}
          // rel="noreferrer"
          >
            <button className="benefit-btn">Learn more</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default TestEngineBenefit;
