import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

import { TYPE_PRICING } from "../../../../constants";

import "./pricing-started.scss";
import PricingTestEngine from "./pricing-test-engine";
import PricingStudio from "./pricing-studio";

const PricingStarted = () => {
  // action
  const setCurrentTab = useStoreActions(
    (action) => action.global.setPricingTab
  );
  // get
  const currentTab = useStoreState((state) => state.global?.pricingTab);

  return (
    <section className="pricing-started">
      <h1 className="started-title divider-12">Ready to get started?</h1>
      <p className="divider-60">
        Free for personal use. All-in-one solution for team collaboration.
      </p>
      {/* tab */}
      <div className="pricing-tab divider-60">
        <div className="tab-left">
          <article
            onClick={() => setCurrentTab(TYPE_PRICING.E2E)}
            className={`tab-item ${
              currentTab === TYPE_PRICING.E2E ? "tab-active" : ""
            }`}
            tabIndex="1"
          >
            <div>E2E Solution</div> (Saving Up 30%)
          </article>
          <article
            onClick={() => setCurrentTab(TYPE_PRICING.STANDALONE)}
            className={`tab-item ${
              currentTab === TYPE_PRICING.STANDALONE ? "tab-active" : ""
            }`}
            tabIndex="2"
          >
            Standalone
          </article>
        </div>
      </div>
      {/* end tab */}
      <div className="warp-product">
        {currentTab === TYPE_PRICING.E2E && <PricingStudio />}
        {currentTab === TYPE_PRICING.STANDALONE && <PricingTestEngine />}
      </div>
    </section>
  );
};

export default PricingStarted;
