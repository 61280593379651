import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { Link } from "react-router-dom";
import {
  IconAdsProduct,
  IconDownload,
  IconGlobal,
  IconStep1,
  IconStep2,
  IconStep3,
} from "../../../../assets/icons/icon";
import { AdsImageProduct } from "../../../../assets/images";
import { BASE_URL_FORUM } from "../../../../constants/endpoints";

import "./ads-product.scss";

const AdsProduct = () => {
  const appInsights = useAppInsightsContext();
  const trackingDownloadStudio = useTrackEvent(
    appInsights,
    "trackingDownloadStudioFromPageAds",
    "trackingDownloadStudioFromPageAds"
  );

  return (
    <section className="ads-product">
      <div className="ads-product-container">
        <div className="ads-product-introduction">
          <div className="ads-product-left">
            <IconAdsProduct />
            <h3 className="font-BROmegaVN divider-24">
              Bứt phá năng suất làm việc với công cụ kiểm thử tự động akaAT
              Studio
            </h3>
            <p className="font-BROmegaVN">
              Công cụ kiểm thử tự động akaAT <span>miễn phí trọn đời</span> cho
              người dùng cá nhân, giúp tiết kiệm thời gian tạo kịch bản kiểm thử
              và tăng năng suất thực thi cho dự án.
            </p>
            <div className="ads-product-warp-btn">
              <Link to="/download" onClick={trackingDownloadStudio}>
                <button className="font-BROmegaVN btn me-4">
                  <IconDownload className="m-1" />
                  Tải ngay
                </button>
              </Link>

              <a
                href={`https://www.facebook.com/groups/akaatsupport`}
                target={"_blank"}
                rel="noreferrer"
              >
                <button className="font-BROmegaVN btn me-4">
                  <IconGlobal className="me-1" />
                  Khám phá cộng đồng
                </button>
              </a>
            </div>
          </div>
          <div className="ads-product-right">
            <img src={AdsImageProduct} alt="logo" />
          </div>
        </div>
        <div className="ads-product-step">
          <article className="product-step-detail">
            <p className="font-BROmegaVN">Bước 1: Tạo automation script</p>
            <IconStep1 />
          </article>
          <article className="product-step-detail">
            <p className="font-BROmegaVN">Bước 2: Thực thi script</p>
            <IconStep2 />
          </article>
          <article className="product-step-detail">
            <p className="font-BROmegaVN">Bước 3: Phân tích báo cáo</p>
            <IconStep3 />
          </article>
        </div>
      </div>
    </section>
  );
};
export default AdsProduct;
