import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { LogoAkaAT } from "../../../../assets/images";

const NavBarsMobileTab = ({ handelShowNavMobile }) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = useState("");

  return (
    <ul className="navbar-nav">
      <li className="m-nav-item">
        <Link to="#" className="nav-link" onClick={() => setKey("sub_product")}>
          <p>{formatMessage({ id: "menu.product" })}</p>
        </Link>
      </li>
      <li className="m-nav-item">
        <Link
          to="/pricing"
          className="nav-link"
          onClick={() => handelShowNavMobile(false)}
        >
          {formatMessage({ id: "menu.pricing" })}
        </Link>
      </li>

      <li className="m-nav-item">
        <Link
          to="#"
          className="nav-link"
          onClick={() => setKey("sub_resources")}
        >
          <p>{formatMessage({ id: "menu.resources" })}</p>
        </Link>
      </li>
      <li className="m-nav-item">
        <Link
          to="/about"
          className="nav-link"
          onClick={() => handelShowNavMobile(false)}
        >
          {formatMessage({ id: "menu.about" })}
        </Link>
      </li>
    </ul>
  );
};

const AdsNavMobile = ({ show, handelShowNavMobile }) => {
  return (
    <>
      {show && (
        <div
          className={`offcanvas offcanvas-end w-100 d-flex d-lg-none`}
          style={{
            visibility: "visible",
            transform: "none",
            // paddingRight: "26px",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div className="offcanvas-header">
            <Link to="/" className="nav-link">
              <img src={LogoAkaAT} alt="logo" width={92} height={28} />
            </Link>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={() => handelShowNavMobile(false)}
            ></button>
          </div>
          <div className="offcanvas-body">
            {/* bar */}
            <div className="m-nav-above me-auto mb-2 mb-lg-0">
              <NavBarsMobileTab handelShowNavMobile={handelShowNavMobile} />
            </div>
            {/* end bar */}
          </div>
        </div>
      )}
    </>
    // <div onClick={() => setShow(false)}>{show && "oke"}</div>
  );
};

export default AdsNavMobile;
