import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import EventsFeatured from "./components/event-featured";
import EventsMore from "./components/event-more";
import EventSearch from "./components/event-search";
import "./events.scss";
const EventsPage = ({ currentTab, textSearch, searchResults }) => {
  // action
  const getEvents = useStoreActions((action) => action.events.fetchEvents);

  const getEventsFeatured = useStoreActions(
    (action) => action.events.fetchEventsFeatured
  );
  // get
  const eventSearch = useStoreState((state) => state.events?.eventSearch);
  const moreEvents = useStoreState((state) => state.events?.moreEvents);
  const eventsFeatured = useStoreState((state) => state.events?.eventsFeatured);

  useEffect(() => {
    getEvents(1);
    getEventsFeatured();
  }, [getEventsFeatured, getEvents]);

  return (
    <section className="events-page">
      {searchResults ? (
        <EventSearch
          results={eventSearch || {}}
          textSearch={textSearch}
          currentTab={currentTab}
        />
      ) : (
        <>
          <EventsFeatured dataFeatured={eventsFeatured || {}} />
          <EventsMore currentTab={currentTab} data={moreEvents?.data || []} />
        </>
      )}
    </section>
  );
};

export default EventsPage;
