import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconArrowRight } from "../../../assets/icons/icon";
import Layout from "../../../common/components/layout";
import Pagination from "../../../common/components/pagination/pagination";

const InsightsNewsPage = () => {
  const [infoPage, setInfoPage] = useState({
    MaxResultCount: 6,
    page: 1,
    SkipCount: 0,
  });

  const getPage = (page) => {
    setInfoPage({
      ...infoPage,
      SkipCount: (page - 1) * infoPage.MaxResultCount,
    });
  };

  const getSize = (size) => {
    setInfoPage({
      ...infoPage,
      MaxResultCount: size,
    });
  };

  return (
    <Layout>
      <div className="insights-blog-page">
        <h1 className="divider-48">Blog</h1>
        <hr />
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <article className="more-item">
            <div className="insights-blog-img">
              <img
                src="https://akaat.com/services/portal/download/15192509_1833530230239671_7449326546432947363_n.jpeg"
                alt=""
                className="divider-24"
              />
            </div>

            <div className="insights-blog-content">
              <p className="insights-tag divider-20">Blog</p>
              <h5 className="divider-12">
                akaAT - Automation solution for programmers/testers
              </h5>
              <p className="more-short-desc divider-20">
                akaAT's mission is to help programmers and testers solve
                problems encountered at work and provide more weapons to
                increase productivity, reduce costs,...
              </p>
              <Link to="/blog/article/1">
                Learn more{" "}
                <span>
                  <IconArrowRight
                    style={{ color: "#EA2127", marginLeft: "12px" }}
                  />
                </span>
              </Link>
            </div>
          </article>
        ))}

        <Pagination
          getPage={getPage}
          getSize={getSize}
          numberPerPage={infoPage.MaxResultCount}
          totalValue={50}
          currentPage={
            (infoPage.SkipCount + infoPage.MaxResultCount) /
              infoPage.MaxResultCount -
            1
          }
        />
      </div>
    </Layout>
  );
};

export default InsightsNewsPage;
