import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { IconModalConfirmLogin } from "../../../../assets/icons/icon";
import ModalA from "../../../../common/components/modal/modalA";

import "./download-modal.scss";

const DownloadModal = ({ showModal, setShowModal, setSkipLogin }) => {
  const { keycloak } = useKeycloak();
  return (
    <div className="download-modal-ask-login">
      <ModalA show={showModal} setShow={setShowModal} hideCloseButton>
        {/* <div className="modalX__header">
          <h2>Modal header</h2>
        </div> */}
        <div className="modalX__body">
          <IconModalConfirmLogin />
          <p className="divider-20">
            Become a user to explore more features of akaAT!
          </p>
          <button className="login-btn" onClick={() => keycloak?.login()}>
            Sign in
          </button>
          <button
            className="skip-btn"
            onClick={() => {
              setShowModal(false);
              setSkipLogin(true);
            }}
          >
            Skip
          </button>
        </div>
        {/* <div className="modalX__footer">
          <button onClick={() => setShowModal(false)}>Close</button>
        </div> */}
      </ModalA>
    </div>
  );
};

export default DownloadModal;
