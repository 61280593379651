import { BASE_URL_SUPPORT } from "../../constants/endpoints";

export function getStrapiMedia(url) {
  if (url == null) {
    return null;
  }
  if (url.startsWith("http") || url.startsWith("//")) {
    return url;
  }
  return `${BASE_URL_SUPPORT || "http://localhost:1337"}${url}`;
}
