import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { IconPlay } from "../../../assets/icons/icon";
import { BASE_URL_SUPPORT } from "../../../constants/endpoints";

const StoriesMore = ({ data, showModal, setShowModal, setEmbedCode }) => {
  const { formatMessage } = useIntl();
  return (
    <section className="success-stories-more">
      <h2 className="divider-32">More Success Stories</h2>
      <div className="more-wrap-item divider-60">
        {data.map((item, index) => (
          <article className="more-item" key={item.id}>
            {item.attributes.video ? (
              <>
                <div
                  className="more-img"
                  onClick={() => {
                    setEmbedCode(item.attributes.video);
                    setShowModal(true);
                  }}
                >
                  <img
                    src={`https://img.youtube.com/vi/${item.attributes.video.replace(
                      "https://www.youtube.com/watch?v=",
                      ""
                    )}/maxresdefault.jpg`}
                    alt=""
                    className="divider-24"
                  />
                  <IconPlay className={"more-icon"} />
                </div>
                <h3>{item.attributes.title}</h3>
                <p className="more-short-desc divider-20">
                  {item.attributes.seo?.metaDescription}
                </p>
              </>
            ) : (
              <Link to={`/success-stories/${item.attributes.slug}`}>
                <img
                  src={`${BASE_URL_SUPPORT}${item.attributes?.image.data.attributes.url}`}
                  alt=""
                  className="divider-24"
                />

                <h3>{item.attributes.title}</h3>
                <p className="more-short-desc divider-20">
                  {item.attributes.seo?.metaDescription}
                </p>
              </Link>
            )}
          </article>
        ))}
        {/* {[1, 2, 3].map((item, index) => (
          <article className="more-item" key={index}>
            <div className="more-img">
              <img
                src="https://akaat.com/services/portal/download/15192509_1833530230239671_7449326546432947363_n.jpeg"
                alt=""
                className="divider-24"
              />
              <IconPlay className={"more-icon"} />
            </div>
            <h3>akaAT - Automation solution for programmers/testers</h3>
            <p className="more-short-desc divider-20">
              akaAT's mission is to help programmers and testers solve problems
              encountered at work and provide more weapons ...
            </p>
          </article>
        ))} */}
      </div>
    </section>
  );
};

export default StoriesMore;
