import { action, thunk } from "easy-peasy";
import { ENDPOINTS_SUPPORT } from "../../constants/endpoints";
import { aloApi } from "../../core/alo-api";

export const modelSuccessStories = {
  /**
   * State
   */
  storyHightLight: {},
  moreStories: {
    data: [],
    total: 0,
  },
  storySearch: {
    data: [],
    keyword: "",
    total: 0,
  },

  story: {},

  /**
   * Action: Set data story search
   */
  setDataStorySearch: action((state, payload) => {
    state.storySearch.data = payload.data;
    state.storySearch.keyword = payload.keyword;
    state.storySearch.total = payload.meta.pagination.total;
  }),
  /**
   * Action: Set loading change password
   */
  setData: action((state, payload) => {
    state.moreStories.data = payload.data;
    state.moreStories.total = payload.meta.pagination.total;
  }),

  /**
   * Action: Set loading change password
   */
  setStoryHightLight: action((state, payload) => {
    state.storyHightLight = payload.data.find(
      (story) => story.attributes.highlight
    );
  }),

  /**
   * Action: Set loading change password
   */
  setStory: action((state, payload) => {
    state.story = payload.data[0];
  }),

  /**
   * Action: Call api to change password
   */
  // changePassword: thunk(async (action, payload) => {
  //   try {
  //     if (!(payload !== null && typeof payload === "object")) {
  //       throw new Error("Invalid Payload");
  //     }

  //     action.setLoadingChangePass(true);

  //     // const url = ENDPOINTS.CHANGE_PASSWORD;
  //     const url = "http://localhost:3000/";

  //     const res = await aloApi.put(url, payload).then((res) => res.data);

  //     // notification.success({
  //     //   message: i18next.t("akaat:profile.changePassword"),
  //     //   description: i18next.t("akaat:profile.changedPasswordSuccessfully"),
  //     // });

  //     return res;
  //   } catch (err) {
  //     console.error(err);
  //     // handleError(err);
  //   } finally {
  //     action.setLoadingChangePass(false);
  //   }
  // }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchStoryHightLight: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);

      const url = `${ENDPOINTS_SUPPORT.SUCCESS_STORIES}`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setStoryHightLight(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchSuccessStories: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);

      const url = `${ENDPOINTS_SUPPORT.SUCCESS_STORIES}&pagination[page]=${payload}&pagination[pageSize]=6`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setData(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchStoryDetail: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS_SUPPORT.SUCCESS_STORIES}&filters[slug]=${payload.slug}`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setStory(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  fetchStorySearchResults: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS_SUPPORT.SUCCESS_STORIES}&_q=${payload.text}&pagination[page]=${payload.page}&pagination[pageSize]=6`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setDataStorySearch({ ...res, keyword: payload.text });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
