import { action, thunk } from "easy-peasy";
import { aloApi } from "../../core/alo-api";

export const modelBlog = {
  /**
   * State
   */
  data: [],

  /**
   * Action: Set loading change password
   */
  setData: action((state, payload) => {
    // if (state?.data === undefined || state?.total === undefined) return;

    // if (!(Array.isArray(payload) && payload.length)) {
    //   state.data = [];
    //   // state.total = 0;
    //   return;
    // }

    state.data = payload;
  }),

  /**
   * Action: Call api to change password
   */
  getArticleList: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);

      const url = "http://localhost:1337/api/articles?populate=*";
      const res = await aloApi.get(url).then((res) => res.data);
      action.setData(res?.data);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
