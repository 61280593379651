import {
  IconCodelessGui,
  IconDynamicTesting,
  IconMultiPlatforms,
  IconTestingIntegrate,
} from "../../../../assets/icons/icon";
import { StudioImageLogo } from "../../../../assets/images";
import "./aka-studio-feature.scss";
const AkaStudioFeature = () => {
  return (
    <section className="aka-studio-feature">
      <div className="w-1440">
        <h2 className="divider-52">
          Power-packed with <br />
          <span>features</span>
        </h2>
        <div className="feature-container">
          <div className="row feature-center divider-70">
            <article className="col-4 feature-item">
              <div className="benefit-icon divider-28">
                <IconCodelessGui />
              </div>
              <h3 className="divider-8">CODELESS GUI</h3>
              <p>
                With a user-friendly and customizable GUI, even beginners now
                can use akaAT Studio to build sophisticated tests, without a
                line of code.
              </p>
            </article>
            <article className="col-4 feature-item feature-item-right">
              <div className="benefit-icon divider-28">
                <IconTestingIntegrate className="feature-icon-right" />
              </div>
              <h3 className="divider-8">DYNAMIC DATA-DRIVEN TESTING</h3>
              <p>
                Using data files containing keywords related to the application
                being tested
              </p>
            </article>
          </div>
          <div className="row feature-center">
            <article className="col-4 feature-item">
              <div className="benefit-icon divider-28">
                <IconMultiPlatforms />
              </div>
              <h3 className="divider-8">MULTI PLATFORMS</h3>
              <p>
                Test multiple platforms at the same time (Web, mobile, API,
                window app...). One test case able to cover all platforms
              </p>
            </article>
            <article className="col-4 feature-item feature-item-right">
              <div className="benefit-icon divider-28">
                <IconDynamicTesting className="feature-icon-right" />
              </div>
              <h3 className="divider-8">SMART RECORDER</h3>
              <p>
                Test scripts, processes or product data – anything you want,
                akaAT ensures you can save it and conveniently listen to it
                later.
              </p>
            </article>
          </div>
          <div className="feature-img">
            <img src={StudioImageLogo} alt="logo akaAt studio" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AkaStudioFeature;
