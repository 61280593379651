import React from "react";
import { Link } from "react-router-dom";
import { IconDownload, ImageBanner } from "../../../../assets/icons/icon";
import { ImagePartners } from "../../../../assets/images";
import "./home-banner.scss";
const HomeBanner = () => {
  return (
    <section className="home-banner">
      <div className="w-1440">
        <div className="row">
          <div className="col-7 banner-title">
            <h1 className="divider-24">
              The All-in-one Automation Test Solution for anyone
            </h1>
            <p className="divider-32">
              Why waste your <span>{`{TIME}`}</span>
              <br /> while you can choose Automation Test?
            </p>
            <Link to="/download">
              <button className="banner-btn divider-60">
                <IconDownload className="me-1" />
                GET akaAT for Free
              </button>
            </Link>

            <img src={ImagePartners} alt="fireSpot" height={40} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
