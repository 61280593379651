import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconArrowBack, IconHome } from "../../assets/icons/icon";
import { Image404 } from "../../assets/images";
import Layout from "../../common/components/layout";

import "./not-found.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Layout hiddenFooter>
      <div className="not-found-page">
        <img src={Image404} alt="not found result" />
        <div className="not-found-description">
          <h1>Page not found</h1>
          <p className="divider-40">
            Sorry, the page you are looking for doesn't exist.
          </p>
          <div className="not-found-wrap-btn">
            <button className="not-found-btn" onClick={() => navigate(-1)}>
              <IconArrowBack style={{ marginRight: 5 }} />
              Go back
            </button>
            <Link to="/">
              <button className="not-found-btn">
                <IconHome style={{ marginRight: 5 }} /> Homepage
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
