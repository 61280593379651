import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Layout from "../../common/components/layout";
import InsightsFeatured from "./components/insights-featured";
import InsightsMore from "./components/insights-more";
import InsightsSearch from "./components/insights-search";
import InsightsTab from "./components/insights-tab";
import "./insights.scss";

const InsightsPage = () => {
  const { formatMessage } = useIntl();
  const [currentTab, setCurrentTab] = useState("blog");
  const [textSearch, setTextSearch] = useState("");
  const [searchResults, setSearchResults] = useState(false);

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "InsightsPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  const handelTab = (name) => {
    console.log("name", name);
  };
  const renderTab = () => {
    switch (currentTab) {
      case "news":
        return <h1 className="divider-48">News</h1>;
      default:
        return <h1 className="divider-48">Blog</h1>;
    }
  };

  // action
  const getArticles = useStoreActions(
    (action) => action.insights.fetchArticles
  );

  const getArticlesFeatured = useStoreActions(
    (action) => action.insights.fetchArticlesFeatured
  );

  const getMoreArticles = useStoreActions(
    (action) => action.insights.fetchMoreArticles
  );

  // get
  const moreArticles = useStoreState((state) => state.insights?.moreArticles);
  const articlesFeatured = useStoreState(
    (state) => state.insights?.articlesFeatured
  );
  const articleSearch = useStoreState((state) => state.insights?.articleSearch);

  useEffect(() => {
    if (textSearch.length === 0) {
      getArticles({ page: 1, tab: currentTab });
      getArticlesFeatured(currentTab);
      getMoreArticles(currentTab);
    }
  }, [
    getArticlesFeatured,
    getArticles,
    currentTab,
    textSearch.length,
    getMoreArticles,
  ]);

  return (
    <Layout>
      <div className="insights-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.insight.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.insight.meta_description" })}
          />
        </Helmet>
        {renderTab()}
        <InsightsTab
          handelTab={handelTab}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
          setSearchResults={setSearchResults}
        />
        {searchResults ? (
          <InsightsSearch
            results={articleSearch || {}}
            textSearch={textSearch}
            currentTab={currentTab}
          />
        ) : (
          <>
            <InsightsFeatured
              currentTab={currentTab}
              dataFeatured={articlesFeatured || {}}
            />
            <hr />
            <InsightsMore
              currentTab={currentTab}
              data={moreArticles?.data || []}
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default InsightsPage;
