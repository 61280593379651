import { useStoreActions } from "easy-peasy";
import React from "react";
import { IconSearch } from "../../assets/icons/icon";

const EventsTab = ({
  currentTab,
  handelTab,
  setCurrentTab,
  setTextSearch,
  textSearch,
  setSearchResults,
}) => {
  const getEventSearchResults = useStoreActions(
    (action) => action.events.fetchEventSearchResults
  );

  const handelSearch = () => {
    if (textSearch.length > 0) {
      setSearchResults(true);
      getEventSearchResults({ text: textSearch, tab: currentTab, page: 1 });
    } else {
      setSearchResults(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      handelSearch();
    }
  };

  return (
    <section className="events-tab divider-80">
      <div className="tab-left">
        <article
          onClick={() => {
            setCurrentTab("Events");
            if (textSearch.length > 0) {
              setSearchResults(true);
              getEventSearchResults({
                text: textSearch,
                tab: "Events",
                page: 1,
              });
            } else {
              setSearchResults(false);
            }
          }}
          className={`tab-item ${currentTab === "Events" ? "tab-active" : ""}`}
          tabIndex="1"
        >
          Events
        </article>
        <article
          onClick={() => {
            setCurrentTab("Webinars");
            if (textSearch.length > 0) {
              setSearchResults(true);
              getEventSearchResults({
                text: textSearch,
                tab: "Webinars",
                page: 1,
              });
            } else {
              setSearchResults(false);
            }
          }}
          className={`tab-item ${
            currentTab === "Webinars" ? "tab-active" : ""
          }`}
          tabIndex="2"
        >
          Webinars
        </article>
      </div>
      <div className="tab-right">
        <div className="search-container">
          <button onClick={handelSearch}>
            <IconSearch />
          </button>
          <input
            type="text"
            placeholder="Search..."
            className=""
            onChange={(event) => setTextSearch(event.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>
    </section>
  );
};

export default EventsTab;
