import { useStoreActions } from "easy-peasy";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";

import "./about-contact.scss";

const initData = {
  company: "",
  email: "",
  fname: "",
  lname: "",
  message: "",
  phone: "",
  subject: "",
};

const AboutContact = ({ aboutUs }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const sendContact = useStoreActions(
    (action) => action.contactUs.requestSendContact
  );

  const onSubmit = (data) => {
    sendContact(data);
    reset(initData);
  };

  const [valueCaptcha, setCaptcha] = useState(null);

  return (
    <section className="about-contact">
      <section className="contact-header divider-80">
        <h2>Contact us</h2>
        <p>We are here to accompany your automation test journey!</p>
      </section>
      <div className="contact-wrap">
        <section className="contact-left"></section>
        <section className="contact-right">
          <form onSubmit={handleSubmit(onSubmit)}>
            <article className="contact-form-field  contact-wrap-field divider-35">
              <div className="contact-row">
                <label htmlFor="fname">
                  First name <span className="contact-required">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  {...register("fname", { required: true, maxLength: 80 })}
                />
                {errors.fname && (
                  <span className="contact-required">
                    This field is required
                  </span>
                )}
              </div>
              <div className="contact-row">
                <label htmlFor="lname">Last Name</label>
                <br />
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  {...register("lname", { required: true, maxLength: 80 })}
                />
                {errors.lname && (
                  <span className="contact-required">
                    This field is required
                  </span>
                )}
              </div>
            </article>
            <article className="contact-form-field contact-wrap-field divider-35">
              <div className="contact-row">
                <label htmlFor="email">
                  Email<span className="contact-required">*</span>
                </label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
                {errors.email && (
                  <span className="contact-required">
                    This field is required
                  </span>
                )}
              </div>
              <div className="contact-row">
                <label htmlFor="phone">
                  Phone Number<span className="contact-required">*</span>
                </label>
                <br />
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  {...register("phone", { required: true, maxLength: 80 })}
                />
                {errors.phone && (
                  <span className="contact-required">
                    This field is required
                  </span>
                )}
              </div>
            </article>
            <article className="contact-form-field divider-35">
              <label htmlFor="company">
                Company<span className="contact-required">*</span>
              </label>
              <br />
              <input
                type="text"
                id="company"
                name="company"
                {...register("company", { required: true, maxLength: 80 })}
              />
              {errors.company && (
                <span className="contact-required">This field is required</span>
              )}
            </article>
            <article className="contact-form-field divider-35">
              <label htmlFor="subject">
                Subject<span className="contact-required">*</span>
              </label>
              <br />
              <input
                type="text"
                id="subject"
                name="subject"
                {...register("subject", { required: true, maxLength: 80 })}
              />
              {errors.subject && (
                <span className="contact-required">This field is required</span>
              )}
            </article>
            <article className="contact-form-field divider-40">
              <label htmlFor="message">
                Message<span className="contact-required">*</span>
              </label>
              <br />
              <textarea
                id="message"
                name="message"
                rows="3"
                {...register("message", { required: true, maxLength: 80 })}
              />
              {errors.subject && (
                <span className="contact-required">This field is required</span>
              )}
            </article>
            <div className="contact-captcha divider-40">
              <ReCAPTCHA
                sitekey="6LdxjhEaAAAAAJLwAt4bKYDtPkBCM-lVVY8-Nz5d"
                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={(e) => setCaptcha(e.value)}
                onExpired={() => setCaptcha(null)}
                onErrored={() => setCaptcha(null)}
              />
            </div>
            <button className="contact-btn" disabled={valueCaptcha === null}>
              Submit
            </button>
          </form>
        </section>
      </div>
    </section>
  );
};

export default AboutContact;
