const VietNam = {
  insights: {},
  home: {
    why: {
      msg_01: "aaaaaaaaaaaaaaaaa",
      msg_02: "ccccccccccccc",
      msg_03:
        "akaAT allows all users to operate automation test, whether they use any operating platform, development system and testing environment.",
      msg_04: "End-to-end testing solution",
      msg_05:
        "Covering all application functions and stages, akaAT makes the testing journey shorter and effortless. Ready-made, tailored platforms and tools with user-friendly UI makes it easy for beginners with codeless .",
      msg_06: "Global Support & Training",
      msg_07:
        "More than simply offering a testing tool or platform, akaAT collaborates and accompanies you for an enhanced testing experience. Wherever you are, we are with you!",
    },
  },
};

export default VietNam;
