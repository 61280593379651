import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { TYPE_PRICING } from "../../constants";

import MobilePricingStudio from "./pricing-studio";
import MobilePricingTestEngine from "./pricing-test-engine";
import "./pricing-started.scss";

const MobilePricingStarted = () => {
  // action
  const setCurrentTab = useStoreActions(
    (action) => action.global.setPricingTab
  );
  // get
  const currentTab = useStoreState((state) => state.global?.pricingTab);

  return (
    <section className="m-pricing-started">
      <div className="w-375">
        <h1 className="product-title divider-12">Ready to get started?</h1>
        <p className="divider-60">
          Free for personal use. All-in-one solution for team collaboration.
        </p>
        {/* tab */}
        <div className="pricing-tab divider-60">
          <div className="tab-left">
            <article
              onClick={() => setCurrentTab(TYPE_PRICING.E2E)}
              className={`tab-item ${
                currentTab === TYPE_PRICING.E2E ? "tab-active" : ""
              }`}
              tabIndex="1"
            >
              <div>E2E Solution</div> (Saving Up 30%)
            </article>
            <article
              onClick={() => setCurrentTab(TYPE_PRICING.STANDALONE)}
              className={`tab-item ${
                currentTab === TYPE_PRICING.STANDALONE ? "tab-active" : ""
              }`}
              tabIndex="2"
            >
              Standalone
            </article>
          </div>
        </div>
        {/* end tab */}
        {currentTab === TYPE_PRICING.E2E && <MobilePricingStudio />}
        {currentTab === TYPE_PRICING.STANDALONE && <MobilePricingTestEngine />}
      </div>
    </section>
  );
};

export default MobilePricingStarted;
