import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useStoreActions } from "easy-peasy";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  IconFb,
  IconGithub,
  IconLinkedin,
  IconLocation,
  IconMail,
  IconYoutube,
} from "../../assets/icons/icon";
import Layout from "../../common/components/layout";

import "./contact-us.scss";

const initData = {
  company: "",
  email: "",
  fname: "",
  lname: "",
  message: "",
  phone: "",
  subject: "",
};

const ContactUsPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { formatMessage } = useIntl();

  const sendContact = useStoreActions(
    (action) => action.contactUs.requestSendContact
  );

  useEffect(() => {
    // sendContact();
  }, []);

  const onSubmit = (data) => {
    sendContact(data);
    reset(initData);
  };
  const [valueCaptcha, setCaptcha] = useState(null);

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "ContactUsPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout hiddenStarted>
      <div className="contact-us-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.contact.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.contact.meta_description" })}
          />
        </Helmet>
        <section className="contact-header divider-80">
          <h1>Contact us</h1>
          <p>We are here to accompany your automation test journey!</p>
        </section>
        <div className="contact-wrap">
          <section className="contact-left">
            <article className="contact-item divider-68">
              <h2>Contact form</h2>
              <p>
                Fill up the form and our team will get back to you within 24
                hours.
              </p>
            </article>
            <article className="contact-item divider-40">
              <h3>Head quarter</h3>
              <p>
                <IconLocation className="contact-icon" />
                FPT Tower, No.10 Pham Van Bach street, Dich Vong Ward, Cau Giay
                District, Hanoi City
              </p>
            </article>
            <article className="contact-item">
              <h3>Contact us</h3>
              <p>
                <IconMail className="contact-icon" /> akaat.support@fpt.com
              </p>
            </article>
            <div className="contact-icon-social">
              <IconYoutube />
              <IconGithub style={{ margin: "0px 20px" }} />
              <IconFb style={{ margin: "0px 20px 0px 0px" }} />
              <IconLinkedin />
            </div>
          </section>
          <section className="contact-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <article className="contact-form-field  contact-wrap-field divider-35">
                <div className="contact-row">
                  <label htmlFor="fname">
                    First name <span className="contact-required">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    {...register("fname", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      maxLength: {
                        value: 80,
                        message: "Please enter no more than 80 characters",
                      },
                    })}
                  />
                  {errors.fname && (
                    <span className="contact-required">
                      {errors.fname.message}
                    </span>
                  )}
                </div>
                <div className="contact-row">
                  <label htmlFor="lname">
                    Last Name <span className="contact-required">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    {...register("lname", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      maxLength: {
                        value: 80,
                        message: "Please enter no more than 80 characters",
                      },
                    })}
                  />
                  {errors.lname && (
                    <span className="contact-required">
                      {errors.lname.message}
                    </span>
                  )}
                </div>
              </article>
              <article className="contact-form-field contact-wrap-field divider-35">
                <div className="contact-row">
                  <label htmlFor="email">
                    Email<span className="contact-required">*</span>
                  </label>
                  <br />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="contact-required">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="contact-row">
                  <label htmlFor="phone">
                    Phone Number<span className="contact-required">*</span>
                  </label>
                  <br />
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    {...register("phone", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      maxLength: {
                        value: 20,
                        message: "Please enter no more than 20 characters",
                      },
                    })}
                  />
                  {errors.phone && (
                    <span className="contact-required">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </article>
              <article className="contact-form-field divider-35">
                <label htmlFor="company">
                  Company<span className="contact-required">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="company"
                  name="company"
                  {...register("company", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    maxLength: {
                      value: 100,
                      message: "Please enter no more than 100 characters",
                    },
                  })}
                />
                {errors.company && (
                  <span className="contact-required">
                    {errors.company.message}
                  </span>
                )}
              </article>
              <article className="contact-form-field divider-35">
                <label htmlFor="subject">
                  Subject<span className="contact-required">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  {...register("subject", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    maxLength: {
                      value: 100,
                      message: "Please enter no more than 100 characters",
                    },
                  })}
                />
                {errors.subject && (
                  <span className="contact-required">
                    {errors.subject.message}
                  </span>
                )}
              </article>
              <article className="contact-form-field divider-40">
                <label htmlFor="message">
                  Message<span className="contact-required">*</span>
                </label>
                <br />
                <textarea
                  id="message"
                  name="message"
                  rows="3"
                  {...register("message", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    maxLength: {
                      value: 200,
                      message: "Please enter no more than 200 characters",
                    },
                  })}
                />
                {errors.message && (
                  <span className="contact-required">
                    {errors.message.message}
                  </span>
                )}
              </article>
              <div className="contact-captcha divider-40">
                <ReCAPTCHA
                  sitekey="6LdxjhEaAAAAAJLwAt4bKYDtPkBCM-lVVY8-Nz5d"
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={(e) => setCaptcha(e.value)}
                  onExpired={() => setCaptcha(null)}
                  onErrored={() => setCaptcha(null)}
                />
              </div>
              <button className="contact-btn" disabled={valueCaptcha === null}>
                Submit
              </button>
            </form>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsPage;
