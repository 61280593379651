import { IconLogoTextDeviceFarm } from "../../../../assets/icons/icon";
import {
  DeviceFarmImageMultiDevices,
  DeviceFarmLogoIntroduction,
} from "../../../../assets/images";
import "./device-farm-introduction.scss";
const DeviceFarmIntroduction = () => {
  return (
    <section className="device-farm-introduction">
      <div className="w-1440">
        <div className="row">
          <div className="col-12 col-md-6 introduction-text">
            {/* <img
              src={DeviceFarmLogoIntroduction}
              alt=""
              className="introduction-img"
            /> */}

            <div className="introduction-title">
              <IconLogoTextDeviceFarm />
              <h2 className="divider-40">
                DaaS – Device <br />
                as a Service
              </h2>
            </div>

            <p>
              akaAT Device Farm provides you with a wide range of online mobile
              devices, which are compatible with multiple platforms, for any
              testing purpose. Device Farm lets you execute tests on devices
              concurrently up to your demands, whether it is for manual or
              automation tests, enhancing your remote testing journey with
              real-world experience and improving shipping quality.
            </p>
          </div>
          <div className="col-12 col-md-6 introduction-img">
            <img
              src={DeviceFarmImageMultiDevices}
              alt=""
              className="introduction-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeviceFarmIntroduction;
