import React from "react";
import { ImageGlobal } from "../../../../assets/images";
import { BASE_URL_FORUM } from "../../../../constants/endpoints";
import "./home-community.scss";

const HomeCommunity = () => {
  return (
    <section className="home-community">
      <div className="w-1440">
        <div className="row">
          <div className="col-4 community-left">
            <h3>What's on our</h3>
            <h2 className="divider-12">Community?</h2>
            <p className="divider-40">
              akaAT Community – The official knowledge-sharing space for all
              akaAT users
            </p>
            <a href={`${BASE_URL_FORUM}`} target={"_blank"} rel="noreferrer">
              <button className="community-btn">Join our community</button>
            </a>
          </div>
          <div className="col-8 community-right">
            <img src={ImageGlobal} alt="global community" />
            {/* <img src={ImageLaptop} alt="" className="community-right-image" />
          <div className="community-logo">
            <img src={LogoAkaAT} alt="" width={70} />
          </div>
          <div className="community-video">
            <img src={PreviewVideo} alt="" width={210} />
            <IconPlay className={"community-icon"} />
          </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCommunity;
