import {
  IconFeatureProduct7,
  IconFeatureProduct8,
  IconFeatureProduct9,
} from "../../../../assets/icons/icon";
import {
  DeviceFarmImageLogo,
  DeviceFarmImageNetwork,
} from "../../../../assets/images";
import "./device-farm-feature.scss";
const DeviceFarmFeature = () => {
  return (
    <section className="device-farm-feature">
      <div className="w-1440">
        <h2 className="divider-52">Core features</h2>
        <div className="feature-container">
          <div className="feature-img-network">
            <img src={DeviceFarmImageNetwork} alt="logo akaAt studio" />
          </div>
          <div className="row feature-center divider-70">
            <article className="col-4 feature-item">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct7 />
              </div>
              <h3 className="divider-8">Multiple Access</h3>
              <p>
                Manage your inventory and execute your operations on multiple
                devices concurrently.
              </p>
            </article>
            <article className="col-4 feature-item feature-item-right">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct8 className="feature-icon-right" />
              </div>
              <h3 className="divider-8">Distributed Farms</h3>
              <p>
                Build your own farm for the offshore team to access across
                locations and develop without travelling.
              </p>
            </article>
          </div>
          <div className="row feature-center">
            <article className="col-4 feature-item">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct9 />
              </div>
              <h3 className="divider-8">Booking & Reservations</h3>
              <p>
                Keep track on device usage rate and available time slot to
                organize tests effectively.
              </p>
            </article>
          </div>
          <div className="feature-img">
            <img src={DeviceFarmImageLogo} alt="logo akaAt studio" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeviceFarmFeature;
