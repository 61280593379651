import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { HashLink } from "react-smooth-hash-link";
import { IconDownload, IconGlobal } from "../../../../assets/icons/icon";
import { AdsImageLogoFpt } from "../../../../assets/images";
import { BASE_URL_FORUM } from "../../../../constants/endpoints";

const AdsNavPC = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      {/* bar */}
      <div className="nav-left me-auto mb-2 mb-lg-0 d-none d-lg-block">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a href="#2" className="nav-link font-BROmegaVN">
              Sản phẩm
            </a>
          </li>
          <li className="nav-item">
            <a href="#3" className="nav-link font-BROmegaVN">
              Tính năng
            </a>
          </li>

          {/* <Link to="/contact-us" className="nav-link ">
                    {formatMessage({ id: "menu.pricing" })}Contact Us
                    </Link> */}
          <li className="nav-item">
            <a href="#4" className="nav-link font-BROmegaVN">
              Cộng đồng
            </a>
          </li>

          <li className="nav-item">
            <a href="#5" className="nav-link font-BROmegaVN">
              Đánh giá
            </a>
          </li>
          <li className="nav-item">
            <a href="#6" className="nav-link font-BROmegaVN">
              Hệ sinh thái akaAT
            </a>
          </li>
        </ul>
      </div>
      {/* value={language} onChange={changRouter} */}
      <div className="nav-right d-none d-lg-block ">
        <p className="font-BROmegaVN d-inline-block me-2">Powered by</p>
        <img src={AdsImageLogoFpt} alt="logo fpt software" width={96} />
      </div>
      {/* end bar */}
    </>
  );
};

export default AdsNavPC;
