import { lazy } from "react";

const ReleaseNotesPage = lazy(() => import("./release-notes"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/release-notes",
    public: true,
    component: ReleaseNotesPage,
  },
];
