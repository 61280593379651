import { lazy } from "react";

const DownloadPage = lazy(() => import("./download"));
/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: "/download",
    public: true,
    component: DownloadPage,
  },
];
