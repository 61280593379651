import Home from "../../assets/translation/home/en.json";
import Webinar from "../../assets/translation/webinar/en.json";
import Layout from "../../assets/translation/layout/en.json";
import Seo from "../../assets/translation/seo/en.json";

const English = {
  menu: {
    home: "Home",
    product: "Product",
    pricing: "Pricing",
    resources: "Resources",
    about: "About us",
  },
  ...Home,
  ...Webinar,
  ...Layout,
  ...Seo,
  insights: {
    msg_01: "View All",
    msg_02: "View all Blogs",
    msg_03: "View all News",
  },
  events: {
    msg_01: "View All",
  },
};
export default English;
