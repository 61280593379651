import { lazy } from "react";

const AboutPage = lazy(() => import("./about"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/about",
    public: true,
    component: AboutPage,
  },
];
