import { Link } from "react-router-dom";
import { IconDownload } from "../../../../assets/icons/icon";
import "./aka-studio-banner.scss";
const AkaStudioBanner = () => {
  return (
    <section className="aka-studio-banner">
      <div className="w-1440">
        <div className="row">
          <div className="col-12 .col-lg-5	 banner-title">
            <h1 className="divider-24">akaAT Studio</h1>
            <p className="divider-32">
              {`{Free}`} low-code automation testing tool <br />
              for everyone.
            </p>
            <Link to="/download">
              <button className="banner-btn divider-60">
                <IconDownload className="me-1" />
                Start for free
              </button>
            </Link>

            {/* <img src={ImagePartners} alt="fireSpot" height={40} /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AkaStudioBanner;
