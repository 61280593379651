/* eslint-disable react-hooks/rules-of-hooks */
import { useStoreState } from "easy-peasy";
import React, { useState } from "react";
import Layout from "../../common/components/layout";
import YoutubePopover from "../../common/components/modal/modal-video";
import MobileHomeBanner from "./components/home-banner/home-banner";
import MobileHomeBlog from "./components/home-blog/home-blog";
import MobileHomeCommunity from "./components/home-community/home-community";
import MobileHomeFeedback from "./components/home-feedback/home-feedback";
import MobileHomeIntroduction from "./components/home-introduction/home-introduction";
import MobileHomeProduct from "./components/home-product/home-product";
import MobileHomeRecognition from "./components/home-recognition/home-recognition";
import MobileHomeService from "./components/home-service/home-service";
import MobileHomeWhy from "./components/home-why/home-why";
import "./home.scss";

const MobileHomePage = () => {
  const globalUserInfo = useStoreState((state) => state);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="m-home-page">
      <MobileHomeBanner />
      <MobileHomeIntroduction
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <MobileHomeService />
      <MobileHomeProduct />
      <MobileHomeCommunity />
      <MobileHomeBlog />
      <MobileHomeWhy />
      <MobileHomeFeedback />
      <MobileHomeRecognition />
      <YoutubePopover
        showModal={showModal}
        setShowModal={setShowModal}
        embedCode={"https://www.youtube.com/watch?v=CVdbdQGiwoE"}
      />
    </div>
  );
};

export default MobileHomePage;
