import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIntl } from "react-intl";
import { IconPlay } from "../../../assets/icons/icon";
import Pagination from "../../../common/components/pagination/pagination";
import { BASE_URL_SUPPORT } from "../../../constants/endpoints";

const StoriesSearch = ({
  results,
  textSearch,
  showModal,
  setShowModal,
  setEmbedCode,
}) => {
  const { formatMessage } = useIntl();
  const { data, total } = results;

  const [infoPage, setInfoPage] = useState({
    MaxResultCount: 6,
    page: 1,
    SkipCount: 0,
  });

  const getPage = (page) => {
    setInfoPage({
      ...infoPage,
      page,
      SkipCount: (page - 1) * infoPage.MaxResultCount,
    });
  };

  const getSize = (size) => {
    setInfoPage({
      ...infoPage,
      MaxResultCount: size,
    });
  };

  const getStorySearchResults = useStoreActions(
    (action) => action.successStories.fetchStorySearchResults
  );

  const storySearch = useStoreState(
    (state) => state.successStories?.storySearch
  );

  useEffect(() => {
    getStorySearchResults({
      text: textSearch,
      page: infoPage.page,
    });
  }, [getStorySearchResults, infoPage.page]);

  if (data.length === 0) {
    return (
      <section className="success-stories-more">
        <div className="search_results_content">
          <p> Results for {`"${storySearch.keyword}"`} </p>
          <p>Sorry. There are no results for {`"${storySearch.keyword}"`}</p>
          <p>Try rewording your query, or browse through our site.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="success-stories-more">
      <h2 className="more-header">Results</h2>
      <div className="more-wrap-item divider-60">
        {data.map((item, index) => (
          <article className="more-item" key={item.id}>
            {console.log("item", item)}
            <Link to={`/${"success-stories"}/${item.attributes.slug}`}>
              <img
                src={`${BASE_URL_SUPPORT}${item.attributes.image.data.attributes.url}`}
                alt=""
                className="divider-24"
              />
            </Link>
            {/* <p className="insights-tag divider-20">{"success-stories"}</p> */}
            <Link to={`/${"success-stories"}/${item.attributes.slug}`}>
              <h3>{item.attributes.title}</h3>
            </Link>
            <p className="more-short-desc divider-20">
              {item.attributes.seo?.metaDescription}
            </p>
          </article>
        ))}
      </div>

      <Pagination
        getPage={getPage}
        getSize={getSize}
        numberPerPage={infoPage.MaxResultCount}
        totalValue={total}
        currentPage={
          (infoPage.SkipCount + infoPage.MaxResultCount) /
            infoPage.MaxResultCount -
          1
        }
      />
    </section>
  );
};

export default StoriesSearch;
