import React from "react";
import { Link } from "react-router-dom";
import { IconDownload } from "../../../../assets/icons/icon";
import {
  ImageMobileBanner,
  ImageMobilePartners,
} from "../../../../assets/images";
import "./home-banner.scss";
const MobileHomeBanner = () => {
  return (
    <section className="m-home-banner">
      <img src={ImageMobileBanner} alt="" />
      <div className="banner-title">
        <h1 className="divider-24">
          The All-in-one Automation Test Solution for anyone
        </h1>
        <p className="divider-32">
          Why waste your <span>{`{TIME}`}</span>
          <br /> while you can choose Automation Test?
        </p>
        <Link to="/download">
          <button className="divider-60">
            <IconDownload className="me-1" />
            GET akaAT for Free
          </button>
        </Link>
        <br />
        <img src={ImageMobilePartners} alt="fireSpot" />
      </div>
    </section>
  );
};

export default MobileHomeBanner;
