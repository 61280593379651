import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { IconArrowRight } from "../../../assets/icons/icon";
import { BASE_URL_SUPPORT } from "../../../constants/endpoints";

const InsightsFeatured = ({ currentTab, dataFeatured }) => {
  const { highlight, popular } = dataFeatured;
  return (
    <section className="insights-featured divider-80">
      <h3 className="featured-header divider-32">Featured</h3>
      <div className="featured-left">
        <Link to={`/${currentTab}/article/${highlight?.attributes?.slug}`}>
          <img
            src={`${BASE_URL_SUPPORT}${highlight?.attributes?.image.data.attributes.url}`}
            alt=""
          />
        </Link>

        <div className="featured-content">
          <div className="more-tag">
            {highlight?.attributes?.tags?.split(",").map((tag) => (
              <p className="insights-tag divider-20">{_.capitalize(tag)}</p>
            ))}
          </div>
          <Link to={`/${currentTab}/article/${highlight?.attributes?.slug}`}>
            <h5>{highlight?.attributes?.title}</h5>
          </Link>

          <p className="featured-short-desc divider-12">
            {highlight?.attributes?.seo?.metaDescription}
          </p>
        </div>
      </div>
      <div className="featured-right">
        {popular.map((item) => (
          <Link
            to={`/${currentTab}/article/${item.attributes.slug}`}
            key={item.id}
          >
            <article className="featured-news divider-20">
              <div className="featured-vertical-line"></div>
              <div className="more-tag">
                {item.attributes?.tags?.split(",").map((tag) => (
                  <p className="insights-tag divider-20">{_.capitalize(tag)}</p>
                ))}
              </div>
              <h5>{item.attributes.title}</h5>
            </article>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default InsightsFeatured;
