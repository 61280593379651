import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { IconArrowRight } from "../../../../assets/icons/icon";
import { BASE_URL_SUPPORT } from "../../../../constants/endpoints";
import Pagination from "../../../../common/components/pagination/pagination";
import { useStoreActions, useStoreState } from "easy-peasy";

const EventSearch = ({ currentTab, results, textSearch }) => {
  const { data, total } = results;
  const { formatMessage } = useIntl();

  const [infoPage, setInfoPage] = useState({
    MaxResultCount: 6,
    page: 1,
    SkipCount: 0,
  });

  const getPage = (page) => {
    setInfoPage({
      ...infoPage,
      page,
      SkipCount: (page - 1) * infoPage.MaxResultCount,
    });
  };

  const getSize = (size) => {
    setInfoPage({
      ...infoPage,
      MaxResultCount: size,
    });
  };

  const getEventSearchResults = useStoreActions(
    (action) => action.events.fetchEventSearchResults
  );

  const eventSearch = useStoreState((state) => state.events?.eventSearch);

  useEffect(() => {
    getEventSearchResults({
      text: textSearch,
      tab: currentTab,
      page: infoPage.page,
    });
  }, [currentTab, getEventSearchResults, infoPage.page]);

  if (data.length === 0) {
    return (
      <section className="events-more">
        <div className="search_results_content">
          <p> Results for {`"${eventSearch.keyword}"`} </p>
          <p>Sorry. There are no results for {`"${eventSearch.keyword}"`}</p>
          <p>Try rewording your query, or browse through our site.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="events-more">
      <h3 className="more-header">Results</h3>
      <div className="more-wrap-item divider-60">
        {data.map((item, index) => (
          <article className="more-item" key={item.id}>
            <Link to={`/event/article/${item.attributes.slug}`}>
              <img
                src={`${BASE_URL_SUPPORT}${item.attributes.image.data.attributes.url}`}
                alt=""
                className="divider-24"
              />
            </Link>
            <p className="events-tag divider-20">Events</p>
            <Link to={`/event/article/${item.attributes.slug}`}>
              <h5>{item.attributes.title}</h5>
            </Link>
            <p className="more-short-desc divider-20">
              {item.attributes.seo?.metaDescription}
            </p>
          </article>
        ))}
      </div>
      <Pagination
        getPage={getPage}
        getSize={getSize}
        numberPerPage={infoPage.MaxResultCount}
        totalValue={total}
        currentPage={
          (infoPage.SkipCount + infoPage.MaxResultCount) /
            infoPage.MaxResultCount -
          1
        }
      />
    </section>
  );
};

export default EventSearch;
