import { lazy } from "react";

const AkaTestEnginePage = lazy(() => import("./aka-test-engine"));
/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: "/aka-test-engine",
    public: true,
    component: AkaTestEnginePage,
  },
];
