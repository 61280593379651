import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../common/components/layout";
import "./blog.scss";
const BlogPage = () => {
  // /api/articles?locale=${locale}&populate=image

  const getArticleList = useStoreActions(
    (action) => action.blog.getArticleList
  );
  const listArticle = useStoreState((state) => state.blog);

  useEffect(() => {
    getArticleList();
  }, [getArticleList]);
  return (
    <Layout>
      <div className="blog_page">
        <div>BlogPage</div>
        <div className="blog_page_content">
          {listArticle &&
            listArticle.data.map((article, i) => {
              return (
                <article id="post-8436" key={i} style={{ maxWidth: 300 }}>
                  <Link to={`/article/${article.attributes.slug}`}>
                    <figure className="post-thumbnail">
                      <img
                        src={`http://localhost:1337${article.attributes.image.data.attributes.url}`}
                        width={288}
                        height={162}
                        alt=""
                      />
                    </figure>
                    <header>
                      <h3 className="blog_page_card_time_to_read">
                        <a
                          href="https://ld-wp73.template-help.com/monstroid2/default/2018/08/15/have-an-idea-but-need-the-perfect-implementation-m2-is-here-for-you/"
                          rel="bookmark"
                        >
                          News
                        </a>
                      </h3>
                    </header>
                    <div className="blog_page_card">
                      {article.attributes.title}
                    </div>
                  </Link>
                </article>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default BlogPage;
