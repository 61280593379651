import React from "react";
import { Link } from "react-router-dom";
import "./download-end.scss";

const DownloadEnd = () => {
  return (
    <section className="download-end">
      <div className="row download-container">
        <div className="col-12  col-md-6 banner-title">
          <h1 className="divider-24">
            We’ve got you covered.
            <br /> Get akaAT for your team now!
          </h1>
          <Link to="/contact-us">
            <button className="banner-btn">Contact US</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default DownloadEnd;
