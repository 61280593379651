import React from "react";
import { IconPlay } from "../../../../assets/icons/icon";
import { PreviewVideo } from "../../../../assets/images";
import "./home-introduction.scss";

const HomeIntroduction = ({ showModal, setShowModal }) => {
  return (
    <section className="home-introduction">
      <div className="w-1440">
        <div className="row">
          <div className="col-6 introduction-text">
            <h2 className="divider-40">
              akaAT – End-to-end <br />
              automation testing ecosystem
            </h2>
            <p>
              Built as an end-to-end testing ecosystem for both individual users
              and enterprises, akaAT converges all SaaS testing platforms, tools
              and management system to make the testing journey way more
              efficient, faster and saving, for anyone and at anytime.
            </p>
          </div>
          <div
            className="col-6 introduction-video"
            onClick={() => setShowModal(true)}
          >
            <div className="introduction-anime">
              <img
                src={`https://img.youtube.com/vi/CVdbdQGiwoE/maxresdefault.jpg`}
                alt=""
                className="introduction-img"
              />

              <IconPlay className={"introduction-icon"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIntroduction;
