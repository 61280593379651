import React from "react";
import Slider from "react-slick";
import {
  ImageAsiaPacific,
  ImageAward,
  ImageInternationalBusiness,
  ImageItWorld,
  ImageSaokhue,
} from "../../../../assets/images";
import "./home-recognition.scss";

const settings = {
  // centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  // autoplay: true,
  // autoplaySpeed: 2000,
  // pauseOnHover: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        // infinite: true,
        // dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   }
    // }
  ],
};

const HomeRecognition = () => {
  return (
    <section className="home-recognition">
      {/* <img src={ImageAward} alt="" className="recognition-poster" /> */}
      <div className="w-1440">
        <div className="recognition-text">
          <h3>Recognitions</h3>
          <p>From industry leading organizations</p>
        </div>
        <div className="recognition-wrap">
          <Slider {...settings}>
            <article className="recognition-row divider-20">
              <img
                src={ImageItWorld}
                alt=""
                className="recognition-logo divider-20"
              />
              <h3 className="divider-8">IT World Awards 2022</h3>
              <p>Gold Globee® Winner - Software Test Automation</p>
            </article>

            <article className="recognition-row divider-20">
              <img
                src={ImageInternationalBusiness}
                alt=""
                className="recognition-logo divider-20"
              />
              <h3 className="divider-8">International Business Awards 2022</h3>
              <p>Silver Stevie® Winner - DevOps Solution</p>
            </article>

            <article className="recognition-row divider-20">
              <img
                src={ImageAsiaPacific}
                alt=""
                className="recognition-logo divider-20"
              />
              <h3 className="divider-8">Asia-Pacific Stevie® Awards 2022</h3>
              <p>
                Bronze Stevie® Winner - Award for the Innovation in Technology
                Development - Computer Industries
              </p>
            </article>

            <article className="recognition-row">
              <img
                src={ImageSaokhue}
                alt=""
                className="recognition-logo divider-20"
              />
              <h3 className="divider-8">Sao Khue 2021</h3>
              <p>Top 10 - New software products and solutions</p>
            </article>
          </Slider>
        </div>
        {/* <div className="recognition-slides">
          <Slider {...settings}>
            <article className="recognition-row divider-20">
              <img
                src={ImageItWorld}
                alt=""
                className="recognition-logo divider-20"
              />
              <h3 className="divider-8">IT World Awards 2022</h3>
              <p>Gold Globee® Winner - Software Test Automation</p>
            </article>

            <article className="recognition-row divider-20">
              <img
                src={ImageInternationalBusiness}
                alt=""
                className="recognition-logo divider-20"
              />
              <h3 className="divider-8">International Business Awards 2022</h3>
              <p>Silver Stevie® Winner - DevOps Solution</p>
            </article>

            <article className="recognition-row divider-20">
              <img
                src={ImageAsiaPacific}
                alt=""
                className="recognition-logo divider-20"
              />
              <h3 className="divider-8">Asia-Pacific Stevie® Awards 2022</h3>
              <p>
                Bronze Stevie® Winner - Award for the Innovation in Technology
                Development - Computer Industries
              </p>
            </article>

            <article className="recognition-row divider-20">
              <img
                src={ImageSaokhue}
                alt=""
                className="recognition-logo divider-20"
              />
              <h3 className="divider-8">Sao Khue 2021</h3>
              <p>Top 10 - New software products and solutions</p>
            </article>
          </Slider>
        </div> */}
      </div>
    </section>
  );
};

export default HomeRecognition;
