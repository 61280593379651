import React from "react";
import Slider from "react-slick/lib/slider";
import "./test-management-benefit.scss";
import {
  IconBenefitProduct7,
  IconBenefitProduct8,
  IconBenefitProduct9,
} from "../../../../assets/icons/icon";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileTestManagementBenefit = () => {
  return (
    <section className="m-test-management-benefit">
      <div className="recognition-text divider-40">
        <h2 className="divider-52">Potential benefits</h2>
      </div>
      <div className="recognition-slides divider-40">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <IconBenefitProduct7 />
            <h3 className="divider-8">Simplify management work</h3>
            <p>
              Remove bottlenecks with centralized tasks to ensure an effective
              management.
            </p>
          </article>
          <article className="recognition-row divider-20">
            <IconBenefitProduct8 />
            <h3 className="divider-8">Boost collaboration productivity</h3>
            <p>
              Make it easier for your team to work together with transparent
              tasks and user-friendly collaboration features.
            </p>
          </article>

          <article className="recognition-row divider-20">
            {/* <img
              src={ImageSaokhue}
              alt=""
              className="recognition-logo divider-20"
            /> */}
            <IconBenefitProduct9 />
            <h3 className="divider-8">Enhance your test quality</h3>
            <p>
              From the planning to execution stages, akaAT Collab provides your
              team with features to plan and better optimize for your target
              goals.
            </p>
          </article>
        </Slider>
      </div>
      <div className="benefit-wrap-btn">
        <a
        // href={`https://akatester.com/auth/register`}
        // target={"_blank"}
        // rel="noreferrer"
        >
          <button className="benefit-btn">Learn more</button>
        </a>
      </div>
    </section>
  );
};

export default MobileTestManagementBenefit;
