import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { IconPlay } from "../../../assets/icons/icon";
import Pagination from "../../../common/components/pagination/pagination";
import WebinarsMore from "./components/webinars-more";
import "./webinars.scss";
import { useStoreActions, useStoreState } from "easy-peasy";
import YoutubePopover from "../../../common/components/modal/modal-video";
import moment from "moment";
import WebinarSearch from "./components/webinars-search";
import WebinarsFeatured from "./components/webinars-featured";

const WebinarsPage = ({ currentTab, textSearch, searchResults }) => {
  const { formatMessage } = useIntl();
  const [infoPage, setInfoPage] = useState({
    MaxResultCount: 6,
    page: 1,
    SkipCount: 0,
  });

  const getPage = (page) => {
    setInfoPage({
      ...infoPage,
      page,
      SkipCount: (page - 1) * infoPage.MaxResultCount,
    });
  };

  const getSize = (size) => {
    setInfoPage({
      ...infoPage,
      MaxResultCount: size,
    });
  };

  // action
  const getWebinars = useStoreActions((action) => action.events.fetchWebinars);

  const getWebinarHightLight = useStoreActions(
    (action) => action.events.fetchWebinarHightLight
  );
  // get
  const eventSearch = useStoreState((state) => state.events?.eventSearch);
  const moreWebinars = useStoreState((state) => state.events?.moreWebinars);
  const webinarHightLight = useStoreState(
    (state) => state.events?.webinarHightLight
  );

  useEffect(() => {
    getWebinars(infoPage.page);
    getWebinarHightLight();
  }, [getWebinarHightLight, getWebinars, infoPage.page]);
  const [showModal, setShowModal] = useState(false);
  const [embedCode, setEmbedCode] = useState("");
  return (
    <div className="webinars-page">
      {searchResults ? (
        <WebinarSearch
          results={eventSearch || {}}
          textSearch={textSearch}
          currentTab={currentTab}
          setShowModal={setShowModal}
          setEmbedCode={setEmbedCode}
          infoPage={infoPage}
          setInfoPage={setInfoPage}
        />
      ) : (
        <>
          <WebinarsFeatured
            dataFeatured={webinarHightLight || {}}
            setShowModal={setShowModal}
            setEmbedCode={setEmbedCode}
          />
          <hr />
          <WebinarsMore
            data={moreWebinars?.data || []}
            showModal={showModal}
            setShowModal={setShowModal}
            setEmbedCode={setEmbedCode}
          />
        </>
      )}

      <YoutubePopover
        showModal={showModal}
        setShowModal={setShowModal}
        embedCode={embedCode}
      />
      <Pagination
        getPage={getPage}
        getSize={getSize}
        numberPerPage={infoPage.MaxResultCount}
        totalValue={moreWebinars.total}
        currentPage={
          (infoPage.SkipCount + infoPage.MaxResultCount) /
            infoPage.MaxResultCount -
          1
        }
      />
    </div>
  );
};

export default WebinarsPage;
