import React from "react";
import {
  AboutImageWhy1,
  AboutImageWhy2,
  AboutImageWhy3,
  AboutImageWhy4,
} from "../../../../assets/images";

import "./about-why.scss";

const AboutWhy = () => {
  return (
    <section className="about-why">
      <div className="why-container">
        <div className="row why-list-item">
          <div className="col-6 col-md-3 why-bg-red-item">
            <h2>Why choose us?</h2>
          </div>
          <div className="col-6 col-md-3">
            <img src={AboutImageWhy1} alt="Images" />
          </div>
          <div className="col-6 col-md-3 why-bg-white-item">
            <h3>High scalability</h3>
            <p>
              The unlimited number of agents allows testers to perform multiple
              tests on multiple devices simultaneously.
            </p>
          </div>
          <div className="col-6 col-md-3">
            <img src={AboutImageWhy2} alt="Images" />
          </div>
        </div>
        <div className="row why-list-item">
          <div className="col-6 col-md-3">
            <img src={AboutImageWhy3} alt="Images" />
          </div>
          <div className="col-6 col-md-3 why-bg-white-item">
            <h3>End-to-End Testing</h3>
            <p>
              Able to cover all application’s function, all stage of the user’s
              journey and business process.
            </p>
          </div>
          <div className="col-6 col-md-3">
            <img src={AboutImageWhy4} alt="Images" />
          </div>
          <div className="col-6 col-md-3 why-item">
            <h3>Strong global support</h3>
            <p>
              A diversified working model including onsite, offshore, nearshore
              combined with over 400+ specialists allows support and service to
              be easily and readily delivered
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutWhy;
