import { lazy } from "react";

const AkaDeviceFarmPage = lazy(() => import("./aka-device-farm"));
/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: "/aka-device-farm",
    public: true,
    component: AkaDeviceFarmPage,
  },
];
