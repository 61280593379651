import React from "react";
import Slider from "react-slick/lib/slider";
import "./test-engine-feature.scss";
import {
  IconFeatureProduct1,
  IconFeatureProduct2,
  IconFeatureProduct3,
  IconFeatureProduct4,
} from "../../../../assets/icons/icon";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileTestEngineFeature = () => {
  return (
    <section className="m-test-engine-feature">
      <div className="recognition-text divider-40">
        <h2 className="divider-52">Feature</h2>
      </div>
      <div className="recognition-slides">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct1 />
            </div>
            <h3 className="divider-8">JOB SCHEDULE</h3>
            <p>
              Schedule test jobs to run automatically according to the plan,
              saving time, cost and effort.
            </p>
          </article>
          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct2 />
            </div>
            <h3 className="divider-8">CI/CD</h3>
            <p>
              Easily connect with any devices or systems to control automation
              test jobs.
            </p>
          </article>

          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct3 />
            </div>
            <h3 className="divider-8">REALTIME NOTIFICATION</h3>
            <p>
              Push notifications to users in real time and through out the job,
              from starting to reporting phases. Support multiple popular
              communication systems: Mail, Slack, Microsoft Teams, Zalo.
            </p>
          </article>

          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct4 />
            </div>
            <h3 className="divider-8">CUSTOMIZED REPORT</h3>
            <p>
              Create customizable reports based on requirements from the system
              data.
            </p>
          </article>
        </Slider>
      </div>
    </section>
  );
};

export default MobileTestEngineFeature;
