import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import _ from "lodash";

import { IconArrowRight } from "../../../assets/icons/icon";
import { BASE_URL_SUPPORT } from "../../../constants/endpoints";

const InsightsMore = ({ currentTab, data }) => {
  const { formatMessage } = useIntl();
  const renderBtnByTab = (tab) => {
    switch (tab) {
      case "blog":
        return (
          <Link to="/insights/blog">
            {formatMessage({ id: "insights.msg_02" })}
          </Link>
        );
      case "news":
        return (
          <Link to="/insights/news">
            {formatMessage({ id: "insights.msg_03" })}
          </Link>
        );

      // eslint-disable-next-line no-fallthrough
      default:
        return (
          <Link to="/insights/all">
            {formatMessage({ id: "insights.msg_01" })}
          </Link>
        );
    }
  };
  return (
    <section className="insights-more">
      <h3 className="more-header divider-32">More Articles</h3>
      <div className="more-wrap-item divider-60">
        {data.map((item, index) => (
          <article className="more-item" key={item.id}>
            <Link to={`/${currentTab}/article/${item.attributes.slug}`}>
              <img
                src={`${BASE_URL_SUPPORT}${item.attributes.image.data?.attributes?.url}`}
                alt=""
                className="divider-24"
              />
              <div className="more-item-content">
                <div className="more-tag">
                  {item.attributes?.tags?.split(",").map((tag) => (
                    <p className="insights-tag divider-20">
                      {_.capitalize(tag)}
                    </p>
                  ))}
                </div>
                <p className="font-archia">{item.attributes.title}</p>
                <p className="more-short-desc divider-20">
                  {item.attributes.seo?.metaDescription}
                </p>
              </div>
            </Link>
          </article>
        ))}
      </div>
      <div className="insights-btn">{renderBtnByTab(currentTab)}</div>
    </section>
  );
};

export default InsightsMore;
