import React, { useState, memo } from "react";
import ReactPaginate from "react-paginate";
import "./pagination.scss";

// interface event {
//   selected: number;
// }

// type Props = {
//   getPage: (page: number) => void,
//   getSize: (size: number) => void,
//   totalValue: number,
//   currentPage: number,
//   numberPerPage: number,
//   checkTotalValuePerPage: {
//     status: boolean,
//     type: string[],
//   },
// };

export const Pagination = ({
  totalValue,
  numberPerPage,
  checkTotalValuePerPage,
  currentPage,
  getPage,
  getSize,
}) => {
  const [valuePerPage, setValuePerPage] = useState(numberPerPage);

  const pageCount = Math.ceil(totalValue / valuePerPage);

  const changePage = (e) => {
    document.body.scrollTop = document.documentElement.scrollTop = 700;
    getPage(e.selected + 1);
  };

  return (
    <div className="nav__paging">
      <div className="nav-paging">
        <ReactPaginate
          previousLabel=""
          nextLabel=""
          containerClassName="nav__paging__list"
          previousClassName="tabs-page-arrow"
          nextClassName="tabs-page-arrow"
          previousLinkClassName="arrow left"
          nextLinkClassName="arrow right"
          activeClassName="tabs-page-active"
          pageCount={pageCount}
          onPageChange={changePage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          forcePage={currentPage}
        />
      </div>
    </div>
  );
};

export default memo(Pagination);
