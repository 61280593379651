import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PricingImageAtomp } from "../../../../assets/images";

import "./pricing-atomp.scss";

const PricingAtomp = () => {
  return (
    <section className="pricing-atomp">
      <div className="row">
        <div className="col-12 col-lg-5 test-engine-left">
          <h2>akaAT Device Farm</h2>
          <p className="divider-43">
            Test smarter with our online devices at anywhere.
          </p>
          <Link to="/contact-us">
            <button className="download-btn">Contact us</button>
          </Link>
        </div>
        <div className="col-12 col-lg-7 test-engine-right">
          <img src={PricingImageAtomp} alt=" man and woman discussing" />
        </div>
      </div>
    </section>
  );
};

export default PricingAtomp;
