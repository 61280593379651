import { lazy } from "react";

const HomePage = lazy(() => import("./home"));
/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: "/",
    public: true,
    component: HomePage,
  },
];
