import { lazy } from "react";

const FeedPage = lazy(() => import("./feed"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/feed",
    public: true,
    component: FeedPage,
  },
];
