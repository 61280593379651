import React from "react";
import { useIntl } from "react-intl";
import { useStoreActions } from "easy-peasy";
// import { GenericHashLink, HashLink } from "@xzar90/react-router-hash-link";
import { HashLink } from "react-smooth-hash-link";

import {
  IconCompany,
  IconFb,
  IconLinkedin,
  IconPhone,
  IconYoutube,
} from "../../assets/icons/icon";
import { useForm } from "react-hook-form";
import {
  LogoAkaATWhite,
  LogoG2,
  LogoGartnerCustomerChoice,
} from "../../assets/images";
import { Link } from "react-router-dom";
import "./layout.scss";
import { TYPE_PRICING } from "../../constants";

const initData = {
  email: "",
};

const FooterStarted = () => {
  const { formatMessage } = useIntl();
  // intl.fo;
  return (
    <section className="footer-started">
      <h3
        className="divider-20"
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: "layout.footer.msg_01" }),
        }}
      />

      <a href="https://docs.akaat.com/" target="_blank" rel="noreferrer">
        <button className="divider-20">
          {formatMessage({ id: "layout.footer.msg_02" })}
        </button>
      </a>
    </section>
  );
};

const FooterStudio = () => {
  const { formatMessage } = useIntl();
  // intl.fo;
  return (
    <section className="footer-started">
      <h3
        className="divider-20"
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: "layout.footer.msg_35" }),
        }}
      />
      <Link to="/download">
        <button className="divider-20">
          {formatMessage({ id: "layout.footer.msg_36" })}
        </button>
      </Link>
    </section>
  );
};

const FooterProduct = () => {
  const { formatMessage } = useIntl();
  // intl.fo;
  return (
    <section className="footer-started">
      <h3
        className="divider-20"
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: "layout.footer.msg_37" }),
        }}
      />
      <a href="https://docs.akaat.com/" target="_blank" rel="noreferrer">
        <button className="divider-20">
          {formatMessage({ id: "layout.footer.msg_02" })}
        </button>
      </a>
    </section>
  );
};

const FooterContactUs = () => {
  const { formatMessage } = useIntl();
  return (
    <section className="footer-contact-us">
      <div className="footer-contact-us-left">
        <h3 className="divider-20">
          {formatMessage({ id: "layout.footer.msg_03" })}
        </h3>
        <p>{formatMessage({ id: "layout.footer.msg_04" })}</p>
      </div>
      <div className="footer-contact-us-right">
        <a href="https://forum.akaat.com" target="_blank" rel="noreferrer">
          <button>{formatMessage({ id: "layout.footer.msg_05" })}</button>
        </a>
      </div>
    </section>
  );
};

const FooterSubscribe = () => {
  const { formatMessage } = useIntl();

  // action
  const requestReceiveEmail = useStoreActions(
    (action) => action.events.requestReceiveEmail
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });
  const onSubmit = (data) => {
    requestReceiveEmail(data);
    reset(initData);
  };
  return (
    <section className="footer-subscribe">
      <h3 className="divider-20">
        {formatMessage({ id: "layout.footer.msg_06" })}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="footer-wrap-email">
          <label htmlFor="email">
            {formatMessage({ id: "layout.footer.msg_07" })}
          </label>
          <br />
          <input
            type="email"
            id="email"
            name="email"
            {...register("email", {
              required: true,
              pattern: /^\S+@\S+$/i,
              maxLength: 80,
            })}
          />
          <button type="submit" disabled={!isValid}>
            {formatMessage({ id: "layout.footer.msg_08" })}
          </button>
        </div>
      </form>
    </section>
  );
};

const Footer = ({ hiddenStarted, subscribe, contactUs, studio, product }) => {
  const { formatMessage } = useIntl();
  // action
  const setCurrentTab = useStoreActions(
    (action) => action.global.setPricingTab
  );

  return (
    <footer className="layout-footer">
      {/* <div style={{ width: "1440px", margin: "auto" }}>
        
      </div> */}

      <div className="w-1440">
        {!hiddenStarted && <FooterStarted />}
        {studio && <FooterStudio />}
        {product && <FooterProduct />}
        {contactUs && <FooterContactUs />}
        {subscribe && <FooterSubscribe />}
        <section className="footer-logo divider-40">
          <img src={LogoAkaATWhite} alt="fireSpot" height={28} />
        </section>
        <section className="footer-warp divider-40">
          <article className="footer-started-info">
            <h3 className="footer-text-22 divider-16">
              {formatMessage({ id: "layout.footer.msg_09" })}
            </h3>
            <p className="footer-text-14 divider-16">
              <IconCompany style={{ marginRight: "12px" }} />
              {formatMessage({ id: "layout.footer.msg_10" })}
            </p>
            <p className="footer-text-14 divider-20">
              <IconPhone />
              {formatMessage({ id: "layout.footer.msg_11" })}
            </p>
            <div className="footer-social divider-16">
              <h3 className="footer-text-22 divider-16">
                {formatMessage({ id: "layout.footer.msg_12" })}
              </h3>
              <a
                href={`https://www.facebook.com/akaATPlatform`}
                target={"_blank"}
                rel="noreferrer"
              >
                <IconFb />
              </a>
              <a
                href={`https://www.linkedin.com/in/akaat-studio-9a8a68210`}
                target={"_blank"}
                rel="noreferrer"
              >
                <IconLinkedin style={{ margin: "0px 24px" }} />
              </a>

              <a
                href={`https://www.youtube.com/channel/UCct9XYvA_zdc6y2VfbNwnlA`}
                target={"_blank"}
                rel="noreferrer"
              >
                <IconYoutube />
              </a>
            </div>
            <div className="footer-under-social">
              <a
                href={`https://www.gartner.com/reviews/form/login?nextRoute=%2Freviews%2Fsoftware-test-automation%2Fform%2F%3Fmid%3D250%26pid%3D132512%26vid%3D34550`}
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={LogoGartnerCustomerChoice}
                  alt="gartner-customer-choice"
                  style={{ marginRight: "20px" }}
                />
              </a>

              <a
                href={`https://www.g2.com/products/akaat/take_survey`}
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={LogoG2} alt="g2" />
              </a>
            </div>
          </article>
          <article className="footer-link">
            <p className="divider-16">
              {formatMessage({ id: "layout.footer.msg_13" })}
            </p>
            <ul>
              <Link to="/aka-studio">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_14" })}
                </li>
              </Link>
              <Link to="/aka-test-engine">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_15" })}
                </li>
              </Link>
              <Link to="/aka-test-management">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_16" })}
                </li>
              </Link>
              <Link to="/aka-device-farm">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_17" })}
                </li>
              </Link>
              {/* <Link to="#">
                <li className="divider-12">Store</li>
              </Link> */}
            </ul>
          </article>
          <article className="footer-link">
            <p className="divider-16">
              {formatMessage({ id: "layout.footer.msg_18" })}
            </p>
            <ul>
              <Link to="/pricing">
                <li
                  className="divider-12"
                  onClick={() => setCurrentTab(TYPE_PRICING.E2E)}
                >
                  {formatMessage({ id: "layout.footer.msg_19" })}
                </li>
              </Link>
              <Link to="/pricing">
                <li
                  className="divider-12"
                  onClick={() => setCurrentTab(TYPE_PRICING.STANDALONE)}
                >
                  {formatMessage({ id: "layout.footer.msg_20" })}
                </li>
              </Link>
            </ul>
          </article>
          <article className="footer-link">
            <p className="divider-16">
              {formatMessage({ id: "layout.footer.msg_21" })}
            </p>
            <ul>
              <Link to="/download">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_22" })}
                </li>
              </Link>
              <a
                href={`https://docs.akaat.com`}
                target={"_blank"}
                rel="noreferrer"
              >
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_23" })}
                </li>
              </a>
              <a
                href={`https://forum.akaat.com`}
                target={"_blank"}
                rel="noreferrer"
              >
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_24" })}
                </li>
              </a>
              <Link to="/success-stories">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_25" })}
                </li>
              </Link>
              <Link to="/insights">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_26" })}
                </li>
              </Link>
              <Link to="/events">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_27" })}
                </li>
              </Link>
              {/* hask link pricing - faq */}
              <Link to="/pricing#Faqs">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_28" })}
                </li>
              </Link>
              <HashLink to="/pricing#Faqs" />
              {/* hask link pricing - faq */}
            </ul>
          </article>
          <article className="footer-link">
            <p className="divider-16">
              {formatMessage({ id: "layout.footer.msg_29" })}
            </p>
            <ul>
              <Link to="/about">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_30" })}
                </li>
              </Link>
              {/* <Link to="/insights">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_31" })}
                </li>
              </Link> */}
              <Link to="/terms">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_32" })}
                </li>
              </Link>
              <Link to="/contact-us">
                <li className="divider-12">
                  {formatMessage({ id: "layout.footer.msg_33" })}
                </li>
              </Link>
            </ul>
          </article>
        </section>
        <div style={{ border: "1px solid #D0D5DD" }} className="divider-40" />
        <p className="text-center">
          {formatMessage({ id: "layout.footer.msg_34" })}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
