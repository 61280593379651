import { action, thunk } from "easy-peasy";
import { ENDPOINTS_FORUM } from "../../constants/endpoints";
import { aloApi } from "../../core/alo-api";

export const modelHome = {
  /**
   * State
   */
  topics: {
    announcement: [],
    hot: [],
    latest: [],
    usersOfAnnouncement: [],
    usersOfHot: [],
    usersOfLatest: [],
  },

  /**
   * Action: Set loading change password
   */
  setData: action((state, payload) => {
    state.topics.announcement = payload?.topicAnnouncement.topic_list.topics;
    state.topics.hot = payload.topicHot.topic_list.topics;
    state.topics.latest = payload.topicLatest.topic_list.topics;
    state.topics.usersOfAnnouncement = payload.topicAnnouncement.users;
    state.topics.usersOfHot = payload.topicHot.users;
    state.topics.usersOfLatest = payload.topicLatest.users;
  }),

  /**
   * Action: Call api to change password
   */
  // changePassword: thunk(async (action, payload) => {
  //   try {
  //     if (!(payload !== null && typeof payload === "object")) {
  //       throw new Error("Invalid Payload");
  //     }

  //     action.setLoadingChangePass(true);

  //     // const url = ENDPOINTS.CHANGE_PASSWORD;
  //     const url = "http://localhost:3000/";

  //     const res = await aloApi.put(url, payload).then((res) => res.data);

  //     // notification.success({
  //     //   message: i18next.t("akaat:profile.changePassword"),
  //     //   description: i18next.t("akaat:profile.changedPasswordSuccessfully"),
  //     // });

  //     return res;
  //   } catch (err) {
  //     console.error(err);
  //     // handleError(err);
  //   } finally {
  //     action.setLoadingChangePass(false);
  //   }
  // }),

  /**
   * Action: Call api to change password
   */
  fetchPostsForum: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);

      const url1 = ENDPOINTS_FORUM.ANNOUNCEMENT_TOPIC;
      const url2 = ENDPOINTS_FORUM.HOT_TOPIC;
      const url3 = ENDPOINTS_FORUM.LATEST_TOPIC;

      const [topicAnnouncement, topicHot, topicLatest] = await Promise.all([
        aloApi.get(url1).then((res) => res.data),
        aloApi.get(url2).then((res) => res.data),
        aloApi.get(url3).then((res) => res.data),
      ]);
      action.setData({ topicAnnouncement, topicHot, topicLatest });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
