import React from "react";
import {
  AboutImageCenterOurMission,
  AboutImageOurMission,
} from "../../../../assets/images";

import "./about-mission.scss";

const AboutMission = () => {
  return (
    <section className="about-mission">
      <div className="row mission-container">
        <div className="col-12 col-lg-6 test-engine-left">
          <h2>Our mission</h2>
          <h3>“Test smart to grow fast”</h3>
          <p className="divider-64">
            At akaAT, we accompany you on the painless automation testing
            journey. Testing is made easy, and fast, by simplifying and speeding
            up the tedious testing process, as well as empowering the global
            community of users.
          </p>
        </div>
        <div className="col-12 col-lg-6 test-engine-right">
          <img src={AboutImageOurMission} alt=" man and woman discussing" />
        </div>
        <div className="about-mission-center">
          <img
            src={AboutImageCenterOurMission}
            alt=" man and woman discussing"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutMission;
