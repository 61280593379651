import { Navigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Cookies from "js-cookie";

function PrivateRoute({ children }) {
  const { keycloak, initialized } = useKeycloak();

  /**
   * Waiting for keycloak is initialized
   */
  if (!initialized) {
    return <div>loading</div>;
  }

  if (!keycloak?.authenticated) {
    keycloak.login();
  }

  Cookies.set("token", keycloak?.token);

  return children;
}

export default PrivateRoute;
