import { lazy } from "react";

const EmailPage = lazy(() => import("./send-email"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/email",
    public: true,
    component: EmailPage,
  },
];
