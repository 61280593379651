import React from "react";
import { IconArrowForward } from "../../../../assets/icons/icon";
import { DeviceFarmMobileBanner } from "../../../../assets/images";
import "./device-farm-banner.scss";
const MobileDeviceFarmBanner = () => {
  return (
    <section className="m-device-farm-banner">
      <img src={DeviceFarmMobileBanner} alt="" />
      <div className="banner-title">
        <h1 className="divider-24">akaAT Device Farm</h1>
        <p className="divider-32">
          Mobile remote testing is made easy with <br />
          {`{online devices}`} from akaAT, for any demand.
        </p>
        <a href={`https://docs.akaat.com/`} target={"_blank"} rel="noreferrer">
          <button className=" divider-60">
            Get started
            <IconArrowForward style={{ marginLeft: "8px" }} />
          </button>
        </a>
      </div>
    </section>
  );
};

export default MobileDeviceFarmBanner;
