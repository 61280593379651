import React, { useEffect, useState } from "react";
import { useStoreActions } from "easy-peasy";
import {
  IconApartment,
  IconCompany,
  IconPerson,
} from "../../../../assets/icons/icon";
import { ProfileDefaultAvatar } from "../../../../assets/images";

import "./profile-introduction.scss";

const ProfileIntroduction = ({
  setImageUpdate,
  dataUserInfo,
  currentTab,
  setCurrentTab,
}) => {
  const [profileImg, setProfileImg] = useState("");
  const [style, setStyle] = useState({ display: "flex" });

  const sendUserInfo = useStoreActions(
    (action) => action.profile.requestUpdateUserInfo
  );

  const onImageChange = (e) => {
    if (e.target.files) {
      // setImageUpdate(e.target.files?.[0]);
      setProfileImg(URL.createObjectURL(e.target.files[0]));
      sendUserInfo({ id: dataUserInfo.id, img: e.target.files?.[0] });
    }
  };

  return (
    <section className="profile-introduction">
      <div className="introduction-top divider-80">
        <div className="introduction-top-img">
          <img
            // src={
            //   profileImg.length === 0
            //     ? ProfileDefaultAvatar
            //     : dataUserInfo?.attributes?.avatar?.[0]
            // }
            src={`${
              dataUserInfo?.attributes?.avatar?.[0] || ProfileDefaultAvatar
            }`}
            alt=""
            id="img"
            className=""
          />

          <input
            type="file"
            accept="image/*"
            onChange={onImageChange}
            alt="choose a new image"
          />
          <div className="introduction-top-choose-img">
            <p>Choose image</p>
          </div>
        </div>
        <div className="introduction-top-content">
          <div className="introduction-top-content-person divider-60">
            <h3>{dataUserInfo?.firstName + " " + dataUserInfo?.lastName}</h3>
            <p>
              {dataUserInfo?.attributes?.state?.[0]}{" "}
              {dataUserInfo?.attributes?.city?.[0]}{" "}
              {dataUserInfo?.attributes?.country?.[0]}
            </p>
            {/* Ha Noi, Viet Nam */}
          </div>
          <div className="introduction-top-content-work">
            <article className="introduction-top-content-work-item">
              <p>
                <IconPerson
                  style={{
                    verticalAlign: "bottom",
                    marginRight: "4px",
                  }}
                />
                Role
              </p>
              <p>{dataUserInfo?.attributes?.role?.[0]}</p>
            </article>
            <article className="introduction-top-content-work-item">
              <p>
                <IconApartment
                  style={{
                    verticalAlign: "bottom",
                    marginRight: "4px",
                  }}
                />
                Company
              </p>
              <p>{dataUserInfo?.attributes?.company?.[0]}</p>
            </article>
          </div>
        </div>
      </div>
      <div className="introduction-tab">
        <article
          onClick={() => {
            setCurrentTab("myProfile");
          }}
          className={`tab-item ${
            currentTab === "myProfile" ? "tab-active" : ""
          }`}
          tabIndex="2"
        >
          My profile
        </article>
        <article
          onClick={() => {
            setCurrentTab("changePassword");
          }}
          className={`tab-item ${
            currentTab === "changePassword" ? "tab-active" : ""
          }`}
          tabIndex="3"
        >
          Change password
        </article>
      </div>
    </section>
  );
};

export default ProfileIntroduction;
