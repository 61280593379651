import React from "react";
import ModalA from "./modalA";
const YoutubePopover = ({ showModal, setShowModal, embedCode }) => {
  const code = embedCode?.replace("https://www.youtube.com/watch?v=", "");
  return (
    <div>
      <ModalA
        show={showModal}
        setShow={setShowModal}
        // hideCloseButton
      >
        {/* <div className="modalX__header">
          <h2>Modal header</h2>
        </div> */}
        <div className="modalX__body">
          {showModal && (
            <iframe
              width="950"
              height="540"
              src={`https://www.youtube.com/embed/${code}?rel=0&autoplay=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen="allowfullscreen"
            />
          )}
        </div>
        {/* <div className="modalX__footer">
          <button onClick={() => setShowModal(false)}>Close</button>
        </div> */}
      </ModalA>
    </div>
  );
};

export default YoutubePopover;
