import {
  IconFeatureProduct1,
  IconFeatureProduct2,
  IconFeatureProduct3,
  IconFeatureProduct4,
} from "../../../../assets/icons/icon";
import { TestEngineImageLogo } from "../../../../assets/images";
import "./test-engine-feature.scss";
const AkaStudioFeature = () => {
  return (
    <section className="test-engine-feature">
      <div className="w-1440">
        <h2 className="divider-52">Feature</h2>
        <div className="feature-container">
          <div className="row feature-center divider-70">
            <article className="col-4 feature-item">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct1 />
              </div>
              <h3 className="divider-8">JOB SCHEDULE</h3>
              <p>
                Schedule test jobs to run automatically according to the plan,
                saving time, cost and effort.
              </p>
            </article>
            <article className="col-4 feature-item feature-item-right">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct2 className="feature-icon-right" />
              </div>
              <h3 className="divider-8">CI/CD</h3>
              <p>
                Easily connect with any devices or systems to control automation
                test jobs.
              </p>
            </article>
          </div>
          <div className="row feature-center">
            <article className="col-4 feature-item">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct3 />
              </div>
              <h3 className="divider-8">REALTIME NOTIFICATION</h3>
              <p>
                Push notifications to users in real time and through out the
                job, from starting to reporting phases. Support multiple popular
                communication systems: Mail, Slack, Microsoft Teams, Zalo.
              </p>
            </article>
            <article className="col-4 feature-item feature-item-right">
              <div className="benefit-icon divider-28">
                <IconFeatureProduct4 className="feature-icon-right" />
              </div>
              <h3 className="divider-8">CUSTOMIZED REPORT</h3>
              <p>
                Create customizable reports based on requirements from the
                system data.
              </p>
            </article>
          </div>
          <div className="feature-img">
            <img src={TestEngineImageLogo} alt="logo akaAt studio" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AkaStudioFeature;
