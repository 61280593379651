import React from "react";
import Slider from "react-slick/lib/slider";
import {
  AboutImageLogoAIA,
  AboutImageLogoBaoViet,
  AboutImageLogoFwd,
  AboutImageLogoTPBank,
  AboutImageLogoVV,
} from "../../../../assets/images";

import "./about-client.scss";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   }
    // }
  ],
};

const AboutClient = () => {
  return (
    <section className="about-client">
      <div className="client-title">
        <h2>Our clients</h2>
        <p>Get to know about our happy customers</p>
      </div>
      {/* <div className="client-image-container">
        <div className="row">
          <article className="client-logo">
            <img src={AboutImageLogoFwd} alt="logo fwd company" />
          </article>

          <article className="client-logo">
            <img src={AboutImageLogoVV} alt="logo video visit company" />
          </article>

          <article className="client-logo">
            <img src={AboutImageLogoAIA} alt="logo aia company" />
          </article>

          <article className="client-logo">
            <img src={AboutImageLogoBaoViet} alt="logo baoviet company" />
          </article>

          <article className="client-logo">
            <img src={AboutImageLogoTPBank} alt="logo tpbank company" />
          </article>
        </div>
      </div> */}
      <div className="client-image-container">
        <Slider {...settings}>
          <article className="client-logo divider-20">
            <img src={AboutImageLogoFwd} alt="logo fwd company" />
          </article>

          <article className="client-logo divider-20">
            <img src={AboutImageLogoVV} alt="logo video visit company" />
          </article>

          <article className="client-logo divider-20">
            <img src={AboutImageLogoAIA} alt="logo aia company" />
          </article>

          <article className="client-logo divider-20">
            <img src={AboutImageLogoBaoViet} alt="logo baoviet company" />
          </article>

          <article className="client-logo divider-20">
            <img src={AboutImageLogoTPBank} alt="logo tpbank company" />
          </article>
        </Slider>
      </div>
    </section>
  );
};

export default AboutClient;
