import { Link } from "react-router-dom";
import {
  IconCheckCirclePricing,
  IconLogoStudio,
  IconLogoTestEngine,
  IconLogoTestManagement,
} from "../../../../assets/icons/icon";

const ProductBenefit = ({ content }) => {
  return (
    <div className="product-benefit divider-16">
      <IconCheckCirclePricing />
      <p
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></p>
    </div>
  );
};

const PricingTestEngine = () => {
  return (
    <div className="grid-box">
      <div className="cell cell-border-top">
        <div className="product-header">
          <IconLogoStudio className="divider-12" />
          <h2 className="divider-12">Studio</h2>
          <p className="divider-12">
            <span>$200</span> / License / Month
          </p>
          <p className="product-tag">20% saving for billing annually</p>
        </div>
      </div>
      <div className="cell cell-border-top">
        {" "}
        <div className="product-header">
          <IconLogoTestEngine className="divider-12" />
          <h2 className="divider-12">Test Engine</h2>
          <p className="divider-12">
            <span>$150</span> / License / Month
          </p>
          <p className="divider-12">
            For <span className="color-quick-silver">3 Active Agents</span>
          </p>
          <p className="product-tag">20% saving for billing annually</p>
        </div>
      </div>
      <div className="cell cell-border-top">
        {" "}
        <div className="product-header">
          <IconLogoTestManagement className="divider-12" />
          <h2 className="divider-12">Management</h2>

          <p className="divider-12">
            <span>$750</span> / License / Month
          </p>
          <p className="divider-12">
            For <span className="color-quick-silver">5 Active Users</span>
          </p>
          <p className="product-tag">20% saving for billing annually</p>
        </div>
      </div>
      <div className="cell ">
        <div className="product-content">
          <ProductBenefit
            content={"Multi-platform support: Web, Mobile, API, Database"}
          />
          <ProductBenefit content={"Smart Recorder"} />
          <ProductBenefit content={"End-to-End scenarios"} />
          <ProductBenefit content={"Business Keyword"} />
          <ProductBenefit content={"Custom Code Keyword"} />
          <ProductBenefit content={"Free Plugins from Store"} />
          <ProductBenefit content={"Online Documents"} />
          <ProductBenefit content={"Teamwork Collaboration with GIT"} />
          <ProductBenefit content={"Debugging Step-by-Step"} />
          <ProductBenefit content={"Advance plugins from Store"} />
          <ProductBenefit content={"1 Active Test Engine Agent"} />

          <div className="product-benefit divider-52">
            <IconCheckCirclePricing />
            Helpdesk support.
          </div>
        </div>
      </div>
      <div className="cell">
        <div className="product-content">
          <ProductBenefit content={"Automation Job Schedule"} />
          <ProductBenefit content={"Unlimited Runtime-Engine"} />
          <ProductBenefit content={"Parallel Execution"} />
          <ProductBenefit content={"CI/CD & DevOps Ready"} />
          <ProductBenefit content={"Real-time Notification"} />
          <ProductBenefit content={"Dynamic Automation Report"} />
          <ProductBenefit content={"5GB Storage Volume"} />
          <ProductBenefit content={"10GB Transaction Data"} />
          <div className="product-benefit divider-52">
            <IconCheckCirclePricing />
            Helpdesk support
          </div>
        </div>
      </div>
      <div className="cell">
        <div className="product-content">
          <ProductBenefit content={"Flexible Test Process"} />
          <ProductBenefit content={"Test Planning"} />
          <ProductBenefit content={"Defect Tracking"} />
          <ProductBenefit content={"Traceability"} />
          <ProductBenefit content={"Advance Testing Report"} />
          <ProductBenefit content={"Single sign-On"} />
          <ProductBenefit content={"5GB Storage Volume"} />
          <ProductBenefit content={"10GB Transaction Data"} />
          <div className="product-benefit divider-52">
            <IconCheckCirclePricing />
            Helpdesk support
          </div>
        </div>
      </div>
      <div className="cell cell-border-bottom">
        <div className="wrap-btn divider-20">
          <Link to="/contact-us">
            <button className="product-btn">Contact Sales</button>
          </Link>
        </div>
        <div className="wrap-btn">
          <Link to="/download">
            <button className="product-btn">Download Community Version</button>
          </Link>
        </div>{" "}
      </div>
      <div className="cell cell-border-bottom">
        {" "}
        <div className="wrap-btn">
          <Link to="/contact-us">
            <button className="product-btn">Contact Sales</button>
          </Link>
        </div>
      </div>
      <div className="cell cell-border-bottom">
        {" "}
        <div className="wrap-btn">
          <Link to="/contact-us">
            <button className="product-btn">Contact Sales</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingTestEngine;
