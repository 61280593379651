import React from "react";

const TermTab = ({ currentTab, setCurrentTab }) => {
  return (
    <aside className="term-right">
      <h3>Term of use</h3>
      <a href="#rules1" onClick={() => setCurrentTab("rules1")}>
        <p className={`${currentTab === "rules1" ? "tab-active" : ""}`}>
          Definition
        </p>
      </a>
      <a href="#rules2" onClick={() => setCurrentTab("rules2")}>
        <p className={`${currentTab === "rules2" ? "tab-active" : ""}`}>
          Account and security
        </p>
      </a>
      <a href="#rules3" onClick={() => setCurrentTab("rules3")}>
        <p className={`${currentTab === "rules3" ? "tab-active" : ""}`}>
          Services
        </p>
      </a>
      <a href="#rules4" onClick={() => setCurrentTab("rules4")}>
        <p className={`${currentTab === "rules4" ? "tab-active" : ""}`}>
          Use of the site and the service
        </p>
      </a>
      <a href="#rules5" onClick={() => setCurrentTab("rules5")}>
        <p className={`${currentTab === "rules5" ? "tab-active" : ""}`}>
          Charges and payment
        </p>
      </a>
      <a href="#rules6" onClick={() => setCurrentTab("rules6")}>
        <p className={`${currentTab === "rules6" ? "tab-active" : ""}`}>
          Data protection
        </p>
      </a>
      <a href="#rules7" onClick={() => setCurrentTab("rules7")}>
        <p className={`${currentTab === "rules7" ? "tab-active" : ""}`}>
          Proprietary rights
        </p>
      </a>
      <a href="#rules8" onClick={() => setCurrentTab("rules8")}>
        <p className={`${currentTab === "rules8" ? "tab-active" : ""}`}>
          Termination
        </p>
      </a>
      <a href="#rules9" onClick={() => setCurrentTab("rules9")}>
        <p className={`${currentTab === "rules9" ? "tab-active" : ""}`}>
          Disclaimers
        </p>
      </a>

      <a href="#rules10" onClick={() => setCurrentTab("rules10")}>
        <p className={`${currentTab === "rules10" ? "tab-active" : ""}`}>
          Indemnity and limitations of liability
        </p>
      </a>
      <a href="#rules11" onClick={() => setCurrentTab("rules11")}>
        <p className={`${currentTab === "rules11" ? "tab-active" : ""}`}>
          Force majeure{" "}
        </p>
      </a>
      <a href="#rules12" onClick={() => setCurrentTab("rules12")}>
        <p className={`${currentTab === "rules12" ? "tab-active" : ""}`}>
          Miscellaneous
        </p>
      </a>
      <h3>Privacy Policy</h3>
      <a href="#rules13" onClick={() => setCurrentTab("rules13")}>
        <p className={`${currentTab === "rules13" ? "tab-active" : ""}`}>
          What kinds of information do we collect?
        </p>
      </a>
      <a href="#rules14" onClick={() => setCurrentTab("rules14")}>
        <p className={`${currentTab === "rules14" ? "tab-active" : ""}`}>
          How do we use the information we collect?
        </p>
      </a>
      <a href="#rules15" onClick={() => setCurrentTab("rules15")}>
        <p className={`${currentTab === "rules15" ? "tab-active" : ""}`}>
          Who do we share personal data with?
        </p>
      </a>
      <a href="#rules16" onClick={() => setCurrentTab("rules16")}>
        <p className={`${currentTab === "rules16" ? "tab-active" : ""}`}>
          How do we protect your data?
        </p>
      </a>
      <a href="#rules17" onClick={() => setCurrentTab("rules17")}>
        <p className={`${currentTab === "rules17" ? "tab-active" : ""}`}>
          How long do we retain the personal data we collects?
        </p>
      </a>
      <a href="#rules18" onClick={() => setCurrentTab("rules18")}>
        <p className={`${currentTab === "rules18" ? "tab-active" : ""}`}>
          Children’s privacy
        </p>
      </a>
      <a href="#rules19" onClick={() => setCurrentTab("rules19")}>
        <p className={`${currentTab === "rules19" ? "tab-active" : ""}`}>
          Links to other websites
        </p>
      </a>

      <a href="#rules20" onClick={() => setCurrentTab("rules20")}>
        <p className={`${currentTab === "rules20" ? "tab-active" : ""}`}>
          Contact us
        </p>
      </a>

      <h3>Reference</h3>
      <a href="#rules21" onClick={() => setCurrentTab("rules21")}>
        <p className={`${currentTab === "rules21" ? "tab-active" : ""}`}>
          Reference
        </p>
      </a>
    </aside>
  );
};

export default TermTab;
