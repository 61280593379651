const AdsBanner = () => {
  return (
    <div className="ads-banner">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet dolore
        esse fugiat inventore mollitia nam officiis perferendis veniam.
        Asperiores blanditiis ea enim esse explicabo nam necessitatibus
        obcaecati officia sunt ut. Lorem ipsum dolor sit amet, consectetur
        adipisicing elit. Amet dolore esse fugiat inventore mollitia nam
        officiis perferendis veniam. Asperiores blanditiis ea enim esse
        explicabo nam necessitatibus obcaecati officia sunt ut. Lorem ipsum
        dolor sit amet, consectetur adipisicing elit. Amet dolore esse fugiat
        inventore mollitia nam officiis perferendis veniam. Asperiores
        blanditiis ea enim esse explicabo nam necessitatibus obcaecati officia
        sunt ut. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet
        dolore esse fugiat inventore mollitia nam officiis perferendis veniam.
        Asperiores blanditiis ea enim esse explicabo nam necessitatibus
        obcaecati officia sunt ut. Lorem ipsum dolor sit amet, consectetur
        adipisicing elit. Amet dolore esse fugiat inventore mollitia nam
        officiis perferendis veniam. Asperiores blanditiis ea enim esse
        explicabo nam necessitatibus obcaecati officia sunt ut. Lorem ipsum
        dolor sit amet, consectetur adipisicing elit. Amet dolore esse fugiat
        inventore mollitia nam officiis perferendis veniam. Asperiores
        blanditiis ea enim esse explicabo nam necessitatibus obcaecati officia
        sunt ut.
      </p>
    </div>
  );
};
export default AdsBanner;
