import React from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { useViewportSize } from "../../common/hooks";
import Layout from "../../common/components/layout";

import "./about.scss";
import AboutBanner from "./components/about-banner/about-banner";
import AboutNumber from "./components/about-number/about-number";
import AboutMission from "./components/about-mission/about-mission";
import AboutWhy from "./components/about-why/about-why";
import AboutRecognition from "./components/about-recognition/about-recognition";
import AboutBoss from "./components/about-boss/about-boss";
import AboutClient from "./components/about-client/about-client";
import AboutContact from "./components/about-contact/about-contact";
import MobileAboutBanner from "../../mobile-pages/about/about-banner/about-banner";

const AboutPage = () => {
  const { formatMessage } = useIntl();
  const [viewportWidth] = useViewportSize();

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "AboutPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout hiddenStarted>
      <div className="about-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.about_us.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.about_us.meta_description" })}
          />
        </Helmet>
        {viewportWidth > 991 ? <AboutBanner /> : <MobileAboutBanner />}
        <AboutNumber />
        <AboutMission />
        <AboutWhy />
        {/* <AboutBoss /> */}
        <AboutRecognition />
        <AboutClient />
        <AboutContact />
      </div>
    </Layout>
  );
};

export default AboutPage;
