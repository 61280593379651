import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import moment from "moment/moment";

import { useViewportSize } from "../../common/hooks";
import Layout from "../../common/components/layout";

import "./release-notes.scss";
import { useStoreActions, useStoreState } from "easy-peasy";
import { getStrapiMedia } from "../../common/utils";

const gfm = require("remark-gfm");

const ReleaseNotesPage = () => {
  const { formatMessage } = useIntl();
  const [viewportWidth] = useViewportSize();

  const getReleaseNotes = useStoreActions(
    (action) => action.releaseNotes.fetchReleaseNotes
  );
  const releaseNotes = useStoreState(
    (state) => state?.releaseNotes?.releaseNotes
  );

  useEffect(() => {
    getReleaseNotes();
  }, [getReleaseNotes]);
  return (
    <Layout hiddenStarted>
      <div className="release-notes-page">
        <h1>akaAT Release Notes</h1>
        <div className="release-notes-content">
          {releaseNotes?.data?.map(
            (item) =>
              item?.attributes?.releaseNote && (
                <div className="notes-version divider-52" key={item.id}>
                  <h3>
                  {item?.attributes?.type} - {item?.attributes?.platform} - {item?.attributes?.version}{" "}
                    <span className="font-BROmegaVN">(</span>
                    {item?.attributes?.versionType}
                    <span className="font-BROmegaVN">)</span>
                  </h3>
                  <h5>
                    {moment(item?.attributes?.createdAt).format("YYYY-MM-DD")}
                  </h5>
                  <div className="notes-important">
                    <ReactMarkdown
                      children={item?.attributes?.releaseNote}
                      remarkPlugins={[gfm]}
                      linkTarget="_blank"
                      components={{
                        img: ({ node, ...props }) => (
                          <img
                            src={getStrapiMedia(props.src)}
                            alt={props.alt}
                          />
                        ),
                        // h2: HeadingRenderer,
                        // h3: HeadingRenderer,
                      }}
                    />
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ReleaseNotesPage;
