/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { IconSpeaker } from "../../assets/icons/icon";
import { Context } from "./wrapperIntl";
import "./layout.scss";
import { useStoreActions, useStoreState } from "easy-peasy";

// import styles from "../styles/main-header.module.css";

const Announcement = ({ categories }) => {
  const getAnnouncement = useStoreActions(
    (action) => action.global.fetchAnnouncement
  );
  const announcement = useStoreState((state) => state.global.announcement);
  useEffect(() => {
    getAnnouncement();
  }, [getAnnouncement]);

  const context = useContext(Context);
  const { formatMessage } = useIntl();
  return (
    <>
      {announcement?.attributes?.status && (
        <div className="layout-announcement">
          <IconSpeaker style={{ marginRight: "16px" }} />
          {announcement?.attributes?.run_words ? (
            <marquee>{announcement?.attributes?.content}</marquee>
          ) : (
            <span>{announcement?.attributes?.content}</span>
          )}
        </div>
      )}
    </>
  );
};

export default Announcement;
