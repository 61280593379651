import React from "react";
import { Link } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
import { TYPE_PRICING } from "../../../../constants";

import {
  IconCheckCircle,
  IconCommunity,
  IconEnterprises,
} from "../../../../assets/icons/icon";
import "./home-product.scss";

const HomeProduct = () => {
  // action
  const setCurrentTab = useStoreActions(
    (action) => action.global.setPricingTab
  );

  return (
    <section className="home-product">
      <h3 className="product-title divider-12">Automation Test for Everyone</h3>
      <p className="divider-68">
        Individuals and open source communities will always be able to use for
        free.
      </p>
      <div className="wrap-product">
        <div className="product">
          <div className="product-animations">
            <div className="product-header">
              <IconCommunity />
              <h3 className="divider-12">COMMUNITY</h3>
              <p
                className="product-tag divider-12"
                style={{ backgroundColor: "#D1FADF", color: "#054F31" }}
              >
                FREE
              </p>
              <p className="divider-32">akaAT for PERSONAL</p>
            </div>
            <div className="product-content">
              <div className="product-benefit divider-16">
                <IconCheckCircle style={{ color: "#32D583" }} />
                <p>FREE to use akaAT Studio & Management</p>
              </div>
              <div className="product-benefit divider-16">
                <IconCheckCircle style={{ color: "#32D583" }} />
                <p>
                  Ready-made SaaS platforms and tools, supporting
                  limited-testing experience users
                </p>
              </div>
              <div className="product-benefit divider-16">
                <IconCheckCircle style={{ color: "#32D583" }} />
                <p>FREE online training courses from A-Z</p>
              </div>
              <div className="product-benefit divider-16">
                <IconCheckCircle style={{ color: "#32D583" }} />
                <p>Strong Community for FAQs and knowledge sharing</p>
              </div>
              <div className="product-benefit divider-16">
                <IconCheckCircle style={{ color: "#32D583" }} />
                Global support for FREE
              </div>
              <div className="product-benefit divider-43">
                <IconCheckCircle style={{ color: "#32D583" }} />
                <p>High efficient: reduce 99% human mistakes</p>
              </div>
              <div className="wrap-btn">
                <Link to="/pricing">
                  <button
                    className="product-btn"
                    onClick={() => setCurrentTab(TYPE_PRICING.E2E)}
                  >
                    Get started
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="product product-enterprise">
          <div className="product-animations">
            <div className="product-header">
              <IconEnterprises />
              <h3 className="divider-12">ENTERPRISE</h3>
              <p
                className="product-tag divider-12"
                style={{ backgroundColor: "#FFE0E1", color: "#EA2127" }}
              >
                BEST VALUE
              </p>
              <p className="divider-32">akaAT for TEAM</p>
            </div>
            <div className="product-content">
              <div className="product-benefit divider-16">
                {/* <IconCheckCircle
                  style={{ verticalAlign: "bottom" }}
                /> */}
                <IconCheckCircle style={{ color: "#EA2127" }} />
                <p>Shorten time-to-market: Save 30% Testing Cost</p>
              </div>
              <div className="product-benefit divider-16">
                <IconCheckCircle style={{ color: "#EA2127" }} />
                <p>Reduce 15% Testing Cost</p>
              </div>
              <div className="product-benefit divider-16">
                <IconCheckCircle style={{ color: "#EA2127" }} />
                <p>Increase 25% testing velocity</p>
              </div>
              <div className="product-benefit divider-16">
                <IconCheckCircle style={{ color: "#EA2127" }} />
                <p>Easy to manage mixed projects (automation & manual test)</p>
              </div>
              <div className="product-benefit divider-43">
                <IconCheckCircle style={{ color: "#EA2127" }} />
                <p>Seamlessly integrate with your system</p>
              </div>
              <div className="wrap-btn">
                <Link to="/pricing">
                  <button
                    className="product-btn"
                    onClick={() => setCurrentTab(TYPE_PRICING.MANAGEMENT)}
                  >
                    Get started
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeProduct;
