import React from "react";
import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category) => {
  const eventTracker = (
    action = "action download",
    label = "label download"
  ) => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
