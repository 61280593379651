import { Link } from "react-router-dom";

import { useViewportSize } from "../../../../common/hooks";
import {
  IconBookBookMark,
  IconGlobal,
  IconGroupPeople,
  IconLiveChat,
  IconUserHeadset,
  IconVisibilityOn,
} from "../../../../assets/icons/icon";
import {
  AdsImageCommunity1,
  AdsImageCommunity2,
  AdsImageCommunity3,
  AdsImageMobileCommunity2,
  AdsImageMobileCommunity3,
} from "../../../../assets/images";

import "./ads-community.scss";

const AdsCommunity = () => {
  const [viewportWidth] = useViewportSize();
  return (
    <section className="ads-community">
      <div className="community-above divider-35">
        <div className="community-above-wrap-image">
          <div className="community-above-image">
            <img src={AdsImageCommunity1} alt="Images" />
          </div>
        </div>

        <div className="community-above-content">
          <IconGroupPeople />
          <p className="font-BROmegaVN">Cộng đồng hỗ trợ mạnh mẽ</p>
          <span className="font-BROmegaVN">
            +1300 kiểm thử viên, quản lý dự án, trưởng nhóm kiểm thử cùng tham
            gia học tập, trao đổi, tìm hiểu thực chiến
          </span>
          {/* <Link to="/download">
            <button className="btn  font-BROmegaVN">
              <IconGlobal className="me-1" />
              Tham gia cộng đồng
            </button>
          </Link> */}
          <a
            href={`https://www.facebook.com/groups/akaatsupport`}
            target={"_blank"}
            rel="noreferrer"
          >
            <button className="btn font-BROmegaVN">
              <IconGlobal className="me-2" />
              Tham gia cộng đồng
            </button>
          </a>
        </div>
      </div>

      <div className="community-below">
        <div className="community-below-description">
          <div className="community-below-image">
            {viewportWidth > 991 ? (
              <img src={AdsImageCommunity2} alt="Images" />
            ) : (
              <img src={AdsImageMobileCommunity2} alt="Images" />
            )}
          </div>
          <div className="community-below-content">
            <IconUserHeadset />
            <p className="font-BROmegaVN">Đội ngũ hỗ trợ 24/7</p>
            <span className="font-BROmegaVN">
              Đội ngũ hỗ trợ nhiều năm kinh nghiệm, giải quyết vấn đề sử dụng và
              triển khai sản phẩm 24/7
            </span>
            <a
              href={`https://forum.akaat.com`}
              target={"_blank"}
              rel="noreferrer"
            >
              <button className="btn font-BROmegaVN">
                <IconLiveChat className="me-3" />
                Hỏi ngay
              </button>
            </a>
          </div>
        </div>

        <div className="community-below-description">
          <div className="community-below-image">
            {viewportWidth > 991 ? (
              <img src={AdsImageCommunity3} alt="Images" />
            ) : (
              <img src={AdsImageMobileCommunity3} alt="Images" />
            )}
          </div>
          <div className="community-below-content">
            <IconBookBookMark className="me-2" />
            <p className="font-BROmegaVN">Hướng dẫn sử dụng chi tiết</p>
            <span className="font-BROmegaVN">
              akaAT cung cấp bộ tài liệu hướng dẫn sử dụng công cụ kiểm thử trực
              quan, dễ hiểu
            </span>
            <a
              href={`https://docs.akaat.com`}
              target={"_blank"}
              rel="noreferrer"
            >
              <button className="btn font-BROmegaVN">
                <IconVisibilityOn className="me-2" />
                Xem ngay
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AdsCommunity;
