import React from "react";
import Slider from "react-slick/lib/slider";
import "./device-farm-feature.scss";
import {
  IconFeatureProduct7,
  IconFeatureProduct8,
  IconFeatureProduct9,
} from "../../../../assets/icons/icon";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileDeviceFarmFeature = () => {
  return (
    <section className="m-device-farm-feature">
      <div className="recognition-text divider-40">
        <h2 className="divider-52">Core features</h2>
      </div>
      <div className="recognition-slides">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct7 />
            </div>
            <h3 className="divider-8">Multiple Access</h3>
            <p>
              Manage your inventory and execute your operations on multiple
              devices concurrently.
            </p>
          </article>
          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct8 />
            </div>
            <h3 className="divider-8">Distributed Farms</h3>
            <p>
              Build your own farm for the offshore team to access across
              locations and develop without travelling.
            </p>
          </article>

          <article className="recognition-row divider-20">
            <div className="divider-28">
              <IconFeatureProduct9 />
            </div>
            <h3 className="divider-8">Booking & Reservations</h3>
            <p>
              Keep track on device usage rate and available time slot to
              organize tests effectively.
            </p>
          </article>
        </Slider>
      </div>
    </section>
  );
};

export default MobileDeviceFarmFeature;
