import { Link } from "react-router-dom";
import {
  IconLogoDeviceFarmRed,
  IconLogoEngineRed,
  IconLogoManagementRed,
  IconVisibilityOn,
} from "../../../../assets/icons/icon";
import { AdsImageBgEcosystem } from "../../../../assets/images";
import "./ads-ecosystem.scss";

const AdsEcosystem = () => {
  return (
    <section className="ads-ecosystem">
      <div className="ads-container">
        <div className="ads-ecosystem-img">
          <img src={AdsImageBgEcosystem} alt="Bg Ecosystem" />
        </div>
        <div className="ads-ecosystem-left">
          <h3 className="font-BROmegaVN">Sản phẩm sắp ra mắt</h3>
          <p className="font-BROmegaVN divider-32">
            Cùng khám những sản phẩm vượt trội sắp ra mắt trong hệ sinh thái
            akaAT
          </p>
          <Link to="/">
            <button className="btn me-4 font-BROmegaVN">
              <IconVisibilityOn className="me-2" />
              Khám phá hệ sinh thái
            </button>
          </Link>
        </div>
        <div className="ads-ecosystem-right">
          <div className="ecosystem-item-bg-1">
            <Link to="/aka-test-management">
              <div className="ecosystem-item-management">
                <IconLogoManagementRed className="me-1" />
                <h3 className="font-BROmegaVN">Test Management</h3>
                <p className="font-BROmegaVN">
                  Nền tảng quản lý tiên tiến với các tính năng làm việc theo
                  nhóm nâng cao chất lượng kiểm thử của dự án.
                </p>
              </div>
            </Link>
          </div>
          <div className="ecosystem-item">
            <div className="ecosystem-item-bg-2">
              <Link to="/aka-device-farm">
                <div className="ecosystem-item-farm">
                  <IconLogoDeviceFarmRed className="me-1" />
                  <h3 className="font-BROmegaVN">Device Farm</h3>
                  <p className="font-BROmegaVN">
                    Cung cấp môi trường kiểm thử trực tuyến với các loại thiết
                    bị di động
                  </p>
                </div>
              </Link>
            </div>
            <div className="ecosystem-item-bg-2">
              <Link to="/aka-test-engine">
                <div className="ecosystem-item-engine">
                  <IconLogoEngineRed className="me-1" />
                  <h3 className="font-BROmegaVN">Test Engine</h3>
                  <p className="font-BROmegaVN">
                    Phục vụ người dùng cấu hình, lên lịch và kích hoạt tất cả
                    các tác vụ, giúp sắp xếp toàn bộ quy trình thử nghiệm hợp
                    lý.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AdsEcosystem;
