import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  IconCompany,
  IconDownload,
  IconFb,
  IconLinkedin,
  IconPhone,
  IconYoutube,
} from "../../../../assets/icons/icon";
import {
  LogoAkaATWhite,
  LogoG2,
  LogoGartnerCustomerChoice,
} from "../../../../assets/images";
import "./ads-footer.scss";

const FooterStarted = () => {
  const { formatMessage } = useIntl();

  const appInsights = useAppInsightsContext();
  const trackingDownloadStudio = useTrackEvent(
    appInsights,
    "trackingDownloadStudioFromPageAds",
    "trackingDownloadStudioFromPageAds"
  );

  // intl.fo;
  return (
    <section className="footer-started">
      <div className="footer-started-content">
        <h3 className="font-BROmegaVN divider-20">Trải nghiệm ngay hôm nay</h3>
        <p className="font-BROmegaVN">
          Cùng akaAT đơn giản hoá công việc của bạn
        </p>
      </div>

      <Link to="/download" onClick={trackingDownloadStudio}>
        <button className="divider-20 font-BROmegaVN">
          <IconDownload
            style={{
              verticalAlign: "bottom",
              marginRight: "4px",
            }}
          />
          Tải về miễn phí
        </button>
      </Link>
    </section>
  );
};

const AdsFooter = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <div className="ads-footer-cta">
        <FooterStarted />
      </div>
      <footer className="ads-footer">
        <div className="w-1440">
          <section className="footer-logo divider-40">
            <img src={LogoAkaATWhite} alt="fireSpot" height={28} />
          </section>
          <section className="footer-warp divider-40">
            <article className="footer-started-info">
              <h3 className="font-BROmegaVN footer-text-22 divider-16">
                Trụ sở chính
              </h3>
              <p className="font-BROmegaVN footer-text-14 divider-16">
                <IconCompany style={{ marginRight: "12px" }} />
                Toà FPT Tower, số 10 Phạm Văn Bạch, Cầu Giấy, Hà Nội, Việt Nam
              </p>
              <p className="font-BROmegaVN footer-text-14 divider-20">
                <IconPhone />
                {formatMessage({ id: "layout.footer.msg_11" })}
              </p>
            </article>
            <article className="footer-link">
              <p className="font-BROmegaVN divider-16">Sản phẩm</p>
              <ul>
                <Link to="/aka-studio">
                  <li className="font-BROmegaVN divider-12">
                    {formatMessage({ id: "layout.footer.msg_14" })}
                  </li>
                </Link>
                <Link to="/aka-test-engine">
                  <li className="font-BROmegaVN divider-12">
                    {formatMessage({ id: "layout.footer.msg_15" })}
                  </li>
                </Link>
                <Link to="/aka-test-management">
                  <li className="font-BROmegaVN divider-12">
                    {formatMessage({ id: "layout.footer.msg_16" })}
                  </li>
                </Link>
                <Link to="/aka-device-farm">
                  <li className="font-BROmegaVN divider-12">
                    {formatMessage({ id: "layout.footer.msg_17" })}
                  </li>
                </Link>
                {/* <Link to="#">
          <li className="font-BROmegaVN divider-12">Store</li>
        </Link> */}
              </ul>
            </article>
            <article className="footer-link">
              <p className="font-BROmegaVN divider-16">Tài nguyên</p>
              <ul>
                <a
                  href={`https://docs.akaat.com`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <li className="font-BROmegaVN divider-12">
                    Hướng dẫn sử dụng
                  </li>
                </a>
                <a
                  href={`https://www.facebook.com/groups/akaatsupport`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <li className="font-BROmegaVN divider-12">Cộng đồng</li>
                </a>
                <Link to="/insights">
                  <li className="font-BROmegaVN divider-12">Blogs</li>
                </Link>
                {/* <Link to="/events">
                <li className="font-BROmegaVN divider-12">Sự kiện</li>
              </Link> */}
              </ul>
            </article>
            <article className="footer-link">
              <p className="font-BROmegaVN divider-16">Về chúng tôi</p>
              <ul>
                <Link to="/about">
                  <li className="font-BROmegaVN divider-12">Sứ mệnh</li>
                </Link>
                <Link to="/terms">
                  <li className="font-BROmegaVN divider-12">
                    Điều khoản sử dụng
                  </li>
                </Link>
                <Link to="/contact-us">
                  <li className="font-BROmegaVN divider-12">Liên hệ</li>
                </Link>
              </ul>
            </article>
            <article className="footer-link">
              <div className="footer-social divider-16">
                <p className="font-BROmegaVN footer-text-22 divider-16">
                  Mạng xã hội
                </p>
                <a
                  href={`https://www.facebook.com/search/top?q=akaat`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <IconFb />
                </a>
                <a
                  href={`https://www.youtube.com/@akaat1531`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <IconYoutube style={{ margin: "0px 24px" }} />
                </a>
                <a
                  href={`https://www.linkedin.com/in/akaat-studio-9a8a68210/`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <IconLinkedin />
                </a>
              </div>
              <div className="footer-under-social">
                <a
                  href={`https://www.gartner.com/reviews/form/login?nextRoute=%2Freviews%2Fsoftware-test-automation%2Fform%2F%3Fmid%3D250%26pid%3D132512%26vid%3D34550`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    src={LogoGartnerCustomerChoice}
                    alt="gartner-customer-choice"
                    style={{ marginRight: "20px" }}
                  />
                </a>

                <a
                  href={`https://www.g2.com/products/akaat/take_survey`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={LogoG2} alt="g2" />
                </a>
              </div>
            </article>
          </section>
          <div style={{ border: "1px solid #D0D5DD" }} className="divider-40" />
          <p className="text-center">
            {formatMessage({ id: "layout.footer.msg_34" })}
          </p>
        </div>
      </footer>
    </>
  );
};
export default AdsFooter;
