import { useRef, useState } from "react";
import Slider from "react-slick";
import {
  IconAngleLeft,
  IconAngleRight,
  IconArrowBack,
  IconArrowDownward,
  IconArrowForward,
  IconArrowRight,
  IconArrowUpward,
} from "../../../../assets/icons/icon";
import {
  AboutImageLogoFwd,
  AboutImageLogoVV,
  AdsImageEvaluate1,
  AdsImageEvaluate2,
  FeedbackBaoviet,
  FeedbackFpt,
} from "../../../../assets/images";
import "./ads-evaluate.scss";

const AdsEvaluate = () => {
  const sliderRef = useRef();
  const [slide, setSlide] = useState({
    slideIndex: 0,
    updateCount: 0,
  });

  const settings = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 2000,
    // vertical: true,
    // afterChange: () =>
    //   setSlide({
    //     ...slide,
    //     updateCount: slide.updateCount + 1,
    //   }),
    // beforeChange: (currentSlide, nextSlide) => {
    //   return setSlide({ slideIndex: nextSlide }),
    // }

    beforeChange: (currentSlide, nextSlide) => {
      setSlide({ slideIndex: nextSlide });
    },
  };

  const next = () => {
    // Slider.slickNext();
    sliderRef.current.slickNext();
  };
  const previous = () => {
    // Slider.slickPrev();
    sliderRef.current.slickPrev();
  };

  return (
    <section className="ads-evaluate">
      <div className="ads-evaluate-container">
        <p className="font-BROmegaVN ads-evaluate-title ">
          Trải nghiệm khách hàng và
        </p>
        <p className="font-BROmegaVN">câu chuyện thành công</p>
        <div className="ads-evaluate-slide">
          <div className="evaluate-angle divider-22">
            <div className="angle-icon" onClick={previous}>
              <IconAngleLeft />
            </div>

            <div className="angle-icon" onClick={next}>
              <IconAngleRight />
            </div>
          </div>
          <div className="evaluate-slide-content">
            <Slider {...settings} ref={sliderRef}>
              <article
                className="feedback-item divider-40"
                onClick={() => test(0)}
              >
                <div className="feedback-logo-customer">
                  <img src={AdsImageEvaluate1} alt="" />
                </div>
                <div className="feedback-content">
                  <div className="feedback-content-img">
                    <img src={AboutImageLogoFwd} alt="logo fwd" />
                  </div>
                  <p className="font-BROmegaVN feedback-content-header">
                    “Giảm hơn 50% nguồn lực con người, tăng độ chính xác khi vận
                    hành...”
                  </p>
                  <p className="font-BROmegaVN feedback-content-name divider-24">
                    Việc áp dụng giải pháp akaAT vào quy trình kiểm thử hệ thống
                    trước khi đưa các sản phẩm mới đến khách hàng đã giúp công
                    ty giảm hơn 50% nguồn lực con người, tăng độ chính xác khi
                    vận hành hệ thống nghiệp vụ bảo hiểm, góp phần nâng cao năng
                    lực phục vụ khách hàng của doanh nghiệo.
                  </p>
                  <p className="font-BROmegaVN feedback-content-header">
                    Ông Đào Hữu Phúc
                  </p>
                  <p className="font-BROmegaVN feedback-content-comment">
                    Phó Tổng Giám đốc Nghiệp vụ Bảo hiểm và Công nghệ thông tin
                    FWD
                  </p>
                </div>
              </article>
              <article
                className="feedback-item divider-40"
                onClick={() => test(1)}
              >
                <div className="feedback-logo-customer">
                  <img src={AdsImageEvaluate2} alt="" />
                </div>
                <div className="feedback-content">
                  <div className="feedback-content-img">
                    <img src={AboutImageLogoVV} alt="logo video visit" />
                  </div>

                  <p className="font-BROmegaVN feedback-content-header">
                    “Báo cáo rõ ràng, tường minh cùng với đội ngũ hỗ trợ giàu
                    kinh nghiệm...”
                  </p>
                  <p className="font-BROmegaVN feedback-content-name divider-12">
                    akaAT đã thuyết phục chúng tôi bằng giải pháp kiểm thử trên
                    nền tảng Cloud cho hệ thống chăm sóc sức khoẻ với những báo
                    cáo rõ ràng, tường mình cùng với đội ngũ hỗ trợ cũng giàu
                    kinh nghiệm và nhiệt tình
                  </p>
                  <p className="font-BROmegaVN feedback-content-header">
                    Ông Rikku Mettala
                  </p>
                  <p className="font-BROmegaVN feedback-content-comment">
                    Giám đốc điều hành Video Visit
                  </p>
                </div>
              </article>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AdsEvaluate;
