import React from "react";
import "./send-email.scss";
import TextEditor from "./text-editor";

const EmailPage = () => {
  return (
    <div className="email-page">
      <div className="email-container">
        <article className="email-form-group divider-30">
          <p className="email-form-title">To</p>
          <input type="email" className="email-form-input" />
          {/* <div className="email-wrap-user">
          </div> */}
          <label className="email-wrap-user">
            <input type="checkbox" />
            <p>All user</p>
          </label>
        </article>

        <article className="email-form-group divider-30">
          <p className="email-form-title">Ccc</p>
          <input type="email" className="email-form-input" />
          <div className="email-wrap-user">
            <input type="checkbox" />
            <p>All user</p>
          </div>
        </article>

        <article className="email-form-group divider-30">
          <p className="email-form-title">Bcc</p>
          <input type="email" className="email-form-input" />
          <div className="email-wrap-user">
            <input type="checkbox" />
            <p>All user</p>
          </div>
        </article>

        <article className="email-form-group divider-30">
          <p className="email-form-title">Subject</p>
          <input type="email" className="email-form-input" />
        </article>

        <section>
          <TextEditor />
        </section>

        <section style={{ position: "relative", left: "510px" }}>
          <button type="submit" className="email-btn">
            send
          </button>
        </section>
      </div>
    </div>
  );
};

export default EmailPage;
