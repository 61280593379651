import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Layout from "../../common/components/layout";
import DownloadEnd from "./components/download-end/download-end";
import DownloadHeader from "./components/download-header/download-header";
import DownloadTestEngine from "./components/download-test-engine/download-test-engine";
import DownloadTestManagement from "./components/download-test-management/download-test-management";
import DownloadStudio from "./components/download-studio/download-studio";
import DownloadModal from "./components/download-modal/download-modal";
import PricingAtomp from "../pricing/components/pricing-atomp/pricing-atomp";

import "./download.scss";

const DownloadPage = () => {
  const { formatMessage } = useIntl();
  const getDownloadInformation = useStoreActions(
    (action) => action.download.requestDownload
  );
  const [showModal, setShowModal] = useState(false);
  const [skipLogin, setSkipLogin] = useState(false);

  useEffect(() => {
    getDownloadInformation();
  }, []);

  const listDownloads = useStoreState((state) => state.download.dataDownload);

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "DownloadPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout hiddenStarted>
      <div className="download-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.download.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.download.meta_description" })}
          />
        </Helmet>
        <DownloadHeader />
        <DownloadStudio
          dataStudio={listDownloads}
          showModal={showModal}
          setShowModal={setShowModal}
          skipLogin={skipLogin}
        />
        <DownloadTestEngine />
        <DownloadTestManagement />
        <PricingAtomp />
        <DownloadModal
          showModal={showModal}
          setShowModal={setShowModal}
          setSkipLogin={setSkipLogin}
        />
      </div>
      <DownloadEnd />
    </Layout>
  );
};

export default DownloadPage;
