import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import ReactGA from "react-ga";
import PrivateRoute from "./private-routes";

// import Default from "./default";
// import Blank from "./blank";
// import Error404 from "./error-404";

import store from "../core/create-store";
import routes from "./root-routes";
import { modelHome } from "./home/model";
import { modelBlog } from "./blog/model";
import { modelContactUs } from "./contact-us/modal";
import { modelSuccessStories } from "./success-stories/model";
import { modelEvents } from "./events-webinars/model";
import { modelInsights } from "./insights/model";
import { modelDownload } from "./download/modal";
import { modelProfile } from "./profile/modal";
import { modelReleaseNotes } from "./release-notes/model";
import ScrollToTop from "../common/hooks/scroll-to-top";
import NotFoundPage from "./404/not-found";
// import { COOKIE_TOKEN } from "../constants";
// import { RouterLoading } from "../components";

// import "./style.scss";

// Add models
store.addModel("home", modelHome);
store.addModel("blog", modelBlog);
store.addModel("contactUs", modelContactUs);
store.addModel("successStories", modelSuccessStories);
store.addModel("events", modelEvents);
store.addModel("insights", modelInsights);
store.addModel("download", modelDownload);
store.addModel("profile", modelProfile);
store.addModel("releaseNotes", modelReleaseNotes);

const Pages = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // For global user info action
  // const getGlobalUserInfo = useStoreActions(
  //   (action) => action.global.getGlobalUserInfo
  // );
  // const globalUserInfo = useStoreState((state) => state);
  // console.log("globalUserInfo", globalUserInfo);
  return (
    <ScrollToTop>
      <Routes>
        {routes.map(({ component: Component, path, ...rest }) => {
          if (rest.public) {
            return (
              <Route path={path} element={<Component />} key={path} {...rest} />
            );
          } else {
            return (
              <Route
                path={path}
                element={
                  <PrivateRoute>
                    <Component />
                  </PrivateRoute>
                }
                key={path}
                {...rest}
              />
            );
          }

          // return (
          //   <Route path={path} element={<Component />} key={path} {...rest} />
          // );
        })}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ScrollToTop>
  );
};

export default Pages;
