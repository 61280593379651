import React from "react";
import { Link } from "react-router-dom";
import { IconArrowDownGradient } from "../../../../assets/icons/icon";
import {
  ImageServices,
  LogoDeviceFarmWhite,
  LogoManagementWhite,
  LogoStudioWhite,
  LogoTestEngineWhite,
} from "../../../../assets/images";
import "./home-service.scss";

const HomeService = () => {
  return (
    <section className="home-service">
      <div className="w-1440">
        <h2 className="divider-30">Start your painless testing journey </h2>
        <div className="service-warp">
          {/* <img src={ImageServices} alt="" className="service-image" /> */}
          <Link to="/aka-studio">
            <article className="service-product service-product-studio">
              {/* <div className="txt-label">FREE</div> */}
              <div className="service-bg-red">
                <img
                  src={LogoStudioWhite}
                  alt=""
                  width="44"
                  className="divider-8"
                />
                <p>Studio</p>
              </div>
            </article>
          </Link>

          <Link to="/aka-test-engine">
            <article className="service-product service-product-cicd">
              {/* <div className="txt-label">FREE</div> */}
              <img
                src={LogoTestEngineWhite}
                alt=""
                width="65"
                className="divider-12"
              />
              <h3>Test Engine</h3>
            </article>
          </Link>

          {/* <div className="service-product service-product-cicd">
            <div className="txt-label">ENTERPRISE</div>
            <div
              className=""
              style={{
                height: "50%",
                paddingTop: "30px",
                textAlign: "center",
                borderBottom: "1px dashed #f3c8cb",
                width: "100%",
              }}
            >
              <img src={LogoAkaAT} alt="" width="65.71" height="20" />
              <p>Center</p>
              <IconArrowDownGradient className="icon-anime" />
            </div>
            <div
              className=""
              style={{ height: "50%", textAlign: "center", paddingTop: "30px" }}
            >
              <img src={LogoAkaAT} alt="" width="65.71" height="20" />
              <p>Runetime Engine</p>
            </div>
          </div> */}
          <Link to="/aka-test-management">
            <article className="service-product service-product-devops">
              {/* <div className="txt-label">ENTERPRISE</div> */}
              <div className="service-bg-red">
                <img
                  src={LogoManagementWhite}
                  alt=""
                  width="97"
                  height="28"
                  className="divider-8"
                />

                <p>
                  Test <br />
                  management
                </p>
              </div>
            </article>
          </Link>

          {/* <Link to="/aka-device-farm">
            <article className="service-product service-product-device-farm">
              <div className="service-bg-red">
                <img
                  src={LogoDeviceFarmWhite}
                  alt=""
                  width="64"
                  className="divider-8"
                />
                <p>
                  Device <br />
                  Farm
                </p>
              </div>
            </article>
          </Link> */}
        </div>
      </div>
    </section>
  );
};
export default HomeService;
