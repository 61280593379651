import { action, thunk } from "easy-peasy";
import { ENDPOINTS_EVENT, ENDPOINTS_SUPPORT } from "../../constants/endpoints";
import { aloApi } from "../../core/alo-api";
import { toast } from "react-toastify";
import {
  BLOCKS_CONTENT_VIDEO,
  BLOCKS_CONTENT_VIDEO_FEATURED,
} from "../../constants";

export const modelEvents = {
  /**
   * State
   */
  webinarHightLight: {},
  moreWebinars: {
    data: [],
    total: 0,
  },
  moreEvents: {
    data: [],
    total: 0,
  },
  allEvents: {
    data: [],
    total: 0,
  },
  eventsFeatured: {
    highlight: {},
    popular: [],
  },
  eventSearch: {
    data: [],
    keyword: "",
    total: 0,
  },
  eventArticle: {},

  /**
   * Action: Set loading change password
   */
  setDataEventSearch: action((state, payload) => {
    state.eventSearch.data = payload.data;
    state.eventSearch.keyword = payload.keyword;
    state.eventSearch.total = payload.meta.pagination.total;
  }),

  /**
   * Action: Set loading change password
   */
  setDataWebinars: action((state, payload) => {
    state.moreWebinars.data = payload.data.filter(
      (story) => !story.attributes.highlight
    );
    state.moreWebinars.total = payload.meta.pagination.total;
  }),

  /**
   * Action: Set loading change password
   */
  setWebinarHightLight: action((state, payload) => {
    state.webinarHightLight = payload.data.find(
      (story) => story.attributes.highlight
    );
  }),

  /**
   * Action: Set loading change password
   */
  setDataEvents: action((state, payload) => {
    state.allEvents.data = payload.data;
    state.moreEvents.data = payload.data.filter(
      (event) => !event.attributes.featured && !event.attributes.hot
    );
    state.allEvents.total = payload.meta.pagination.total;
    state.moreEvents.total = payload.meta.pagination.total;
  }),

  /**
   * Action: Set loading change password
   */
  setEventsFeatured: action((state, payload) => {
    state.eventsFeatured.highlight = payload.data.find(
      (story) => story.attributes.hot
    );
    state.eventsFeatured.popular = payload.data.filter(
      (story) => story.attributes.featured
    );
  }),

  /**
   * Action: Set loading change password
   */
  setEventArticle: action((state, payload) => {
    state.eventArticle = payload.data[0];
  }),

  /**
   * Action: Call api to change password
   */
  // changePassword: thunk(async (action, payload) => {
  //   try {
  //     if (!(payload !== null && typeof payload === "object")) {
  //       throw new Error("Invalid Payload");
  //     }

  //     action.setLoadingChangePass(true);

  //     // const url = ENDPOINTS.CHANGE_PASSWORD;
  //     const url = "http://localhost:3000/";

  //     const res = await aloApi.put(url, payload).then((res) => res.data);

  //     // notification.success({
  //     //   message: i18next.t("akaat:profile.changePassword"),
  //     //   description: i18next.t("akaat:profile.changedPasswordSuccessfully"),
  //     // });

  //     return res;
  //   } catch (err) {
  //     console.error(err);
  //     // handleError(err);
  //   } finally {
  //     action.setLoadingChangePass(false);
  //   }
  // }),

  /**
   * Action: Call api to request receive email
   */
  requestReceiveEmail: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }
      // action.setLoadingList(true);
      // action.setQuery(payload);
      const url = `${ENDPOINTS_EVENT.RECEIVE_EVENTS}`;
      const res = await aloApi.post(url, payload);
      toast.success("Your email has been sent successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to request attend email
   */
  requestAttendEmail: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }
      // action.setLoadingList(true);
      // action.setQuery(payload);
      const url = `${ENDPOINTS_EVENT.ATTEND_EVENTS}`;
      const res = await aloApi.post(url, payload);
      toast.success("Your email has been sent successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchWebinarHightLight: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);

      const url = `${ENDPOINTS_SUPPORT.WEBINARS}`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setWebinarHightLight(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchWebinars: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);

      const url = `${ENDPOINTS_SUPPORT.WEBINARS}&pagination[page]=${payload}&pagination[pageSize]=6`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setDataWebinars(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchEvents: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);

      const url = `${ENDPOINTS_SUPPORT.EVENTS}&pagination[page]=${payload}&pagination[pageSize]=6`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setDataEvents(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchEventsFeatured: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);

      const url = `${ENDPOINTS_SUPPORT.EVENTS}`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setEventsFeatured(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchEventArticle: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }

      // action.setLoadingList(true);
      // action.setQuery(payload);
      const url = `${ENDPOINTS_SUPPORT.EVENTS}&filters[slug]=${payload}`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setEventArticle(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  fetchEventSearchResults: thunk(async (action, payload) => {
    try {
      const url =
        payload.tab === "Events"
          ? `${ENDPOINTS_SUPPORT.EVENTS}&_q=${payload.text}&pagination[page]=${payload.page}&pagination[pageSize]=6`
          : `${ENDPOINTS_SUPPORT.WEBINARS}&_q=${payload.text}&pagination[page]=${payload.page}&pagination[pageSize]=6`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setDataEventSearch({ ...res, keyword: payload.text });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
