import { lazy } from "react";

const ContactUsPage = lazy(() => import("./contact-us"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/contact-us",
    public: true,
    component: ContactUsPage,
  },
];
