import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconArrowForward } from "../../../../assets/icons/icon";
import { ImageAvatar } from "../../../../assets/images";
import { BASE_URL_FORUM } from "../../../../constants/endpoints";
import "./home-blog.scss";

const Article = ({ topics, users, type, tagColor }) => {
  const getUrlAvatar = (urlImage) => {
    return `${BASE_URL_FORUM}/${users
      ?.find((user) => user?.id === urlImage)
      ?.avatar_template.replace("{size}", 240)}`;
  };
  return (
    <>
      {topics &&
        topics.slice(0, 2).map((item) => (
          <a
            href={`${BASE_URL_FORUM}/t/${item.slug}`}
            target="_blank"
            rel="noreferrer"
            className="divider-8"
            key={item.id}
          >
            <article className="blog-wrap divider-24">
              <div className="blog-user divider-16">
                <img
                  src={getUrlAvatar(item?.posters[0].user_id)}
                  alt=""
                  width="32"
                  height="32"
                />
                <div className="blog-user-name">
                  <p>
                    {
                      users?.find((user) => user.id === item.posters[0].user_id)
                        ?.name
                    }
                  </p>
                  <span>
                    @
                    {
                      users?.find((user) => user.id === item.posters[0].user_id)
                        ?.username
                    }
                  </span>
                </div>
                <div className="blog-tag" style={{ backgroundColor: tagColor }}>
                  {type}
                </div>
              </div>
              <div className="blog-content">{item.title}</div>
            </article>
          </a>
        ))}
    </>
  );
};

const HomeBlog = () => {
  const getPostsForum = useStoreActions(
    (action) => action.home.fetchPostsForum
  );

  useEffect(() => {
    getPostsForum();
  }, [getPostsForum]);
  const topics = useStoreState((state) => state.home.topics);
  // const dataUsers = useStoreState((state) => state.home.data.topicHot);
  // console.log("data", dataUsers);
  const onOke = () => {
    console.log("oke");
  };
  return (
    <section className="home-blog">
      <div className="w-1440">
        <div className="blog-item">
          <Article
            topics={topics?.announcement}
            users={topics?.usersOfAnnouncement}
            type="Announcement"
            tagColor="#ECFDF3"
          />
        </div>
        <div className="blog-item">
          <Article
            topics={topics?.hot}
            users={topics?.usersOfHot}
            type="Hot"
            tagColor="#FEF3F2"
          />
        </div>
        <div className="blog-item">
          <Article
            topics={topics?.latest}
            users={topics?.usersOfLatest}
            type="Latest"
            tagColor="#EBF8FF"
          />
        </div>
      </div>
      {/* <div className="blog-wrap-btn">
        <a href={`${BASE_URL_FORUM}`} target={"_blank"} rel="noreferrer">
          <button className="blog-btn">
            Read more <IconArrowForward />
          </button>
        </a>
      </div> */}
    </section>
  );
};

export default HomeBlog;
