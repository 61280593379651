import React from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { useViewportSize } from "../../common/hooks";
import Layout from "../../common/components/layout";
import PricingAtomp from "./components/pricing-atomp/pricing-atomp";
import PricingQuestion from "./components/pricing-question/pricing-question";
import PricingStarted from "./components/pricing-started/pricing-started";
import MobilePricingStarted from "../../mobile-pages/pricing/pricing-started";

import "./pricing.scss";
import DownloadTestEngine from "../download/components/download-test-engine/download-test-engine";
import DownloadTestManagement from "../download/components/download-test-management/download-test-management";

const PricingPage = () => {
  const { formatMessage } = useIntl();
  const [viewportWidth] = useViewportSize();
  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "PricingPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout hiddenStarted contactUs>
      <div className="pricing-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.pricing.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.pricing.meta_description" })}
          />
        </Helmet>
        {viewportWidth > 991 ? <PricingStarted /> : <MobilePricingStarted />}

        <PricingQuestion />
      </div>
    </Layout>
  );
};

export default PricingPage;
