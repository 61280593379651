import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useViewportSize } from "../../common/hooks";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Layout from "../../common/components/layout";
import TestEngineBanner from "./components/test-management-banner/test-management-banner";
import TestEngineBenefit from "./components/test-management-benefit/test-management-benefit";
import AkaStudioFeature from "./components/test-management-feature/test-management-feature";
import TestEngineIntroduction from "./components/test-management-introduction/test-engine-introduction";

import MobileTestManagementBanner from "../../mobile-pages/aka-test-management/components/test-management-banner/test-management-banner";
import MobileTestManagementBenefit from "../../mobile-pages/aka-test-management/components/test-management-benefit/test-management-benefit";
import MobileTestManagementFeature from "../../mobile-pages/aka-test-management/components/test-management-feature/test-engine-feature";

import "./aka-test-management.scss";

const AkaTestManagementPage = () => {
  const { formatMessage } = useIntl();
  const [viewportWidth] = useViewportSize();

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "AkaTestManagementPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout hiddenStarted product>
      <div className="aka-studio-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.management.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.management.meta_description" })}
          />
        </Helmet>
        {viewportWidth > 991 ? (
          <TestEngineBanner />
        ) : (
          <MobileTestManagementBanner />
        )}

        <TestEngineIntroduction />
        {viewportWidth > 991 ? (
          <>
            <TestEngineBenefit />
            <AkaStudioFeature />
          </>
        ) : (
          <>
            <MobileTestManagementBenefit />
            <MobileTestManagementFeature />
          </>
        )}
        <section className="aka-studio-end"></section>
      </div>
    </Layout>
  );
};

export default AkaTestManagementPage;
