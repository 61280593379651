import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm } from "react-hook-form";

import {
  IconDownload,
  IconEdit,
  IconSave,
} from "../../../../assets/icons/icon";
import "./profile-personal.scss";

const initData = {
  fname: "",
  lname: "",
  wPhone: "",
  mPhone: "",
  mAddress: "",
  country: "",
  company: "",
  state: "",
  domain: "",
  city: "",
  role: "",
  zip: "",
};

const ProfilePerson = ({ dataUserInfo, imageUpdate }) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm();

  const { firstName, lastName, attributes } = dataUserInfo || {};

  const [editInfo, setEditInfo] = useState(false);

  const sendUserInfo = useStoreActions(
    (action) => action.profile.requestUpdateUserInfo
  );

  useEffect(() => {
    // fields.forEach((field) => setValue(field, initData[field]));
    // setValue({
    //   fname: getUserInfo?.firstName,
    // });
    setValue("fname", firstName || "");
    setValue("lname", lastName || "");
    setValue("wPhone", attributes?.wPhone?.[0] || "");
    setValue("mPhone", attributes?.mPhone?.[0] || "");
    setValue("mAddress", attributes?.mAddress?.[0] || "");
    setValue("country", attributes?.country?.[0] || "");
    setValue("company", attributes?.company?.[0] || "");
    setValue("state", attributes?.state?.[0] || "");
    setValue("domain", attributes?.domain?.[0] || "");
    setValue("city", attributes?.city?.[0] || "");
    setValue("role", attributes?.role?.[0] || "");
    setValue("zip", attributes?.zip?.[0] || "");
  }, [dataUserInfo]);

  const handelOnCancel = async () => {
    await reset({
      fname: firstName || "",
      lname: lastName || "",
      wPhone: attributes?.wPhone?.[0] || "",
      mPhone: attributes?.mPhone?.[0] || "",
      mAddress: attributes?.mAddress?.[0] || "",
      country: attributes?.country?.[0] || "",
      company: attributes?.company?.[0] || "",
      state: attributes?.state?.[0] || "",
      domain: attributes?.domain?.[0] || "",
      city: attributes?.city?.[0] || "",
      role: attributes?.role?.[0] || "",
      zip: attributes?.zip?.[0] || "",
    });

    await setEditInfo(false);
  };

  const onSubmit = async (data) => {
    const info = {
      firstName: data.fname,
      lastName: data.lname,
      attributes: {
        mPhone: `${data.mPhone}`,
        mAddress: `${data.mAddress}`,
        country: `${data.country}`,
        company: `${data.company}`,
        state: `${data.state}`,
        domain: `${data.domain}`,
        city: `${data.city}`,
        role: `${data.role}`,
        zip: `${data.zip}`,
      },
    };
    await sendUserInfo({
      data: info,
      id: dataUserInfo.id,
      img: imageUpdate,
    });
    await setEditInfo(false);
    // reset(initData);
  };

  return (
    <section className="profile-person">
      <div className="person-header">
        {editInfo ? (
          <h3>Edit profile</h3>
        ) : (
          <>
            <h3>My profile</h3>
            <button className="btn" onClick={() => setEditInfo(true)}>
              <IconEdit
                style={{
                  verticalAlign: "bottom",
                  marginRight: "4px",
                }}
              />{" "}
              Edit profile
            </button>
          </>
        )}
      </div>
      <hr />
      <div className="person-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <article className="person-form-warp divider-16">
            <div className="person-form-item">
              <label htmlFor="fname">First name</label>
              <br />
              <input
                type="text"
                id="fname"
                name="fname"
                {...register("fname", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
            <div className="person-form-item">
              <label htmlFor="fname">Last name</label>
              <br />
              <input
                type="text"
                id="lname"
                name="lname"
                {...register("lname", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
            {/* <div className="person-form-item">
              <label htmlFor="wPhone">Work phone</label>
              <br />
              <input
                type="text"
                id="wPhone"
                name="wPhone"
                {...register("wPhone", { required: false, maxLength: 80 })}
              />
            </div> */}
          </article>
          <article className="person-form-warp divider-16">
            <div
              className={`person-form-item ${
                editInfo ? "person-form-item-disable" : ""
              } `}
            >
              <label htmlFor="email">Email</label>
              <br />
              <input
                type="text"
                id="email"
                name="email"
                defaultValue={dataUserInfo?.email || ""}
                disabled
              />
            </div>
            <div className="person-form-item">
              <label htmlFor="mPhone">Mobile phone</label>
              <br />
              <input
                type="text"
                id="mPhone"
                name="mPhone"
                {...register("mPhone", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
          </article>
          <article className="person-form-warp divider-16">
            <div className="person-form-item">
              <label htmlFor="mAddress">Mac Address</label>
              <br />
              <input
                type="text"
                id="mAddress"
                name="mAddress"
                {...register("mAddress", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
            <div className="person-form-item divider-16">
              <label htmlFor="country">Country</label>
              <br />
              <input
                type="text"
                id="country"
                name="country"
                {...register("country", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
          </article>
          <article className="person-form-warp divider-16">
            <div className="person-form-item">
              <label htmlFor="company">Company</label>
              <br />
              <input
                type="text"
                id="company"
                name="company"
                {...register("company", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
            <div className="person-form-item">
              <label htmlFor="state">State</label>
              <br />
              <input
                type="text"
                id="state"
                name="state"
                {...register("state", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
          </article>
          <article className="person-form-warp divider-16">
            <div className="person-form-item">
              <label htmlFor="domain">Domain</label>
              <br />
              <input
                type="text"
                id="domain"
                name="domain"
                {...register("domain", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
            <div className="person-form-item">
              <label htmlFor="city">City</label>
              <br />
              <input
                type="text"
                id="city"
                name="city"
                {...register("city", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
          </article>
          <article className="person-form-warp divider-16">
            <div className="person-form-item">
              <label htmlFor="role">Role</label>
              <br />
              <input
                type="text"
                id="role"
                name="role"
                {...register("role", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
            <div className="person-form-item">
              <label htmlFor="zip">Zip</label>
              <br />
              <input
                type="text"
                id="zip"
                name="zip"
                {...register("zip", { required: false, maxLength: 80 })}
                disabled={!editInfo}
              />
            </div>
          </article>
          {editInfo ? (
            <div className="person-wrap-btn">
              <button
                className="btn-cancel"
                type="button"
                onClick={handelOnCancel}
              >
                Cancel
              </button>
              <button className="btn person-btn" type="submit">
                <IconSave
                  style={{
                    verticalAlign: "bottom",
                    marginRight: "4px",
                  }}
                />{" "}
                Update
              </button>
            </div>
          ) : (
            <div className="person-space-46">&nbsp;</div>
          )}
        </form>
      </div>
    </section>
  );
};

export default ProfilePerson;
