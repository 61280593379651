import { useState, useLayoutEffect } from "react";

export const useViewportSize = () => {
  const [size, setSize] = useState([0, 0]);

  /**
   * Get viewport size
   */
  useLayoutEffect(() => {
    let throttleTimer = false;

    const throttle = (callback, time = 250) => {
      if (throttleTimer) return;

      throttleTimer = true;

      setTimeout(() => {
        callback();
        throttleTimer = false;
      }, time);
    };

    const updateSize = () => {
      const viewportWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      const viewportHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );

      setSize([viewportWidth, viewportHeight]);
    };

    window.addEventListener("resize", () => {
      throttle(updateSize);
    });

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};
