import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Layout from "../../common/components/layout";
import AkaStudioBanner from "./components/aka-studio-banner/aka-studio-banner";
import AkaStudioBenefit from "./components/aka-studio-benefit/aka-studio-benefit";
import AkaStudioEnvironment from "./components/aka-studio-environment/aka-studio-environment";
import AkaStudioFeature from "./components/aka-studio-feature/aka-studio-feature";
import AkaStudioIntroduction from "./components/aka-studio-introduction/aka-studio-introduction";
import { StudioImageEnd } from "../../assets/images";
import "./aka-studio.scss";
import { useViewportSize } from "../../common/hooks";
import MobileStudioBenefit from "../../mobile-pages/aka-studio/components/aka-studio-benefit/aka-studio-benefit";
import MobileStudioFeature from "../../mobile-pages/aka-studio/components/aka-studio-feature/aka-studio-feature";
import MobileAkaStudioBanner from "../../mobile-pages/aka-studio/components/aka-studio-banner/aka-studio-banner";

const AkaStudioPage = () => {
  const { formatMessage } = useIntl();
  const [viewportWidth] = useViewportSize();

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "AkaStudioPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout hiddenStarted studio>
      <div className="aka-studio-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.studio.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.studio.meta_description" })}
          />
        </Helmet>
        {viewportWidth > 991 ? <AkaStudioBanner /> : <MobileAkaStudioBanner />}
        <AkaStudioIntroduction />
        {viewportWidth > 991 ? (
          <>
            <AkaStudioBenefit />
            <AkaStudioFeature />
          </>
        ) : (
          <>
            <MobileStudioBenefit />
            <MobileStudioFeature />
          </>
        )}
        <AkaStudioEnvironment />
        <section className="aka-studio-end">
          <div className="w-1440">
            <img src={StudioImageEnd} alt="studio lifecycle" />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AkaStudioPage;
