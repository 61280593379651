import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../../assets/translation/en";
import VietNam from "../../assets/translation/vn";

export const Context = React.createContext();

const language = localStorage.getItem("defaultLanguage");
if (!language) {
  localStorage.setItem("defaultLanguage", "en");
}

let lang;
if (language === "en") {
  lang = English;
} else {
  if (language === "vi") {
    lang = VietNam;
  } else {
    lang = English;
  }
}

const WrapperIntl = (props) => {
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(lang);
  function selectLanguage(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    switch (newLocale) {
      // case "fr":
      //   localStorage.setItem("defaultLanguage", "fr");
      //   break;
      // case "ar":
      //   localStorage.setItem("defaultLanguage", "ar");
      //   break;
      case "vi":
        setMessages(VietNam);
        localStorage.setItem("defaultLanguage", "vi");
        break;
      default:
        setMessages(English);
        localStorage.setItem("defaultLanguage", "en");
        break;
    }
  }

  function flattenMessages(nestedMessages, prefix = "") {
    return Object.keys(nestedMessages).reduce((messages, key) => {
      let value = nestedMessages[key];
      let prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === "string") {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey));
      }

      return messages;
    }, {});
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={flattenMessages(messages)} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default WrapperIntl;
