import { Link } from "react-router-dom";
import {
  IconChart,
  IconComputer,
  IconHourglass,
  IconPhone,
} from "../../../../assets/icons/icon";
import "./aka-studio-benefit.scss";
const AkaStudioBenefit = () => {
  return (
    <section className="aka-studio-benefit">
      <div className="w-1440">
        <h2 className="divider-52">
          Go codeless and effortless <br /> with akaAT
        </h2>
        <div className="row benefit-center divider-32">
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconHourglass />
            </div>
            <h3 className="divider-32">
              Save up to 50% effort compared to manual testing
            </h3>
            <p>
              Shorten the testing period, reduce unnecessary fees and optimize
              testers’ efforts.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconChart />
            </div>
            <h3 className="divider-32">Powerful low-code platform</h3>
            <p>
              Create and run tests faster, smarter, and fully customizable, even
              with limited coding skills.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconComputer />
            </div>
            <h3 className="divider-32">Free to use</h3>
            <p>
              Free for individuals with full basic functions and can be
              customizable with more features, up to enterprises’ needs.
            </p>
          </article>
        </div>
        <div className="benefit-wrap-btn">
          <Link to="/download">
            <button className="benefit-btn">Learn more</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AkaStudioBenefit;
