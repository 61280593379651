import { action, thunk } from "easy-peasy";
import { ENDPOINTS_SUPPORT } from "../../constants/endpoints";
import { aloApi } from "../../core/alo-api";

export const modelInsights = {
  /**
   * State
   */
  articlesFeatured: {
    highlight: {},
    popular: [],
  },
  moreArticles: {
    data: [],
    total: 0,
  },
  allArticles: {
    data: [],
    total: 0,
  },
  articleSearch: {
    data: [],
    keyword: "",
    total: 0,
  },
  article: {},

  /**
   * Action: Set Data Article Search
   */
  setDataArticleSearch: action((state, payload) => {
    state.articleSearch.data = payload.data;
    state.articleSearch.keyword = payload.keyword;
    state.articleSearch.total = payload.meta.pagination.total;
  }),

  /**
   * Action: Set Data Articles
   */
  setDataArticles: action((state, payload) => {
    state.allArticles.data = payload.data;
    state.allArticles.total = payload.meta.pagination.total;
  }),

  /**
   * Action: Set More Articles
   */
  setMoreArticles: action((state, payload) => {
    state.moreArticles.data = payload.data.filter(
      (article) => !article.attributes.featured && !article.attributes.hot
    );
    state.moreArticles.total = payload.meta.pagination.total;
  }),

  /**
   * Action: Set Articles Featured
   */
  setArticlesFeatured: action((state, payload) => {
    state.articlesFeatured.highlight = payload.data.find(
      (article) => article.attributes.hot
    );
    state.articlesFeatured.popular = payload.data.filter(
      (article) => article.attributes.featured
    );
  }),

  /**
   * Action: Set Article
   */
  setArticle: action((state, payload) => {
    state.article = payload.data[0];
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchArticles: thunk(async (action, payload) => {
    try {
      const url =
        payload.tab === "blog"
          ? `${ENDPOINTS_SUPPORT.BLOGS}&pagination[page]=${payload.page}&pagination[pageSize]=6`
          : `${ENDPOINTS_SUPPORT.NEWS}&pagination[page]=${payload.page}&pagination[pageSize]=6`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setDataArticles(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchMoreArticles: thunk(async (action, payload) => {
    try {
      const url =
        payload === "blog"
          ? `${ENDPOINTS_SUPPORT.BLOGS}&pagination[start]=0&pagination[limit]=6&filters[$and][0][hot][$eq]=false&filters[$and][1][featured][$eq]=false`
          : `${ENDPOINTS_SUPPORT.NEWS}&pagination[start]=0&pagination[limit]=6&filters[featured][$eq]=false&filters[hot][$eq]=false`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setMoreArticles(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchArticlesFeatured: thunk(async (action, payload) => {
    try {
      const url =
        payload === "blog"
          ? `${ENDPOINTS_SUPPORT.BLOGS}`
          : `${ENDPOINTS_SUPPORT.NEWS}`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setArticlesFeatured(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  fetchArticleDetail: thunk(async (action, payload) => {
    try {
      const url =
        payload.tab === "blog"
          ? `${ENDPOINTS_SUPPORT.BLOGS}&filters[slug]=${payload.slug}`
          : `${ENDPOINTS_SUPPORT.NEWS}&filters[slug]=${payload.slug}`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setArticle(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  fetchArticleSearchResults: thunk(async (action, payload) => {
    try {
      const url =
        payload.tab === "blog"
          ? `${ENDPOINTS_SUPPORT.BLOGS}&_q=${payload.text}&pagination[page]=${payload.page}&pagination[pageSize]=6`
          : `${ENDPOINTS_SUPPORT.NEWS}&_q=${payload.text}&pagination[page]=${payload.page}&pagination[pageSize]=6`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setDataArticleSearch({ ...res, keyword: payload.text });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
