import { action, thunk } from "easy-peasy";
import { ENDPOINTS_SUPPORT } from "../../constants/endpoints";
import { aloApi } from "../../core/alo-api";

export const modelReleaseNotes = {
  /**
   * State
   */
  releaseNotes: {
    data: [],
    total: 0,
  },

  /**
   * Action: Set data notes
   */
  setDataNotes: action((state, payload) => {
    state.releaseNotes.data = payload.data;
    state.releaseNotes.total = payload.meta.pagination.total;
  }),

  /**
   * Action: Call api to fetch SuccessStories
   */
  // fetchArticlesFeatured: thunk(async (action, payload) => {
  //   try {
  //     const url =
  //       payload === "blog"
  //         ? `${ENDPOINTS_SUPPORT.BLOGS}`
  //         : `${ENDPOINTS_SUPPORT.NEWS}`;
  //     const res = await aloApi.get(url).then((res) => res.data);
  //     action.setArticlesFeatured(res);
  //   } catch (err) {
  //     console.error(err);
  //     // handleError(err);
  //   } finally {
  //     // action.setLoadingList(false);
  //   }
  // }),

  /**
   * Action: Call api to fetch release notes
   */
  fetchReleaseNotes: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS_SUPPORT.DOWNLOAD_AKA_TOOL_VERSIONS}`;
      // const url =
      //   payload.tab === "blog"
      //     ? `${ENDPOINTS_SUPPORT.BLOGS}&filters[slug]=${payload.slug}`
      //     : `${ENDPOINTS_SUPPORT.NEWS}&filters[slug]=${payload.slug}`;
      const res = await aloApi.get(url).then((res) => res.data);
      action.setDataNotes(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
