import React from "react";

function TermsPrivacyPolicy({ currentTab }) {
  return (
    <>
      {currentTab === "rules13" && (
        <div id="rules13">
          <h3 className="divider-35">Privacy Policy</h3>
          <p className="divider-35">
            Effective date/Last modified and valid from: December 21st, 2021{" "}
          </p>
          <p className="divider-35">
            We at akaAT, including our affiliates (“FSOFT”, “we”, “us”, “our”),
            care deeply about privacy, security, and online safety, all of which
            are a significant part of our essential mission: to protect users of
            our products and services (“you” and “your”) from the risks of
            theft, disruption, and unauthorized access to their online
            information and activities.
          </p>
          <p className="divider-35">
            This Privacy Policy (“Policy”) is designed to inform you about how
            we collect, use, and share your personal data through our website
            (our “Site”), products, services, and web-based and mobile
            applications (collectively, the “Services”) or when you interact
            with us and our commitment to using the personal data we collect in
            a respectful fashion.
          </p>
          <p className="divider-35">
            PLEASE READ THIS POLICY CAREFULLY. BY ACTIVATING, ACCEPTING,
            ACCESSING OR USING THE SERVICES AND RELATED WEBSITES PROVIDED TO YOU
            BY FSOFT, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE
            TO BE BOUND BY THE POLICY. IF YOU DO NOT ACCEPT THE POLICY, YOU MAY
            NOT USE THE SERVICES. Any dispute over privacy is subject to this
            Policy, any applicable Terms of Use (including any applicable
            limitations on damages and the resolution of disputes) and any
            applicable End User License Agreement.
          </p>
          <p>
            You agree that we may from time to time modify the Policy. We will
            post the amended Policy at any given time on FSOFT’s website and/or
            notify you by email, in-product notification or we may inform you of
            such amendments by way of using any other methods permitted by
            applicable laws. It is important that you check back and make sure
            that you have reviewed the most current version of this Policy.
          </p>
          <ul className="divider-35">
            <li>WHAT KINDS OF INFORMATION DO WE COLLECT?</li>
            <li>HOW DO WE USE THE INFORMATION WE COLLECT?</li>
            <li>WHO DO WE SHARE PERSONAL DATA WITH?</li>
            <li>HOW DO WE PROTECT YOUR DATA?</li>
            <li>HOW LONG DO WE RETAIN THE PERSONAL DATA WE COLLECTS?</li>
            <li>CHILDREN’S PRIVACY</li>
            <li>LINKS TO OTHER WEBSITES</li>
            <li>CONTACT US</li>
          </ul>

          <h3 className="divider-35">
            What kinds of information do we collect?
          </h3>
          <b className="divider-35">
            In general, we collect the following types of data:
          </b>
          <p>The types of personal data we collect include:</p>
          <ul className="divider-35">
            <li>
              Data on computers/devices (such as IP address or geolocation
              information)
            </li>
            <li>Details about a career or occupation</li>
          </ul>
          <p>
            The types of sources for the personal data we get are as follows:
          </p>
          <ul className="divider-35">
            <li>Forms, sign-ups, registrations, and other Site features</li>
            <li>Online resources and third parties</li>
          </ul>
          <b className="divider-35">
            We collect information you provide to us.
          </b>
          <p>
            The following are the business or commercial goals for which we
            gather personal data:
          </p>
          <ul className="divider-35">
            <li>
              Delivering services to you, processing payments, and maintaining
              your account
            </li>
            <li>Promoting to you our products and solutions</li>
            <li>Security and maintenance</li>
            <li>
              Improving our services, systems, operations, and other quality
              controls through analysis
            </li>
            <li>Following the law and the court's orders</li>
            <li>
              Operating the website, providing our offerings and services, and
              using temporary, fleeting information for site interaction
            </li>
            <li>Confirming your identity if you ask us for information</li>
          </ul>
          <b className="divider-35">
            Information We Collect from Third Parties.
          </b>
          <ul className="divider-35">
            <li>
              Hosts and other tech service providers for the website and its
              offerings
            </li>
            <li>Service providers for akaAT's billing or credit cards</li>
            <li>
              Other third-party collaborators or suppliers of information,
              tools, or services to akaAT and its clients
            </li>
          </ul>
        </div>
      )}
      {currentTab === "rules14" && (
        <div id="rules14">
          <h3 className="divider-35">
            How do we use the information we collect?
          </h3>
          <p className="divider-35">
            FSOFT uses the data we collect to provide you rich, interactive
            experiences and also to operate our business, which includes
            analyzing our performance, meeting our legal obligations, developing
            our workforce, and doing research.
          </p>
          <p className="divider-35">In particular, we use data to:</p>
          <p>
            To Run Our Business. We also use the information we collect for
            other business purposes, including to:
          </p>
          <ul className="divider-35">
            <li>
              Authenticate your identity and prevent fraud with your biometric
              data;
            </li>
            <li>
              Analyze your behavior to measure, customize, and improve our Site
              and Services, include but not limited to provide you with rich,
              interactive experiences; develop new products and services or add
              new features or capabilities and tailor your product experiences
              based;
            </li>
            <li>
              Advertise McAfee products and services that we think may be of
              interest to you;
            </li>
            <li>
              Establish and manage accounts and licenses, including by
              collecting and processing payments and completing transactions;
            </li>
            <li>
              Provide customer support, troubleshoot issues, manage
              subscriptions, and respond to requests, questions, and comments;
            </li>
            <li>
              Communicate about, and administer participation in, special
              events, programs, surveys, contests, sweepstakes, and other offers
              and promotions;
            </li>
            <li>Conduct market and consumer research and trend analyses;</li>
            <li>
              Enable posting on our blogs, forums, and other public
              communications;
            </li>
            <li>
              Perform accounting, auditing, billing, reconciliation, and
              collection activities;
            </li>
            <li>
              Prevent, detect, identify, investigate, and respond to potential
              or actual claims, liabilities, prohibited behavior, and criminal
              activity; and
            </li>
            <li>
              Comply with and enforce legal rights, requirements, agreements,
              and policies.
            </li>
          </ul>
          <p>
            Third Party Advertising. We work with third party advertising
            companies to display or deliver ads to you while you are on our Site
            or using some Services. These third party advertisers may collect
            data about your interaction with the Site or Services or others’
            sites or services to measure the effectiveness of their ads and to
            personalize advertising content.
          </p>
          <p>
            If you have consented to allow our Services to access to your
            location, our mobile advertising partners may use your location to
            target advertisements to you. You may use the location settings on
            your device to withdraw access to information about your location.
          </p>
          <p>
            Other Uses. We may use personal data for which we have a legitimate
            interest, such as direct marketing, individual or market research,
            anti-fraud protection, or any other purpose disclosed to you at the
            time you provide personal data or with your consent.
          </p>
        </div>
      )}
      {currentTab === "rules15" && (
        <div id="rules15">
          <h3 className="divider-35">Who do we share personal data with?</h3>
          <p className="divider-35">
            Third Party. We share your personal data with your consent or as
            necessary to complete any transaction or provide any product you
            have requested or authorized. For example, we share your content
            with third parties when you tell us to do so. For example, when you
            provide payment data to make a purchase, we will share payment data
            with banks and other entities that process payment transactions or
            provide other financial services, and for fraud prevention and
            credit risk reduction.
          </p>
          <p className="divider-35">
            Affiliates or Vendor. In addition, we share personal data among
            FSOFT-controlled affiliates and subsidiaries. We also share personal
            data with vendors or agents working on our behalf for the purposes
            described in this Policy. In such cases, these companies must abide
            by our data privacy and security requirements and are not allowed to
            use personal data they receive from us for any other purpose. We may
            also disclose personal data as part of a corporate transaction such
            as a merger or sale of assets.
          </p>
          <p>
            Government Agencies. Finally, we will retain, access, transfer,
            disclose, and preserve personal data, including your content, when
            we have a good faith belief that doing so is necessary to do any of
            the following
          </p>
          <ul className="divider-35">
            <li>
              Comply with applicable law or respond to valid legal process,
              including from law enforcement or other government agencies.
            </li>
            <li>
              Protect the rights or property of FSOFT, if we receive information
              indicating that someone is using our Services to traffic in stolen
              intellectual or physical property of FSOFT, in some cases, we will
              not inspect a customer's private content ourselves, but we may
              refer the matter to law enforcement.
            </li>
          </ul>
        </div>
      )}
      {currentTab === "rules16" && (
        <div id="rules16">
          <h3 className="divider-35">How do we protect your data?</h3>
          <p className="divider-35">
            We use administrative, organizational, technical, and physical
            safeguards to protect the personal data we collect and process. Our
            security controls are designed to maintain data confidentiality,
            integrity, and an appropriate level of availability.
          </p>
          <p className="divider-35">
            This Site uses SSL or TLS encryption for security reasons and for
            the protection of the transmission of confidential content, such as
            the inquiries you send to us as the site operator. You can recognize
            an encrypted connection in your browser's address line when it
            changes from "http://" to "https://" and the lock icon is displayed
            in your browser's address bar. If SSL or TLS encryption is
            activated, the data you transfer to us cannot be read by third
            parties.
          </p>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
        </div>
      )}
      {currentTab === "rules17" && (
        <div id="rules17">
          <h3 className="divider-35">
            How long do we retain the personal data we collects?
          </h3>
          <p>
            FSOFT will keep your personal data for the minimum period necessary
            for the purposes set out in this Policy, namely
          </p>
          <ul className="divider-35">
            <li>
              for as long as you are a registered subscriber or user of our
              products or
            </li>
            <li>
              for as long as your personal data are necessary in connection with
              the lawful purposes set out in this Policy, for which we have a
              valid legal basis or
            </li>
            <li>
              for as long as is reasonably necessary for business purposes
              related to provision of the Services, such as internal reporting
              and reconciliation purposes, warranties or to provide you with
              feedback or information you might request. Where required by law,
              we will delete your biometric data within three (03) years of your
              last interaction with the Services.
            </li>
          </ul>
          <p className="divider-35">
            In addition, if any relevant legal claims are brought, we may
            continue to process your personal data for such additional periods
            as are necessary in connection with that claim.
          </p>
          <p>
            Once the abovementioned periods, each to the extent applicable, have
            concluded, we will either permanently delete, destroy, or
            de-identify the relevant personal data so that it can no longer
            reasonably be tied to you.
          </p>
        </div>
      )}
      {currentTab === "rules18" && (
        <div id="rules18">
          <h3 className="divider-35">Children’s privacy</h3>
          <p className="divider-35">
            Our Service does not address anyone under the age of 18
            (“Children”).
          </p>
          <p className="divider-35">
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with personal data,
            please contact us. If we become aware that we have collected
            personal data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>
        </div>
      )}
      {currentTab === "rules19" && (
        <div id="rules19">
          <h3 className="divider-35"> Links to other websites</h3>
          <p className="divider-35">
            Our Site and Services may contain links to other websites for your
            convenience and information. These websites may be operated by
            companies not affiliated with FSOFT. Linked websites may have their
            own privacy policies or notices, which we strongly suggest you
            review if you visit those websites. We are not responsible for the
            content, privacy practices, or use of any websites that are not
            affiliated with FSOFT.
          </p>
        </div>
      )}
      {currentTab === "rules20" && (
        <div id="rules20">
          <h3 className="divider-35"> Contact us</h3>
          <p className="divider-35">
            Please do not hesitate to contact us regarding this Privacy Policy
            or other related Privacy issues. You can find our contact
            information listed on{" "}
            <a
              href="https://akaat.com/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              {`https://akaat.com/contact-us`}
            </a>
          </p>
        </div>
      )}
    </>
  );
}

export default TermsPrivacyPolicy;
