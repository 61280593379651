import {
  AboutImageLogoFwd,
  AboutImageLogoVV,
  AdsImageEvaluate1,
  AdsImageEvaluate2,
} from "../../../assets/images";
import "./ads-evaluate.scss";

const MobileAdsEvaluate = () => {
  return (
    <section className="m-ads-evaluate">
      <div className="ads-evaluate-container">
        <p className="font-BROmegaVN">Trải nghiệm khách hàng và</p>
        <p className="font-BROmegaVN divider-24">câu chuyện thành công</p>
        <div className="ads-evaluate-customer">
          <article className="evaluate-customer-item">
            <div className="customer-image">
              <img src={AdsImageEvaluate1} alt="customer fwd" />
            </div>
            <div className="customer-reviews">
              <div className="customer-reviews-logo">
                <img src={AboutImageLogoFwd} alt="logo fwd" />
              </div>
              <p className="font-BROmegaVN feedback-content-title">
                “Giảm hơn 50% nguồn lực con người, tăng độ chính xác khi vận
                hành...”
              </p>
              <p className="font-BROmegaVN feedback-content-description divider-12">
                Việc áp dụng giải pháp akaAT vào quy trình kiểm thử hệ thống
                trước khi đưa các sản phẩm mới đến khách hàng đã giúp công ty
                giảm hơn 50% nguồn lực con người, tăng độ chính xác khi vận hành
                hệ thống nghiệp vụ bảo hiểm, góp phần nâng cao năng lực phục vụ
                khách hàng của doanh nghiệo.
              </p>
              <p className="font-BROmegaVN feedback-content-title">
                Ông Đào Hữu Phúc
              </p>
              <p className="font-BROmegaVN feedback-content-description">
                Phó Tổng Giám đốc Nghiệp vụ Bảo hiểm và Công nghệ thông tin FWD
              </p>
            </div>
          </article>
          <article className="evaluate-customer-item">
            <div className="customer-image">
              <img src={AdsImageEvaluate2} alt="customer vv" />
            </div>
            <div className="customer-reviews">
              <div className="customer-reviews-logo">
                <img src={AboutImageLogoVV} alt="logo video visit" />
              </div>

              <p className="font-BROmegaVN feedback-content-title">
                “Báo cáo rõ ràng, tường minh cùng với đội ngũ hỗ trợ giàu kinh
                nghiệm...”
              </p>
              <p className="font-BROmegaVN feedback-content-description divider-12">
                akaAT đã thuyết phục chúng tôi bằng giải pháp kiểm thử trên nền
                tảng Cloud cho hệ thống chăm sóc sức khoẻ với những báo cáo rõ
                ràng, tường mình cùng với đội ngũ hỗ trợ cũng giàu kinh nghiệm
                và nhiệt tình
              </p>
              <p className="font-BROmegaVN feedback-content-title">
                Ông Rikku Mettala
              </p>
              <p className="font-BROmegaVN feedback-content-description">
                Giám đốc điều hành Video Visit
              </p>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};
export default MobileAdsEvaluate;
