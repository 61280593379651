import React from "react";
import { IconArrowDownGradient } from "../../../../assets/icons/icon";
import { ImageMobileServices } from "../../../../assets/images";
import "./home-service.scss";

const MobileHomeService = () => {
  return (
    <section className="m-home-service divider-80">
      <h1 className="divider-30">
        Start your
        <br /> painless testing journey
      </h1>
      <div className="service-warp">
        <img src={ImageMobileServices} alt="" className="service-image" />
      </div>
    </section>
  );
};
export default MobileHomeService;
