import { action, thunk } from "easy-peasy";
import { aloApi } from "../../core/alo-api";
import { toast } from "react-toastify";
import { ENDPOINTS_SUPPORT } from "../../constants/endpoints";

export const modelDownload = {
  /**
   * State
   */
  message: "",
  dataDownload: {},

  /**
   * Action: Set loading change password
   */
  setData: action((state, payload) => {
    state.dataDownload = payload;
  }),

  /**
   * Action: Call api to Send Contact
   */
  requestDownload: thunk(async (action, payload) => {
    try {
      // if (!(payload !== null && typeof payload === "object")) {
      //   throw new Error("Invalid Payload");
      // }
      const url1 = ENDPOINTS_SUPPORT.DOWNLOAD_AKA_TOOL_VERSIONS_LTS;
      const url2 = ENDPOINTS_SUPPORT.DOWNLOAD_AKA_TOOL_VERSIONS_CURRENT;
      const [res1, res2] = await Promise.all([
        aloApi.get(url1).then((res) => res.data),
        aloApi.get(url2).then((res) => res.data),
      ]);

      const res = {
        lts: res1.data,
        current: res2.data
      };
      // action.setLoadingList(true);
      // action.setQuery(payload);
      action.setData(res);
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
