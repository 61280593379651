import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { IconArrowRight } from "../../../../assets/icons/icon";
import { BASE_URL_SUPPORT } from "../../../../constants/endpoints";

const EventsMore = ({ currentTab, data }) => {
  const { formatMessage } = useIntl();
  return (
    <section className="events-more">
      <h3 className="more-header divider-32">More Events</h3>
      <div className="more-wrap-item divider-60">
        {data.map((item, index) => (
          <article className="more-item" key={item.id}>
            <Link to={`/event/article/${item.attributes.slug}`}>
              <img
                src={`${BASE_URL_SUPPORT}${item.attributes.image.data.attributes.url}`}
                alt=""
                className="divider-24"
              />
              <p className="events-tag divider-20">Events</p>
              <p className="font-archia">{item.attributes.title}</p>
              <p className="more-short-desc divider-20">
                {item.attributes.seo?.metaDescription}
              </p>
            </Link>
          </article>
        ))}
      </div>
      <div className="events-btn">
        <Link to="/events/all">{formatMessage({ id: "events.msg_01" })}</Link>
      </div>
    </section>
  );
};

export default EventsMore;
