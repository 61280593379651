import React from "react";
import {
  ImageAsiaPacific,
  ImageAward,
  ImageInternationalBusiness,
  ImageItWorld,
  ImageSaokhue,
} from "../../../../assets/images";
import Slider from "react-slick/lib/slider";
import "./home-recognition.scss";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileHomeRecognition = () => {
  return (
    <section className="m-home-recognition">
      <div className="recognition-text divider-40">
        <h3>Recognitions</h3>
        <p>From industry leading organizations</p>
      </div>
      <div className="recognition-slides">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <img
              src={ImageItWorld}
              alt=""
              className="recognition-logo divider-20"
            />
            <h3 className="divider-8">IT World Awards 2022</h3>
            <p>Gold Globee® Winner - Software Test Automation</p>
          </article>

          <article className="recognition-row divider-20">
            <img
              src={ImageInternationalBusiness}
              alt=""
              className="recognition-logo divider-20"
            />
            <h3 className="divider-8">IT World Awards 2022</h3>
            <p>Gold Globee® Winner - Software Test Automation</p>
          </article>

          <article className="recognition-row divider-20">
            <img
              src={ImageAsiaPacific}
              alt=""
              className="recognition-logo divider-20"
            />
            <h3 className="divider-8">Asia-Pacific Stevie® Awards 2022</h3>
            <p>
              Bronze Stevie® Winner - Award for the Innovation in Technology
              Development - Computer Industries
            </p>
          </article>

          <article className="recognition-row divider-20">
            <img
              src={ImageSaokhue}
              alt=""
              className="recognition-logo divider-20"
            />
            <h3 className="divider-8">Sao Khue 2021</h3>
            <p>Top 10 - New software products and solutions</p>
          </article>
        </Slider>
      </div>
    </section>
  );
};

export default MobileHomeRecognition;
