import { useRef, useState } from "react";
import Slider from "react-slick";
import {
  IconAdsFeature1,
  IconAdsFeature2,
  IconAdsFeature3,
  IconAdsFeature4,
  IconAdsFeature5,
  IconAdsFeature6,
} from "../../../assets/icons/icon";
import {
  ImageAsiaPacific,
  ImageInternationalBusiness,
  ImageItWorld,
  ImageSaokhue,
} from "../../../assets/images";
import "./ads-feature.scss";

const MobileAdsFeature = () => {
  const sliderRef = useRef();

  const [slide, setSlide] = useState({
    slideIndex: 0,
    updateCount: 0,
  });

  const settings = {
    // className: "center",
    // centerMode: true,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    // autoplay: true,
    // speed: 2000,

    beforeChange: (currentSlide, nextSlide) => {
      setSlide({ slideIndex: nextSlide });
    },
  };
  return (
    <>
      <section className="m-ads-feature">
        <p className="font-BROmegaVN">Các tính năng vượt trội</p>
        <div className="font-BROmegaVN ads-feature-line">
          <span className="line"></span>
          {slide.slideIndex === 2 ? "Enterprise" : "Free"}
        </div>
        <div className="feature-slides">
          <Slider {...settings}>
            <article className="feature-wrap-item">
              <div className="ads-feature-item">
                <IconAdsFeature1 />
                <p className="font-BROmegaVN">Codeless & Smart Recorder</p>
                <span>
                  Giao diện kéo thả giúp người dùng có thể dễ dàng tạo lập các
                  kịch bản kiểm thử mà không cần kỹ năng lập trình
                </span>
              </div>
              <div className="ads-feature-item">
                <IconAdsFeature2 />
                <p className="font-BROmegaVN">Multi Platform</p>
                <span>
                  Kiểm thử đa nền tảng trên các ứng dụng web, mobile, API,
                  window app,...
                </span>
              </div>
            </article>

            <article className="feature-wrap-item">
              <div className="ads-feature-item">
                <IconAdsFeature5 />
                <p className="font-BROmegaVN">Detail Report</p>
                <span>
                  Cho phép tạo các báo cáo chi tiết, trực quan theo yêu cầu từ
                  dữ liệu trên hệ thống.
                </span>
              </div>
              <div className="ads-feature-item">
                <IconAdsFeature4 />
                <p className="font-BROmegaVN">Dynamic - Data Driven Testing</p>
                <span>
                  Hỗ trợ kiểm thử viên viết kịch bản sử dụng các tệp dữ liệu để
                  chứa các từ khoá liên quan đến ứng dụng đang được kiểm tra
                </span>
              </div>
            </article>

            <article className="feature-wrap-item">
              <div className="ads-feature-item">
                <IconAdsFeature3 />
                <p className="font-BROmegaVN">Debug theo từng dòng lệnh</p>
                <span>
                  Dừng lại từng bước để kiểm tra những hành động, những thay đổi
                  để tìm hiểu lý do tại sao test case không chạy/chạy sai
                </span>
              </div>
              <div className="ads-feature-item">
                <IconAdsFeature6 />
                <p className="font-BROmegaVN">Teamwork</p>
                <span>
                  Hỗ trợ Github để chia sẻ dự án một cách dễ dàng, tăng hiệu
                  suất làm việc
                </span>
              </div>
            </article>
          </Slider>
        </div>
      </section>
    </>
  );
};
export default MobileAdsFeature;
