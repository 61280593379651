import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdsImageLogoFpt, LogoAkaAT } from "../../../../assets/images";
import AdsNavMobile from "./ads-nav-mobile";
import AdsNavPC from "./ads-nav-pc";

import "./ads-nav.scss";

const AdsNav = () => {
  const [show, setShow] = useState(false);
  const [fixedClass, setFixedClass] = useState("");

  const handelShowNavMobile = (flag) => {
    if (flag) {
      setShow(flag);
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "26px";
    } else {
      setShow(flag);
      document.body.style.overflow = null;
      document.body.style.paddingRight = null;
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 0 ? setFixedClass("layout-fixed") : setFixedClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div className={`ads-nav ${fixedClass}`}>
      <nav
        className={` navbar navbar-expand-lg navbar-dark`}
        style={{ maxWidth: "1150px", margin: "auto", height: "inherit" }}
      >
        <div className="container-fluid">
          {/* <button
            className="navbar-toggler"
            type="button"
            onClick={() => handelShowNavMobile(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              fill="none"
              viewBox="0 0 25 24"
            >
              <path
                fill="#000"
                d="M4.857 18h16c.55 0 1-.45 1-1s-.45-1-1-1h-16c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1h-16c-.55 0-1 .45-1 1s.45 1 1 1zm-1-6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1h-16c-.55 0-1 .45-1 1z"
              ></path>
            </svg>
          </button> */}
          <Link to="#" className="nav-link">
            <img src={LogoAkaAT} alt="logo" width={106} height={32} />
          </Link>
          <div className="nav-right d-lg-none">
            <p className="font-BROmegaVN d-inline-block me-2">Powered by</p>
            <img src={AdsImageLogoFpt} alt="logo fpt software" width={96} />
          </div>
          <AdsNavPC />
          <AdsNavMobile
            show={show}
            setShow={setShow}
            handelShowNavMobile={handelShowNavMobile}
          />
        </div>
      </nav>
    </div>
  );
};
export default AdsNav;
