import React, { useEffect, useRef } from "react";
import "./modalA.scss";

const ModalA = (props) => {
  const modalRef = useRef();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.setShow(false);
      }
    };

    const clickOutsideContent = (e) => {
      if (e.target === modalRef.current) {
        props.setShow(false);
      }
    };
    window.addEventListener("click", clickOutsideContent);
    window.addEventListener("keydown", close);
    return () => {
      window.removeEventListener("click", clickOutsideContent);
      window.removeEventListener("keydown", close);
    };
  }, [props]);

  return (
    <div ref={modalRef} className={`modalX ${props.show ? "active" : ""}`}>
      <div className="modalX__content">
        {!props.hideCloseButton && (
          <span onClick={() => props.setShow(false)} className="modalX__close">
            &times;
          </span>
        )}
        {props.children}
      </div>
    </div>
  );
};

export default ModalA;
