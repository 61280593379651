import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Layout from "../../../../common/components/layout";
import Pagination from "../../../../common/components/pagination/pagination";
import { BASE_URL_SUPPORT } from "../../../../constants/endpoints";
import "./event-list.scss";

const EventListPage = () => {
  const { formatMessage } = useIntl();

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "EventListPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  const [infoPage, setInfoPage] = useState({
    MaxResultCount: 6,
    page: 1,
    SkipCount: 0,
  });

  const getPage = (page) => {
    setInfoPage({
      ...infoPage,
      page,
      SkipCount: (page - 1) * infoPage.MaxResultCount,
    });
  };

  const getSize = (size) => {
    setInfoPage({
      ...infoPage,
      MaxResultCount: size,
    });
  };

  // action
  const getEvents = useStoreActions((action) => action.events.fetchEvents);
  // get
  const allEvents = useStoreState((state) => state.events?.allEvents);
  useEffect(() => {
    getEvents(infoPage.page);
  }, [getEvents, infoPage.page]);

  return (
    <Layout>
      <div className="events-blog-page">
        <Helmet>
          <title>{formatMessage({ id: "seo.event.list.title_tag" })}</title>
          <meta
            name="description"
            content={formatMessage({ id: "seo.event.list.meta_description" })}
          />
        </Helmet>
        <h1 className="divider-48">Events</h1>
        {allEvents?.data.map((item) => (
          <article className="more-item" key={item.id}>
            <div className="insights-blog-img">
              <Link to={`/event/article/${item.attributes.slug}`}>
                <img
                  src={`${BASE_URL_SUPPORT}${item.attributes.image.data.attributes.url}`}
                  alt=""
                  className="divider-24"
                />
              </Link>
            </div>

            <div className="insights-blog-content">
              <p className="insights-tag divider-20">Event</p>
              <Link to={`/event/article/${item.attributes.slug}`}>
                <h5 className="divider-12">{item.attributes.title}</h5>
              </Link>
              <p className="more-short-desc divider-20">
                {item.attributes.seo?.metaDescription}
              </p>
            </div>
          </article>
        ))}

        <Pagination
          getPage={getPage}
          getSize={getSize}
          numberPerPage={infoPage.MaxResultCount}
          totalValue={allEvents.total}
          currentPage={
            (infoPage.SkipCount + infoPage.MaxResultCount) /
              infoPage.MaxResultCount -
            1
          }
        />
      </div>
    </Layout>
  );
};

export default EventListPage;
