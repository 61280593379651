/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";

import { useViewportSize } from "../../common/hooks";
import Layout from "../../common/components/layout";
import YoutubePopover from "../../common/components/modal/modal-video";
import MobileHomePage from "../../mobile-pages/home/home";
import HomeBanner from "./components/home-banner/home-banner";
import HomeBlog from "./components/home-blog/home-blog";
import HomeCommunity from "./components/home-community/home-community";
import HomeFeedback from "./components/home-feedback/home-feedback";
import HomeIntroduction from "./components/home-introduction/home-introduction";
import HomeProduct from "./components/home-product/home-product";
import HomeRecognition from "./components/home-recognition/home-recognition";
import HomeService from "./components/home-service/home-service";
import HomeWhy from "./components/home-why/home-why";

import "./home.scss";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const HomePage = () => {
  const { formatMessage } = useIntl();
  const globalUserInfo = useStoreState((state) => state);
  const [showModal, setShowModal] = useState(false);
  const [viewportWidth] = useViewportSize();

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "HomePage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout>
      {/* <FormattedMessage id="msg.01" /> */}
      {viewportWidth > 991 ? (
        <div className="home-page">
          <Helmet>
            <title>{formatMessage({ id: "seo.home.title_tag" })}</title>
            <meta
              name="description"
              content={formatMessage({ id: "seo.home.meta_description" })}
            />
            {/* Facebook Meta Tags */}
            <meta property="og:url" content="http://akaat.com" />
            <meta property="og:type" content="website" />
            <title>{formatMessage({ id: "seo.home.title_tag" })}</title>
            <meta
              name="description"
              content={formatMessage({ id: "seo.home.meta_description" })}
            />
          </Helmet>
          <HomeBanner />
          <HomeIntroduction showModal={showModal} setShowModal={setShowModal} />
          <HomeService />
          <HomeProduct />
          <HomeCommunity />
          <HomeBlog />
          <HomeWhy />
          <HomeFeedback />
          <HomeRecognition />
          <YoutubePopover
            showModal={showModal}
            setShowModal={setShowModal}
            embedCode={"https://www.youtube.com/watch?v=CVdbdQGiwoE"}
          />
        </div>
      ) : (
        <MobileHomePage />
      )}
    </Layout>
  );
};

export default HomePage;
