import { IconLogoTextStudio } from "../../../../assets/icons/icon";
import {
  StudioLogoIntroduction,
  StudioImageMultiDevices,
} from "../../../../assets/images";
import "./aka-studio-introduction.scss";
const AkaStudioIntroduction = () => {
  return (
    <section className="aka-studio-introduction">
      <div className="w-1440">
        <div className="row">
          <div className="col-12 col-md-6 introduction-text">
            {/* <img
              src={StudioLogoIntroduction}
              alt=""
              className="introduction-img"
            /> */}
            <div className="introduction-title">
              <IconLogoTextStudio />
              <h2 className="divider-40">
                Automation Test <br />
                <span>for Everyone</span>
              </h2>
            </div>
            <p>
              With interactive and user-friendly GUI, akaAT Studio is an
              automation testing tool for all testers, from beginner to expert
              levels. akaAT Studio eliminates technical complexities and can be
              used effectively for both personal and team purposes.
            </p>
          </div>
          <div className="col-12 col-md-6 introduction-img">
            <img
              src={StudioImageMultiDevices}
              alt=""
              className="introduction-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AkaStudioIntroduction;
