import React from "react";
import { IconPlay } from "../../../../assets/icons/icon";
import { PreviewVideo } from "../../../../assets/images";
import "./home-introduction.scss";

const MobileHomeIntroduction = ({ showModal, setShowModal }) => {
  return (
    <section className="m-home-introduction">
      <div className="introduction-text">
        <h1 className="divider-24">
          akaAT – End-to-endless <br />
          automation testing ecosystem
        </h1>
        <p>
          Built as an end-to-endless testing ecosystem for both individual users
          and enterprises, akaAT converges all SaaS testing platforms, tools and
          management system to make the testing journey way more efficient,
          faster and saving, for anyone and at anytime.
        </p>
      </div>
      <div
        className="introduction-video divider-24"
        // onClick={() => setShowModal(true)}
      >
        <div className="introduction-anime">
          <a
            target="_blank"
            href="https://www.youtube.com/watch?v=CVdbdQGiwoE"
            rel="noreferrer"
            data-sessionlink="feature=player-button"
          >
            <img
              src={`https://img.youtube.com/vi/CVdbdQGiwoE/maxresdefault.jpg`}
              alt=""
              className="introduction-img "
            />
            <IconPlay className={"introduction-icon"} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default MobileHomeIntroduction;
