import React from "react";
import { AboutImageBoss } from "../../../../assets/images";
import "./about-boss.scss";

const AboutBoss = () => {
  return (
    <section className="about-boss">
      <div className="row">
        <div className="col-12 col-lg-4 test-engine-left">
          <img src={AboutImageBoss} alt=" man and woman discussing" />
        </div>
        <div className="col-12 col-lg-7 test-engine-right">
          <h3 className="divider-24">
            akaAT is a tool that assists consumers in resolving software quality
            issues. akaAT streamlines the implementation process for quick and
            consistent results. The project team wrote 1,500 additional test
            scenarios, which was ten times the initial number.
          </h3>
          <h2>- Mr. Nguyen Duy Vinh -</h2>
          <p>Innovative technology expert at akaAT.</p>
        </div>
      </div>
    </section>
  );
};

export default AboutBoss;
