import React from "react";
import { TYPE_ARTICLE } from "../home-blog";

const HomeBlogTab = ({ currentTab, handelTab, setCurrentTab }) => {
  return (
    <section className="blog-tab divider-20">
      <div className="tab-left">
        <article
          onClick={() => setCurrentTab(TYPE_ARTICLE.ANNOUNCEMENT)}
          className={`tab-item ${
            currentTab === TYPE_ARTICLE.ANNOUNCEMENT ? "tab-active" : ""
          }`}
          tabIndex="1"
        >
          Announcement
        </article>
        <article
          onClick={() => setCurrentTab(TYPE_ARTICLE.HOT)}
          className={`tab-item ${
            currentTab === TYPE_ARTICLE.HOT ? "tab-active" : ""
          }`}
          tabIndex="2"
        >
          Hot
        </article>
        <article
          onClick={() => setCurrentTab(TYPE_ARTICLE.LATEST)}
          className={`tab-item ${
            currentTab === TYPE_ARTICLE.LATEST ? "tab-active" : ""
          }`}
          tabIndex="3"
        >
          Latest
        </article>
      </div>
    </section>
  );
};

export default HomeBlogTab;
