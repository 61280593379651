import React from "react";
import { DownloadImageTestEngine } from "../../../../assets/images";
import "./download-test-engine.scss";

const DownloadTestEngine = () => {
  return (
    <section className="download-test-engine">
      <div className="row">
        <div className="col-12 col-lg-5 test-engine-left">
          <h2>Test Engine</h2>
          <p className="divider-64">
            Orchestrate your automation test and review reports effectively.
            {/* <span> Learn more about akaAT Center.</span> */}
          </p>
          <a href="https://manager.akaat.com" target="_blank" rel="noreferrer">
            <button className="download-btn">Learn more</button>
          </a>
        </div>
        <div className="col-12 col-lg-7 test-engine-right">
          <img src={DownloadImageTestEngine} alt=" man and woman discussing" />
        </div>
      </div>
    </section>
  );
};

export default DownloadTestEngine;
