import { aloApi } from "../../core/alo-api";
import { action, thunk } from "easy-peasy";
import { toast } from "react-toastify";
import cookie from "cookie";

import {
  ENDPOINTS_AUTHENTICATION,
  ENDPOINTS_EVENT,
} from "../../constants/endpoints";
import axios from "axios";
import Cookies from "js-cookie";

export const modelProfile = {
  /**
   * State
   */
  message: "",

  /**
   * Action: Set loading change password
   */
  setData: action((state, payload) => {
    state.topics.announcement = payload?.topicAnnouncement.topic_list.topics;
    state.topics.hot = payload.topicHot.topic_list.topics;
    state.topics.latest = payload.topicLatest.topic_list.topics;
    state.topics.users = payload.topicHot.users;
  }),

  /**
   * Action: Call api to Update User Info
   */
  requestUpdateUserInfo: thunk(async (action, payload, { getStoreActions }) => {
    const token = Cookies.get("token");
    try {
      let data = payload.data || {};
      if (payload.img) {
        const formData = new FormData();
        formData.append("file", payload.img);
        const urlUploadImage = `${ENDPOINTS_AUTHENTICATION.UPLOAD_IMAGE}`;
        const resURLImage = await aloApi.post(urlUploadImage, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        data["attributes"] = {};
        data.attributes["avatar"] = resURLImage.data.url;
      }
      const url = `${ENDPOINTS_AUTHENTICATION.UPDATE_USER}/${payload.id}`;
      const res = await aloApi.put(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Your user has been updated successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
      getStoreActions().global.fetchUserInfo();
    }
  }),

  /**
   * Action: Call api to Update Password
   */

  requestUpdatePassword: thunk(async (action, payload) => {
    const token = Cookies.get("token");
    try {
      const url = `${ENDPOINTS_AUTHENTICATION.UPLOAD_PASSWORD}/${payload.id}/reset-password`;
      await aloApi.put(
        url,
        { newPassword: payload.newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Your password has been updated successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(err);
      // handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),
};
