import { IconLightning } from "../../../../assets/icons/icon";
import { DeviceFarmImageEnd } from "../../../../assets/images";
import "./device-farm-end.scss";
const DeviceFarmEnd = () => {
  return (
    <section className="device-farm-end">
      <div className="w-1440">
        <div className="row">
          <div className="col-12 col-lg-7">
            <h2 className="divider-48">
              Make mobile app development easy with akaAT Device Farm
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 end-image">
            <img src={DeviceFarmImageEnd} alt="a man is using a computer" />
          </div>
          <div className="col-12 col-lg-6 end-description">
            <h3 className="divider-32">
              Make mobile app development easy with akaAT
            </h3>
            <p className="divider-16">
              <IconLightning />
              Available on both Android & iOS
            </p>
            <p className="divider-16">
              <IconLightning /> Remote logging, debugging and app installing
            </p>
            <p className="divider-16">
              <IconLightning />
              Execute shell commands
            </p>
            <p className="divider-16">
              <IconLightning />
              Direct copy & paste
            </p>
            <p>
              <IconLightning />
              Device screenshot
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeviceFarmEnd;
