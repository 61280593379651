import { lazy } from "react";

const BlogPage = lazy(() => import("./blog"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/blog",
    public: true,
    component: BlogPage,
  },
];
