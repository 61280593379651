import React from "react";
import { Link } from "react-router-dom";
import { IconDownload } from "../../../../assets/icons/icon";
import { StudioImageMobileBanner } from "../../../../assets/images";
import "./aka-studio-banner.scss";
const MobileAkaStudioBanner = () => {
  return (
    <section className="m-aka-studio-banner">
      <img src={StudioImageMobileBanner} alt="" />
      <div className="banner-title">
        <h1 className="divider-24">akaAT Studio</h1>
        <p className="divider-32">
          {`{Free}`} low-code automation testing tool for everyone.
        </p>
        <Link to="/download">
          <button className="divider-60">
            <IconDownload className="me-1" />
            Start for free
          </button>
        </Link>
      </div>
    </section>
  );
};

export default MobileAkaStudioBanner;
