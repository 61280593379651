import React, { useEffect, useState } from "react";
import Layout from "../../common/components/layout";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { IconPlay } from "../../assets/icons/icon";
import Pagination from "../../common/components/pagination/pagination";
import StoriesMore from "./components/stories-more";
import { useStoreActions, useStoreState } from "easy-peasy";
import YoutubePopover from "../../common/components/modal/modal-video";

import "./success-stories.scss";
import { BASE_URL_SUPPORT } from "../../constants/endpoints";
import StoriesTab from "./components/stories-tab";
import StoriesSearch from "./components/stories-search";

const SuccessStoriesPage = () => {
  const { formatMessage } = useIntl();
  const [textSearch, setTextSearch] = useState("");
  const [searchResults, setSearchResults] = useState(false);
  const [infoPage, setInfoPage] = useState({
    MaxResultCount: 6,
    page: 1,
    SkipCount: 0,
  });

  const getPage = (page) => {
    setInfoPage({
      ...infoPage,
      page,
      SkipCount: (page - 1) * infoPage.MaxResultCount,
    });
  };

  const getSize = (size) => {
    setInfoPage({
      ...infoPage,
      MaxResultCount: size,
    });
  };
  // action
  const getSuccessStories = useStoreActions(
    (action) => action.successStories.fetchSuccessStories
  );

  const getStoryHightLight = useStoreActions(
    (action) => action.successStories.fetchStoryHightLight
  );
  // get
  const moreStories = useStoreState(
    (state) => state.successStories.moreStories
  );
  const storyHightLight = useStoreState(
    (state) => state.successStories?.storyHightLight
  );
  const storySearch = useStoreState(
    (state) => state.successStories?.storySearch
  );

  useEffect(() => {
    getSuccessStories(infoPage.page);
    getStoryHightLight();
  }, [getStoryHightLight, getSuccessStories, infoPage.page]);

  const [showModal, setShowModal] = useState(false);
  const [embedCode, setEmbedCode] = useState("");

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "SuccessStoriesPage" };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout>
      <div className="success-stories-page">
        <Helmet>
          <title>
            {formatMessage({ id: "seo.success_stories.title_tag" })}
          </title>
          <meta
            name="description"
            content={formatMessage({
              id: "seo.success_stories.meta_description",
            })}
          />
        </Helmet>
        <h1 className="divider-80">Success stories</h1>
        <StoriesTab
          setTextSearch={setTextSearch}
          textSearch={textSearch}
          setSearchResults={setSearchResults}
        />
        {/* <hr /> */}

        {searchResults ? (
          <StoriesSearch
            results={storySearch || {}}
            textSearch={textSearch}
            setShowModal={setShowModal}
            setEmbedCode={setEmbedCode}
            infoPage={infoPage}
            setInfoPage={setInfoPage}
          />
        ) : (
          <>
            <h2 className="divider-32">Featured</h2>
            {storyHightLight && (
              <section className="success-stories-header divider-40">
                <div className="success-stories-right">
                  {storyHightLight.attributes?.video ? (
                    <div
                      className="image-container"
                      onClick={() => {
                        setEmbedCode(storyHightLight.attributes?.video);
                        setShowModal(true);
                      }}
                    >
                      <img
                        src={`https://img.youtube.com/vi/${storyHightLight.attributes?.video.replace(
                          "https://www.youtube.com/watch?v=",
                          ""
                        )}/maxresdefault.jpg`}
                        alt=""
                      />
                      <IconPlay className={"introduction-icon"} />
                    </div>
                  ) : (
                    <div className="image-container">
                      <img
                        src={`${BASE_URL_SUPPORT}${storyHightLight.attributes?.image.data.attributes.url}`}
                        alt=""
                      />
                    </div>
                  )}
                </div>
                <div className="success-stories-left">
                  <h1>{storyHightLight.attributes?.title}</h1>
                  <p className="divider-24">
                    {storyHightLight.attributes?.seo?.metaDescription}
                  </p>
                  <div className="success-stories-btn divider-20">
                    {storyHightLight.attributes?.image.data ? (
                      <Link
                        to={`/success-stories/${storyHightLight.attributes.slug}`}
                      >
                        <button>Read more</button>
                      </Link>
                    ) : (
                      <button
                        onClick={() => {
                          setEmbedCode(storyHightLight.attributes?.video);
                          setShowModal(true);
                        }}
                      >
                        Watch Now
                      </button>
                    )}
                  </div>
                </div>
              </section>
            )}

            <hr />
            <StoriesMore
              data={moreStories?.data || []}
              showModal={showModal}
              setShowModal={setShowModal}
              setEmbedCode={setEmbedCode}
            />

            <YoutubePopover
              showModal={showModal}
              setShowModal={setShowModal}
              embedCode={embedCode}
            />
            <Pagination
              getPage={getPage}
              getSize={getSize}
              numberPerPage={infoPage.MaxResultCount}
              totalValue={moreStories.total}
              currentPage={
                (infoPage.SkipCount + infoPage.MaxResultCount) /
                  infoPage.MaxResultCount -
                1
              }
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default SuccessStoriesPage;
