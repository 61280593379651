import { useStoreActions } from "easy-peasy";
import React from "react";
import { IconSearch } from "../../../assets/icons/icon";

const InsightsTab = ({
  currentTab,
  handelTab,
  setCurrentTab,
  setTextSearch,
  textSearch,
  setSearchResults,
}) => {
  const getArticleSearchResults = useStoreActions(
    (action) => action.insights.fetchArticleSearchResults
  );

  const handelSearch = () => {
    if (textSearch.length > 0) {
      setSearchResults(true);
      getArticleSearchResults({ text: textSearch, tab: currentTab, page: 1 });
    } else {
      setSearchResults(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      handelSearch();
    }
  };

  return (
    <section className="insights-tab divider-80">
      <div className="tab-left">
        {/* <article
          onClick={() => setCurrentTab("all")}
          className={`tab-item ${currentTab === "all" ? "tab-active" : ""}`}
          tabIndex="1"
        >
          All
        </article> */}
        <article
          onClick={() => {
            setCurrentTab("blog");
            if (textSearch.length > 0) {
              setSearchResults(true);
              getArticleSearchResults({
                text: textSearch,
                tab: "blog",
                page: 1,
              });
            } else {
              setSearchResults(false);
            }
          }}
          className={`tab-item ${currentTab === "blog" ? "tab-active" : ""}`}
          tabIndex="2"
        >
          Blog
        </article>
        <article
          onClick={() => {
            setCurrentTab("news");
            if (textSearch.length > 0) {
              setSearchResults(true);
              getArticleSearchResults({
                text: textSearch,
                tab: "news",
                page: 1,
              });
            } else {
              setSearchResults(false);
            }
          }}
          className={`tab-item ${currentTab === "news" ? "tab-active" : ""}`}
          tabIndex="3"
        >
          News
        </article>
      </div>
      <div className="tab-right">
        <div className="search-container">
          <button onClick={handelSearch}>
            <IconSearch />
          </button>
          <input
            type="text"
            placeholder="Search..."
            className=""
            onChange={(event) => setTextSearch(event.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>
    </section>
  );
};

export default InsightsTab;
