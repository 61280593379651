import React, { useState } from "react";
import {
  StudioImageEnvironment1,
  StudioImageEnvironment2,
  StudioImageEnvironment3,
} from "../../../../assets/images";
import "./aka-studio-environment.scss";
const AkaStudioEnvironment = () => {
  const [currentImage, setImage] = useState("MULTI PLATFORMS");

  const renderImage = () => {
    switch (currentImage) {
      case "OPERATING ENVIRONMENT":
        return (
          <img src={StudioImageEnvironment2} alt="OPERATING ENVIRONMENT" />
        );
      case "SYSTEM INTEGRATION":
        return <img src={StudioImageEnvironment3} alt="SYSTEM INTEGRATION" />;
      default:
        return <img src={StudioImageEnvironment1} alt="MULTI PLATFORMS" />;
    }
  };
  return (
    <section className="aka-studio-environment">
      <div className="w-1440">
        <div className="environment-header divider-95">
          <h2>Seamlessly integrate into your environment</h2>
          <p>
            Choose your flow. akaAT Studio easily integrates with your system
            and builds your continuous testing.{" "}
          </p>
        </div>
        <div className="row environment-list">
          <div className="col-11 col-lg-5 environment-left">
            <article
              className={`environment-item ${
                currentImage === "MULTI PLATFORMS" ? "item-active" : ""
              } divider-48`}
              onClick={() => setImage("MULTI PLATFORMS")}
            >
              <div className="environment-item-number">01</div>
              <p>MULTI PLATFORMS</p>
            </article>
            <article
              className={`environment-item ${
                currentImage === "OPERATING ENVIRONMENT" ? "item-active" : ""
              } divider-48`}
              onClick={() => setImage("OPERATING ENVIRONMENT")}
            >
              <div className="environment-item-number">02</div>
              <p>OPERATING ENVIRONMENT</p>
            </article>
            <article
              className={`environment-item ${
                currentImage === "SYSTEM INTEGRATION" ? "item-active" : ""
              } divider-48`}
              onClick={() => setImage("SYSTEM INTEGRATION")}
            >
              <div className="environment-item-number">03</div>
              <p>SYSTEM INTEGRATION</p>
            </article>
          </div>
          <div className="col-11 col-sm-7 col-lg-4	 environment-right">
            {renderImage()}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AkaStudioEnvironment;
