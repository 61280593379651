import { lazy } from "react";

const AdsPage = lazy(() => import("./ads"));
/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: "/akaat-studio",
    public: true,
    component: AdsPage,
  },
];
