import React from "react";
import Slider from "react-slick/lib/slider";
import "./test-engine-benefit.scss";
import {
  IconBenefitTestEngine1,
  IconBenefitTestEngine2,
  IconBenefitTestEngine3,
} from "../../../../assets/icons/icon";

const settings = {
  centerMode: true,
  centerPadding: "0px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MobileTestEngineBenefit = () => {
  return (
    <section className="m-test-engine-benefit">
      <div className="recognition-text divider-40">
        <h2 className="divider-52">
          Enhance your test orchestration <br /> with akaAT Test Engine
        </h2>
      </div>
      <div className="recognition-slides divider-40">
        <Slider {...settings}>
          <article className="recognition-row divider-20">
            <IconBenefitTestEngine1 />
            <h3 className="divider-8">
              Ultimate control over the test process
            </h3>
            <p>
              Orchestrate the automation test process: Plan, trigger, configure
              and schedule tests effortlessly.
            </p>
          </article>
          <article className="recognition-row divider-20">
            <IconBenefitTestEngine2 />
            <h3 className="divider-8">Deep insights from reports</h3>
            <p>
              Fetch extensive data from your tests, analyze and seamlessly
              receive notifications to your communication tools.
            </p>
          </article>

          <article className="recognition-row divider-20">
            {/* <img
              src={ImageSaokhue}
              alt=""
              className="recognition-logo divider-20"
            /> */}
            <IconBenefitTestEngine3 />
            <h3 className="divider-8">Shorten the testing time</h3>
            <p>
              Accelerate testing by executing autonomous, repetitive tasks, and
              in parallel, from start to end.
            </p>
          </article>
        </Slider>
      </div>
      <div className="benefit-wrap-btn">
        <a
        // href={`https://akatester.com/auth/register`}
        // target={"_blank"}
        // rel="noreferrer"
        >
          <button className="benefit-btn">Learn more</button>
        </a>
      </div>
    </section>
  );
};

export default MobileTestEngineBenefit;
