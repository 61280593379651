import { Suspense } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import store from "./core/create-store";
import Pages from "./pages";
import WrapperIntl from "./common/components/wrapperIntl";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";
import { reactPlugin } from "./app-insights";
const TRACKING_ID = "UA-237825005-1";
ReactGA.initialize(TRACKING_ID);

const helmetContext = {};

function App() {
  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        <StoreProvider store={store}>
          <WrapperIntl>
            <BrowserRouter>
              <Suspense fallback={<div>loading</div>}>
                <AppInsightsContext.Provider value={reactPlugin}>
                  <Pages />
                </AppInsightsContext.Provider>
              </Suspense>
            </BrowserRouter>
          </WrapperIntl>
        </StoreProvider>
        <ToastContainer />
      </HelmetProvider>
    </div>
  );
}

export default App;
