import { IconArrowForward } from "../../../../assets/icons/icon";
import "./test-management-banner.scss";
const TestEngineBanner = () => {
  return (
    <section className="test-management-banner">
      <div className="w-1440">
        <div className="row">
          <div className="col-12 .col-lg-5 banner-title">
            <h1 className="divider-24">akaAT Test Management</h1>
            <p className="divider-32">
              Work smarter together with akaAT <br />
              {`{collaborative test management tool}`}.
            </p>
            <a
              href={`https://docs.akaat.com/`}
              target={"_blank"}
              rel="noreferrer"
            >
              <button className="banner-btn divider-60">
                Get started
                <IconArrowForward style={{ marginLeft: "8px" }} />
              </button>
            </a>

            {/* <img src={ImagePartners} alt="fireSpot" height={40} /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestEngineBanner;
