import {
  IconBenefitProduct7,
  IconBenefitProduct8,
  IconBenefitProduct9,
} from "../../../../assets/icons/icon";
import "./test-management-benefit.scss";
const TestEngineBenefit = () => {
  return (
    <section className="test-management-benefit">
      <div className="w-1440">
        <h2 className="divider-52">Potential benefits</h2>
        <div className="row benefit-center divider-32">
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitProduct7 />
            </div>
            <h3 className="divider-32">Simplify management work</h3>
            <p>
              Remove bottlenecks with centralized tasks to ensure an effective
              management.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitProduct8 />
            </div>
            <h3 className="divider-32">Boost collaboration productivity</h3>
            <p>
              Make it easier for your team to work together with transparent
              tasks and user-friendly collaboration features.
            </p>
          </article>
          <article className="col-4 benefit-item">
            <div className="benefit-icon divider-20">
              <IconBenefitProduct9 />
            </div>
            <h3 className="divider-32">Enhance your test quality</h3>
            <p>
              From the planning to execution stages, akaAT Collab provides your
              team with features to plan and better optimize for your target
              goals.
            </p>
          </article>
        </div>
        <div className="benefit-wrap-btn">
          <a
          // href={`https://akatester.com/auth/register`}
          // target={"_blank"}
          // rel="noreferrer"
          >
            <button className="benefit-btn">Learn more</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default TestEngineBenefit;
