import React from "react";
import {
  AboutImage1,
  AboutImage2,
  AboutImage3,
  AboutImageBannerLeft,
  AboutImageBannerRight,
  DownloadImageTestEngine,
} from "../../../../assets/images";

import "./about-number.scss";

const AboutNumber = () => {
  return (
    <section className="about-number">
      <div className="row">
        <div className="col-4 col-sm-3">
          <img src={AboutImage1} alt="Images" />
        </div>
        <div className="col-4 col-sm-3">
          <img src={AboutImage2} alt="Images" />
        </div>
        <div className="col-4 col-sm-3">
          <img src={AboutImage3} alt="Images" />
        </div>
      </div>
    </section>
  );
};

export default AboutNumber;
