import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { EditorState, RichUtils } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
} from "@draft-js-plugins/mention";
import "./editor.scss";
import "@draft-js-plugins/mention/lib/plugin.css";
import "./rich-editor.css";
// import { useGetUsersQuery } from "../services/counterAPI";

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" },
];
const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" },
];
const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const TextEditor = () => {
  const [open, setOpen] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const ref = useRef("editor");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  // const responseFromQuery = useGetUsersQuery("user");
  const responseFromQuery = [
    {
      name: "Matthew Russell",
      link: "https://twitter.com/mrussell247",
      avatar:
        "https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg",
    },
    {
      name: "Julian Krispel-Samsel",
      link: "https://twitter.com/juliandoesstuff",
      avatar: "https://avatars2.githubusercontent.com/u/1188186?v=3&s=400",
    },
    {
      name: "Jyoti Puri",
      link: "https://twitter.com/jyopur",
      avatar: "https://avatars0.githubusercontent.com/u/2182307?v=3&s=400",
    },
    {
      name: "Max Stoiber",
      link: "https://twitter.com/mxstbr",
      avatar: "https://avatars0.githubusercontent.com/u/7525670?s=200&v=4",
    },
    {
      name: "Nik Graf",
      link: "https://twitter.com/nikgraf",
      avatar: "https://avatars0.githubusercontent.com/u/223045?v=3&s=400",
    },
    {
      name: "Pascal Brandt",
      link: "https://twitter.com/psbrandt",
      avatar:
        "https://pbs.twimg.com/profile_images/688487813025640448/E6O6I011_400x400.png",
    },
  ];
  const { data, isLoading, error } = responseFromQuery;
  // const dataUser = useMemo(() => (data ? data?.Item : []), [data]);
  const dataUser = useMemo(() => responseFromQuery, [data]);

  useEffect(() => {
    setSuggestions(dataUser);
  }, [dataUser]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(
    ({ value }) => {
      setSuggestions(defaultSuggestionsFilter(value, dataUser));
    },
    [dataUser]
  );

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };
  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  return (
    <div
      className={"editor"}
      onClick={() => {
        ref.current?.focus();
      }}
    >
      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
      />
      <Editor
        editorKey={"editor"}
        editorState={editorState}
        onChange={setEditorState}
        plugins={plugins}
        ref={ref}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={() => {
          // get the mention object selected
        }}
      />
    </div>
  );
};

export default TextEditor;
